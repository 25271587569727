export const findRegion = (tender) => {
    const { postcode = "" } = tender;
    const result = /^((((?<prefix>[A-Z]{1,2})\d[\dA-Z]{0,1}) *\d)[A-Z]{2})$/.exec(postcode);
    const prefix = result === null ? "" : result.groups.prefix;
    switch (prefix) {
        case "DH":
        case "DL":
        case "HG":
        case "HU":
        case "LS":
        case "NE":
        case "SR":
        case "TS":
        case "WF":
        case "YO":
            return "North East";
        case "BB":
        case "BD":
        case "BL":
        case "CA":
        case "CH":
        case "CW":
        case "FY":
        case "HD":
        case "HX":
        case "L":
        case "LA":
        case "M":
        case "OL":
        case "PR":
        case "SK":
        case "WA":
        case "WN":
            return "North West";
        case "Al":
        case "CB":
        case "CM":
        case "CO":
        case "HP":
        case "IP":
        case "LU":
        case "NR":
        case "SG":
        case "SS":
            return "East of England";
        case "DE":
        case "DN":
        case "LE":
        case "LN":
        case "NG":
        case "S":
            return "East Midlands";
        case "B":
        case "CV":
        case "DY":
        case "HR":
        case "NN":
        case "ST":
        case "TF":
        case "WR":
        case "WS":
        case "WV":
            return "West Midlands";
        case "BN":
        case "CT":
        case "GU":
        case "ME":
        case "MK":
        case "OX":
        case "PO":
        case "RG":
        case "RH":
        case "SL":
        case "SO":
        case "TN":
            return "South East";
        case "BA":
        case "BH":
        case "BS":
        case "DT":
        case "EX":
        case "GL":
        case "PL":
        case "SN":
        case "SP":
        case "TA":
        case "TQ":
        case "TR":
            return "South West";
        case "BR":
        case "CR":
        case "DA":
        case "E":
        case "EC":
        case "EN":
        case "HA":
        case "IG":
        case "KT":
        case "N":
        case "NW":
        case "RM":
        case "SE":
        case "SM":
        case "SW":
        case "TW":
        case "UB":
        case "W":
        case "WC":
        case "WD":
            return "Greater London";
        case "AB":
        case "DD":
        case "DG":
        case "EH":
        case "FK":
        case "G":
        case "HS":
        case "IV":
        case "KA":
        case "KW":
        case "KY":
        case "ML":
        case "PA":
        case "PH":
        case "TD":
        case "ZE":
            return "Scotland";
        case "BT":
            return "Northern Ireland";
        case "CF":
        case "LD":
        case "LL":
        case "NP":
        case "SA":
        case "SY":
            return "Wales";
        case "GY":
        case "JE":
            return "Channel Islands";
        case "IM":
            return "Isle of Man";
        default:
            return "Unknown";
    }
};
