import SVG from "@Components/Icons/SVG";
export default class VArrowLeft extends SVG {
    path = [
        "M 52.7231 1.1231",
        "a 3.8462 3.8462 90 0 1 0 5.4462",
        "L 9.2846 50",
        "l 43.4385 43.4308",
        "a 3.8462 3.8462 90 0 1 -5.4462 5.4462",
        "l -46.1538 -46.1538",
        "a 3.8462 3.8462 90 0 1 0 -5.4462",
        "l 46.1538 -46.1538",
        "a 3.8462 3.8462 90 0 1 5.4462 0 z",
    ];
    evenOdd = true;
    strokeDetails = {
        stroke: `#FFFFFF`,
        strokeWidth: "5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
}
