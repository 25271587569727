import React from "react";
import Table from "@Components/Table";
import { BundleType, Duration, Effected, UnitType, } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { format } from "@Shared/Helpers/Money";
import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import styles from "./index.module.scss";
export default class BundleTable extends React.Component {
    displayNumber = (value, roamLikeHome, applyToRows, unitType) => {
        let displayValue = value;
        if (roamLikeHome && value === undefined) {
            let allowanceTypes = [];
            switch (unitType) {
                case UnitType.Data:
                    allowanceTypes = [
                        AllowanceTypes["EU Data (GB)"],
                        AllowanceTypes["Non-EU Data (GB)"],
                    ];
                    break;
                case UnitType.Mins:
                    allowanceTypes = [
                        AllowanceTypes["EU Calls (mins)"],
                        AllowanceTypes["Non-EU Calls (mins)"],
                    ];
                    break;
                case UnitType.SMS:
                    allowanceTypes = [
                        AllowanceTypes["EU SMS"],
                        AllowanceTypes["Non-EU SMS"],
                    ];
                    break;
                case UnitType.MMS:
                    allowanceTypes = [
                        AllowanceTypes["EU MMS"],
                        AllowanceTypes["Non-EU MMS"],
                    ];
            }
            console.log(applyToRows, allowanceTypes);
            if (applyToRows.some((row) => allowanceTypes.includes(row))) {
                displayValue = React.createElement(React.Fragment, null, "Same\u00A0as\u00A0UK");
            }
        }
        return React.createElement("div", { className: styles.number }, displayValue);
    };
    render() {
        return (React.createElement(Table, { data: this.props.data, fields: [
                {
                    header: "Name",
                    field: Effected.Name,
                    leftAlign: true,
                    display: (data) => (React.createElement("div", { className: styles.name }, data[Effected.Name])),
                },
                {
                    header: "Mins",
                    field: Effected.Mins,
                    display: (data) => this.displayNumber(data[Effected.Mins], data.roamLikeHome, data[Effected.ApplyRows], UnitType.Mins),
                    rightAlign: true,
                },
                {
                    header: "SMS",
                    field: Effected.SMS,
                    display: (data) => this.displayNumber(data[Effected.SMS], data.roamLikeHome, data[Effected.ApplyRows], UnitType.SMS),
                    rightAlign: true,
                },
                {
                    header: "MMS",
                    field: Effected.MMS,
                    display: (data) => this.displayNumber(data[Effected.MMS], data.roamLikeHome, data[Effected.ApplyRows], UnitType.MMS),
                    rightAlign: true,
                },
                {
                    header: "Data",
                    field: Effected.DataMB,
                    display: (data) => this.displayNumber(data[Effected.DataMB], data.roamLikeHome, data[Effected.ApplyRows], UnitType.Data),
                    rightAlign: true,
                },
                {
                    header: "Type",
                    field: Effected.Type,
                    alias: (data) => {
                        return displayEnum(BundleType, data[Effected.Type]);
                    },
                    leftAlign: true,
                },
                {
                    header: "Duration",
                    field: Effected.Duration,
                    alias: (data) => displayEnum(Duration, data[Effected.Duration]),
                    leftAlign: true,
                },
                {
                    header: "Number of Bundles",
                    field: Effected.NumberOfBundles,
                    display: (data) => (React.createElement("div", { className: styles.number }, data[Effected.NumberOfBundles])),
                    rightAlign: true,
                },
                {
                    header: "Cost of Bundle",
                    field: Effected.Cost,
                    display: (data) => (React.createElement("div", { className: styles.number }, format(data[Effected.Cost]))),
                    rightAlign: true,
                },
                {
                    header: "Cat.",
                    field: Effected.ApplyRows,
                    display: (data) => data[Effected.ApplyRows].map((row) => (React.createElement("div", { className: styles.row }, row))),
                },
                {
                    field: "roamLikeHome",
                    hidden: true,
                },
            ], noNav: true, className: styles.table }));
    }
}
