import React from "react";
import Button from "@Components/Button";
import Arrow from "@Components/Icons/Arrow";
import Exit from "@Components/Icons/Exit";
import GuidanceNotesIcon from "@Components/Icons/GuidanceNotes";
import Logo from "@Components/Icons/Logos/Logo";
import buttons from "@Components/Sass/buttons.module.scss";
import Table from "@Components/Table";
import SuperUsers from "@Components/UsageTable/Popout/SuperUsers";
import getBrokerName from "@Helpers/GetBrokerName";
import { displayCountry } from "@Shared/Helpers/Country";
import { roundToFormat } from "@Shared/Helpers/Numbers";
import { average } from "@Shared/Helpers/Array";
import styles from "./index.module.scss";
export default class Popout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: {},
            selectedRows: {},
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.show !== this.props.show) {
            this.setState({
                shown: {},
                selectedRows: {},
            });
        }
    }
    closeRows = () => {
        this.setState({
            shown: {},
        });
    };
    clearSelected = () => {
        this.setState({
            selectedRows: {},
        });
    };
    openSelected = () => {
        const shown = Object.keys(this.state.selectedRows).reduce((shown, key) => {
            if (Object.values(this.state.selectedRows[key]).includes(true)) {
                shown[key] = true;
            }
            return shown;
        }, {});
        this.setState({
            shown,
        });
    };
    openAll = (data) => () => {
        const shown = data.reduce((shown, { lineID }) => {
            if (lineID !== "Grand Total") {
                shown[lineID] = true;
            }
            return shown;
        }, {});
        this.setState({
            shown,
        });
    };
    getUK = (convertValue) => (data, dataMonths) => {
        const formattedData = data.highUsers.map(({ usage, isSuper }) => {
            const total = Object.values(usage.GB).reduce((total, months) => {
                Object.keys(months).map((month) => {
                    dataMonths.add(month);
                    const current = total[month] || 0;
                    total[month] = current + months[month];
                });
                return total;
            }, {});
            return {
                isSuper,
                ...total,
            };
        });
        return {
            formattedData,
            noBreakdown: true,
            convertValue,
        };
    };
    getRoamingData = (data, dataMonths) => {
        const formattedData = data.highUsers.map(({ usage, isSuper }) => {
            const total = Object.values(usage).reduce((total, { Data: months }) => {
                Object.keys(months).map((month) => {
                    dataMonths.add(month);
                    const current = total[month] || 0;
                    total[month] = current + months[month];
                });
                return total;
            }, {});
            const countryBreakdown = Object.keys(usage).map((destination) => {
                const countryTotal = {};
                Object.keys(usage[destination].Data).map((month) => {
                    const current = countryTotal[month] || 0;
                    countryTotal[month] =
                        current + usage[destination].Data[month];
                });
                return {
                    country: destination,
                    ...countryTotal,
                };
            });
            return {
                ...total,
                countryBreakdown,
                isSuper,
            };
        });
        return { formattedData };
    };
    getRoaming = (convertValue) => (data, dataMonths) => {
        const formattedData = data.highUsers.map(({ usage, isSuper }) => {
            const total = Object.values(usage).reduce((total, destinationObject) => {
                Object.values(destinationObject).map((months) => {
                    Object.keys(months).map((month) => {
                        dataMonths.add(month);
                        const current = total[month] || 0;
                        total[month] = current + months[month];
                    });
                });
                return total;
            }, {});
            const countryBreakdown = Object.keys(usage).map((origin) => {
                return Object.keys(usage[origin]).map((destination) => {
                    const countryTotal = {};
                    Object.keys(usage[origin][destination]).map((month) => {
                        const current = countryTotal[month] || 0;
                        countryTotal[month] =
                            current + usage[origin][destination][month];
                    });
                    return {
                        country: `${displayCountry(origin)} to ${displayCountry(destination)}`,
                        ...countryTotal,
                    };
                });
            });
            return {
                ...total,
                countryBreakdown: countryBreakdown.flat(),
                isSuper,
            };
        });
        return { formattedData, convertValue };
    };
    getRoamingDays = (data, dataMonths) => {
        const formattedData = data.highUsers.map(({ usage, isSuper }) => {
            const total = Object.values(usage).reduce((total, months) => {
                Object.keys(months).map((month) => {
                    dataMonths.add(month);
                    const current = total[month] || 0;
                    total[month] = current + months[month];
                });
                return total;
            }, {});
            const countryBreakdown = Object.keys(usage).map((destination) => {
                const countryTotal = {};
                Object.keys(usage[destination]).map((month) => {
                    const current = countryTotal[month] || 0;
                    countryTotal[month] = current + usage[destination][month];
                });
                return {
                    country: destination,
                    ...countryTotal,
                };
            });
            return {
                ...total,
                countryBreakdown,
                isSuper,
            };
        });
        return { formattedData };
    };
    getIDD = (convertValue) => (data, dataMonths) => {
        const formattedData = data.highUsers.map(({ usage, isSuper }) => {
            const total = Object.values(usage.GB).reduce((total, months) => {
                Object.keys(months).map((month) => {
                    dataMonths.add(month);
                    const current = total[month] || 0;
                    total[month] = current + months[month];
                });
                return total;
            }, {});
            const countryBreakdown = Object.keys(usage.GB).map((destination) => {
                const countryTotal = {};
                Object.keys(usage.GB[destination]).map((month) => {
                    const current = countryTotal[month] || 0;
                    countryTotal[month] =
                        current + usage.GB[destination][month];
                });
                return {
                    country: destination,
                    ...countryTotal,
                };
            });
            return {
                ...total,
                countryBreakdown,
                isSuper,
            };
        });
        return { formattedData, convertValue };
    };
    createPopout = (data, unit, title, getData) => {
        const dataMonths = new Set();
        let { formattedData, noBreakdown = false, convertValue, } = getData(data, dataMonths);
        formattedData = this.orderData(formattedData);
        formattedData = this.showOpenedRows(formattedData, noBreakdown);
        return this.displayPopout(data, unit, title, dataMonths, formattedData, noBreakdown, convertValue);
    };
    orderData = (rows) => {
        rows = rows.map(({ countryBreakdown, isSuper, ...months }) => {
            const monthsArray = Object.values(months);
            const avg = average(monthsArray);
            return { countryBreakdown, ...months, isSuper, avg };
        });
        return rows.sort(({ avg: a }, { avg: b }) => {
            return b - a;
        });
    };
    showOpenedRows = (rows, noBreakdown) => {
        const tableRows = rows.reduce((array, row, index) => {
            const lineID = index + 1;
            array.push({ ...row, lineID, country: "Total" });
            const shown = this.state.shown[lineID];
            if (shown) {
                const total = row.countryBreakdown.reduce((total, { country, ...months }) => {
                    const shownRows = this.state.selectedRows[lineID] || {};
                    const shown = shownRows[country];
                    if (shown) {
                        Object.keys(months).map((month) => {
                            const current = total[month] || 0;
                            total[month] = current + months[month];
                        });
                    }
                    return total;
                }, {});
                array.push({
                    country: "Sub-total",
                    className: styles.totalRow,
                    lineID,
                    ...total,
                }, ...row.countryBreakdown.map((data) => ({ ...data, lineID })));
            }
            return array;
        }, []);
        if (!noBreakdown && tableRows.length > 0) {
            const grandTotal = Object.keys(this.state.selectedRows).reduce((total, lineIDKey) => {
                Object.keys(this.state.selectedRows[lineIDKey])
                    .filter((country) => this.state.selectedRows[lineIDKey][country])
                    .map((countryKey) => {
                    const row = tableRows.find((data) => data.lineID === Number(lineIDKey) &&
                        data.countryBreakdown !== undefined);
                    const countryRow = row.countryBreakdown.find((data) => data.country === countryKey);
                    const { country, lineID, ...months } = countryRow;
                    Object.keys(months).map((month) => {
                        const current = total[month] || 0;
                        total[month] = current + months[month];
                    });
                });
                return total;
            }, {});
            tableRows.splice(0, 0, {
                lineID: "Grand Total",
                country: "Selected Lines",
                ...grandTotal,
                className: styles.grandTotal,
            });
        }
        return tableRows;
    };
    getFields = (dataMonths, noBreakdown, convertValue) => {
        return [
            {
                header: "High Users",
                field: "lineID",
                display: ({ lineID, country }) => {
                    if (country === "Total" || lineID === "Grand Total") {
                        if (typeof lineID === "number") {
                            return `Line ${lineID}`;
                        }
                        return lineID;
                    }
                },
                leftAlign: true,
                className: styles.lineIDCol,
            },
            {
                header: "",
                field: "arrow",
                display: ({ countryBreakdown, lineID }) => {
                    if (countryBreakdown !== undefined &&
                        countryBreakdown.length > 0) {
                        const toggleOpen = () => {
                            const { shown = {} } = this.state;
                            shown[lineID] = !shown[lineID];
                            this.setState({ shown });
                        };
                        const open = this.state.shown[lineID];
                        return (React.createElement(Arrow, { onClick: toggleOpen, rotate: open ? 90 : 0, className: styles.arrow }));
                    }
                },
                hidden: noBreakdown,
            },
            {
                header: "Country",
                field: "country",
                leftAlign: true,
                display: ({ country }) => {
                    const displayedCountry = displayCountry(country);
                    if (displayedCountry !== undefined) {
                        return displayedCountry;
                    }
                    return country;
                },
                hidden: noBreakdown,
            },
            {
                header: "",
                field: "tickBox",
                display: ({ lineID, country }) => {
                    if (country !== "Total" &&
                        country !== "Selected Lines" &&
                        country !== "Sub-total") {
                        const selectCountry = () => {
                            const { selectedRows = {} } = this.state;
                            const { [lineID]: lineRows = {} } = selectedRows;
                            lineRows[country] = !lineRows[country];
                            selectedRows[lineID] = lineRows;
                            this.setState({ selectedRows });
                        };
                        const shownRows = this.state.selectedRows[lineID] || {};
                        const shown = shownRows[country];
                        return (React.createElement("input", { type: "checkbox", onClick: selectCountry, checked: shown }));
                    }
                },
                hidden: noBreakdown,
                className: styles.tickBoxColumn,
            },
            ...[...dataMonths].map((month) => ({
                header: month,
                field: month,
                alias: (data) => {
                    const monthData = data[month];
                    if (monthData === undefined) {
                        return "0";
                    }
                    if (convertValue) {
                        return convertValue(monthData);
                    }
                    return roundToFormat(monthData, 0);
                },
                rightAlign: true,
            })),
            {
                header: `Avg.`,
                field: "avg",
                alias: ({ className, tickBox, country, arrow, lineID, avg, isSuper, ...months }) => {
                    if (avg !== undefined) {
                        if (convertValue !== undefined) {
                            return convertValue(avg);
                        }
                        return roundToFormat(avg, 0);
                    }
                    const monthsArray = Object.values(months);
                    if (monthsArray.length === 0) {
                        return 0;
                    }
                    const total = monthsArray.reduce((total, current) => {
                        return total + current;
                    }, 0);
                    if (convertValue !== undefined) {
                        return convertValue(total / monthsArray.length);
                    }
                    return roundToFormat(total / monthsArray.length, 0);
                },
                rightAlign: true,
            },
            {
                hidden: true,
                field: "className",
            },
            {
                hidden: true,
                field: "countryBreakdown",
            },
            {
                header: "",
                field: "isSuper",
                display: ({ isSuper }) => {
                    const openPopout = () => {
                        this.props.changePopout({
                            table: "Super Users",
                        });
                    };
                    if (isSuper) {
                        return (React.createElement(Logo, { className: `${styles.placeholder} ${styles.logo}`, onClick: openPopout }));
                    }
                },
            },
        ];
    };
    checkDays = (value, unit) => {
        if (/days/i.test(unit) && Number(value) === 1) {
            return "Day";
        }
        return unit;
    };
    displayPopout = (data, unit, title, dataMonths, tableData, noBreakdown, convertValue = (value) => roundToFormat(value, 0)) => {
        const linesTotal = tableData
            .filter(({ country }) => country === "Total")
            .reduce((total, { avg }) => {
            return total + avg;
        }, 0);
        const allLines = this.props.stats.totalLines;
        const fields = this.getFields(dataMonths, noBreakdown, convertValue);
        const { total, highUsers, ...lessThanObject } = data;
        const lessThanArray = Object.keys(lessThanObject)
            .map((key) => ({ key, num: Number(key.replace("lte", "")) }))
            .sort(({ num: a }, { num: b }) => a - b);
        const greaterThan = lessThanArray.at(-1)?.num || 0;
        const lessThan = lessThanArray.map(({ num, key }, index) => {
            let message;
            let total = 0, value = data[key];
            if (typeof data[key] === "object") {
                total = data[key].total;
                value = data[key].lines;
            }
            if (index === 0) {
                message = /days/i.test(title) ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: styles.value },
                        convertValue(num),
                        " Day"),
                    " ",
                    "or less")) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: styles.value },
                        convertValue(num),
                        " ",
                        unit),
                    " ",
                    "or less"));
            }
            else {
                const previousElement = lessThanArray[index - 1];
                message = (React.createElement(React.Fragment, null,
                    "between",
                    " ",
                    React.createElement("span", { className: styles.value }, convertValue(previousElement.num)),
                    " ",
                    "and",
                    " ",
                    React.createElement("span", { className: styles.value },
                        convertValue(num),
                        " ",
                        unit)));
            }
            return (React.createElement("div", { key: key },
                React.createElement("span", { className: `${styles.value}` }, value),
                " lines use",
                " ",
                message,
                total > 0 && (React.createElement(React.Fragment, null,
                    " ",
                    "for a total of",
                    " ",
                    React.createElement("span", { className: styles.value },
                        convertValue(total),
                        " ",
                        unit)))));
        });
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.months },
                React.createElement("span", { className: styles.value }, total),
                " lines out of",
                " ",
                React.createElement("span", { className: styles.value }, allLines),
                " use",
                " ",
                React.createElement("span", { className: styles.value }, title),
                " in an average month"),
            lessThan,
            React.createElement("div", { className: styles.listTitle },
                React.createElement("span", { className: styles.value }, highUsers.length),
                " ",
                "lines use over",
                " ",
                React.createElement("span", { className: styles.value },
                    convertValue(greaterThan),
                    " ",
                    unit),
                " ",
                "on average for a total of",
                " ",
                React.createElement("span", { className: styles.value },
                    convertValue(Number(linesTotal)),
                    " ",
                    unit),
                highUsers.length > 0 && ", see below:"),
            tableData.length > 0 && !noBreakdown && (React.createElement("div", { className: `${buttons.buttonGroupRight} ${styles.buttons}` },
                React.createElement(Button, { click: this.openAll(tableData) }, "OPEN ALL"),
                React.createElement(Button, { click: this.clearSelected }, "CLEAR SELECTED"),
                React.createElement(Button, { click: this.closeRows, red: true }, "CLOSE ALL"))),
            tableData.length > 0 && (React.createElement(Table, { fields: fields, data: tableData, noPadding: true, getKey: ({ lineID, country, topRow }) => {
                    const selected = (this.state.selectedRows[lineID] ||
                        {})[country];
                    return `${lineID}-${country}-${topRow}-${selected}`;
                }, padding: "0.2rem" }))));
    };
    render() {
        let content = React.createElement(React.Fragment, null);
        let title = "";
        if (this.props.show !== undefined) {
            const { row = "", table = "" } = this.props.show;
            title = `${table} ${row}`;
            if (table === "UK") {
                if (row === "Data") {
                    content = this.createPopout(this.props.breakdown.analysis.UK_Data, "GB", title, this.getUK((value) => roundToFormat(value / 1024, 2)));
                }
                if (row === "Prem. Calls") {
                    content = this.createPopout(this.props.breakdown.analysis.UK_Premium_Calls, "mins", title, this.getUK((value) => roundToFormat(value / 60, 0)));
                }
                if (row === "Biz. Rate Calls") {
                    content = this.createPopout(this.props.breakdown.analysis.UK_Business_Calls, "mins", title, this.getUK((value) => roundToFormat(value / 60, 0)));
                }
                if (row === "Prem. SMS") {
                    content = this.createPopout(this.props.breakdown.analysis.UK_Premium_SMS, "texts", title, this.getUK());
                }
                if (row === "Prem. MMS") {
                    content = this.createPopout(this.props.breakdown.analysis.UK_Premium_MMS, "texts", title, this.getUK());
                }
            }
            if (table === "Roaming") {
                if (row === "Days") {
                    content = this.createPopout(this.props.breakdown.analysis.Roaming_Days, "Days", title, this.getRoamingDays);
                }
                if (row === "Call") {
                    title = `${title}s`;
                    content = this.createPopout(this.props.breakdown.analysis.Roaming_Calls, "mins", title, this.getRoaming((value) => roundToFormat(value / 60, 0)));
                }
                if (row === "SMS") {
                    content = this.createPopout(this.props.breakdown.analysis.Roaming_SMS, "texts", title, this.getRoaming());
                }
                if (row === "MMS") {
                    content = this.createPopout(this.props.breakdown.analysis.Roaming_MMS, "texts", title, this.getRoaming());
                }
            }
            if (table === "IDD") {
                if (row === "Call") {
                    title = `${title}s`;
                    content = this.createPopout(this.props.breakdown.analysis.IDD_Calls, "mins", title, this.getIDD((value) => roundToFormat(value / 60, 0)));
                }
                if (row === "SMS") {
                    content = this.createPopout(this.props.breakdown.analysis.IDD_SMS, "texts", title, this.getIDD());
                }
                if (row === "MMS") {
                    content = this.createPopout(this.props.breakdown.analysis.IDD_MMS, "texts", title, this.getIDD());
                }
            }
            const nonUKData = row === "Non-UK Data" && table === "UK";
            if ((row === "Data" && table === "Roaming") || nonUKData) {
                if (nonUKData) {
                    title = "Non-UK Data";
                }
                content = this.createPopout(this.props.breakdown.analysis.Roaming_Data, "MB", title, this.getRoamingData);
            }
            if (table === "Super Users") {
                content = (React.createElement(SuperUsers, { superUsers: this.props.breakdown.superUsers }));
            }
        }
        return (React.createElement("div", { className: `${styles.wrapper} ${this.props.show === undefined ? "" : styles.shown}` },
            React.createElement("div", { className: styles.headerTitle },
                React.createElement(GuidanceNotesIcon, { size: "1.25rem" }),
                " ",
                getBrokerName(),
                " ",
                "Analysis"),
            React.createElement("div", { className: styles.top },
                React.createElement("h1", { className: styles.title }, title),
                React.createElement(Exit, { className: styles.exit, onClick: this.props.close, error: true })),
            React.createElement("div", { className: styles.popoutContent }, content)));
    }
}
