import React from "react";
import Card from "@Components/Card";
import GuidanceNotesIcon from "@Components/Icons/GuidanceNotes";
import TenderResponseIcon from "@Components/Icons/Introduction/CompleteResponse";
import CustomerSelectIcon from "@Components/Icons/Introduction/CustomerSelect";
import ScoredIcon from "@Components/Icons/Introduction/Scored";
import Logo from "@Components/Icons/Logos/Logo";
import getBrokerName from "@Helpers/GetBrokerName";
import styles from "./index.module.scss";
export default class Introduction extends React.Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, { title: "Introduction - how the process works" },
                React.createElement("div", { className: styles.process },
                    React.createElement("div", { className: styles.dots }),
                    React.createElement("div", { className: styles.step },
                        React.createElement(TenderResponseIcon, { number: 1 }),
                        React.createElement("div", { className: styles.titleText }, "Complete your response"),
                        React.createElement("p", null, "Fill out each stage to access the next section")),
                    React.createElement("div", { className: styles.step },
                        React.createElement(ScoredIcon, { number: 2 }),
                        React.createElement("div", { className: styles.titleText }, "Your response is scored"),
                        React.createElement("p", null, "We score and rank your response on value for money, tariff suitability, customer service, sustainability & convenience")),
                    React.createElement("div", { className: styles.step },
                        React.createElement(CustomerSelectIcon, { number: 3 }),
                        React.createElement("div", { className: styles.titleText }, "Customer selects contract"),
                        React.createElement("p", null,
                            "If you\u2019re successful, you\u2019ll start the contracting process through ",
                            getBrokerName())))),
            React.createElement(Card, null,
                React.createElement("div", { className: styles.footer },
                    React.createElement("div", { className: styles.footerText },
                        React.createElement(GuidanceNotesIcon, { className: styles.icon }),
                        "Use the guidance notes on the right to view the customers usage, new requirements and related contract information"))),
            !this.props.tender?.isSmallBusiness && (React.createElement(Card, null,
                React.createElement("div", { className: styles.footer },
                    React.createElement("div", { className: styles.footerText },
                        React.createElement(Logo, { className: styles.icon }),
                        "Click the",
                        " ",
                        React.createElement("div", { className: styles.titleText },
                            getBrokerName(),
                            " logo"),
                        " ",
                        "within the guidance notes to reveal our detailed analysis of the customer's usage"))))));
    }
}
