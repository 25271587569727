import SVG from "@Components/Icons/SVG";
export default class Edit extends SVG {
    static defaultProps = {
        size: `1.5vw`,
        className: "",
        rotate: 0,
        fillOpacity: 1,
        onClick: () => { },
        edit: false,
        primary: false,
        dark: false,
    };
    strokeDetails = {
        stroke: this.props.light ? `#FFFFFF` : `#0EABDB`,
        strokeWidth: "5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    path = [
        "M 33.3087 87.5135 L 4.2812 99.6219 L 0 68.2827 M 33.3087 87.5135 L 0 68.2827 M 33.3087 87.5135 L 65.0397 32.5542 M 0 68.2827 L 31.7308 13.3235 M 31.7308 13.3235 L 39.4231 0 L 72.732 19.2308 L 65.0397 32.5542 M 31.7308 13.3235 L 65.0397 32.5542 M 43.6539 99.1115 L 93.6539 99.1115",
    ];
}
