import React from "react";
import Form, { InputType } from "@Components/Form";
import { DMFeatures, Effected, MDMOS, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export default class DMForm extends React.Component {
    render() {
        return (React.createElement(Form, { submit: this.props.submit, data: this.props.data, errors: this.props.errors, noSave: this.props.buttonText === undefined, buttonText: this.props.buttonText, inputChange: this.props.inputChange, labelLeft: true, input: [
                {
                    label: "Do you offer a data management service?",
                    name: Effected.OfferDM,
                    type: InputType.SmallToggle,
                },
                {
                    label: "Which tool are you offering?",
                    name: Effected.DMTool,
                    type: InputType.Text,
                    limit: 10,
                    hidden: !this.props.data[Effected.OfferDM],
                },
                {
                    label: "Which of the following applies to your data management solution?",
                    name: Effected.DMOS,
                    type: InputType.DropdownEnum,
                    options: MDMOS,
                    placeholder: "Pick an option...",
                    hidden: !this.props.data[Effected.OfferDM],
                },
                {
                    label: "Which features do your solution include?",
                    name: Effected.DMFeatures,
                    type: InputType.DropdownEnum,
                    multi: true,
                    options: DMFeatures,
                    placeholder: "Pick an option...",
                    hidden: !this.props.data[Effected.OfferDM],
                },
                {
                    label: "Is your data management solution included free as part of your tariff solution?",
                    name: Effected.DMFree,
                    type: InputType.SmallToggle,
                    hidden: !this.props.data[Effected.OfferDM],
                },
                {
                    label: (React.createElement(React.Fragment, null, "Please detail cost per line per month for your data management solution")),
                    name: Effected.DMLineCost,
                    type: InputType.Currency,
                    hidden: !this.props.data[Effected.OfferDM] ||
                        this.props.data[Effected.DMFree] === true,
                },
                {
                    label: "What is the minimum commitment period for the data management solution?",
                    name: Effected.DMMinCommitmentPeriod,
                    type: InputType.DropdownEnum,
                    options: TimePeriods,
                    placeholder: "Pick a time period...",
                    hidden: !this.props.data[Effected.OfferDM],
                },
                {
                    label: "How long is your minimum commitment period in months?",
                    name: Effected.DMMinCommitmentPeriodOther,
                    type: InputType.Number,
                    hidden: !this.props.data[Effected.OfferDM] ||
                        this.props.data[Effected.DMMinCommitmentPeriod] !==
                            TimePeriods.Other,
                },
                {
                    label: "Do you offer a range of data management options?",
                    name: Effected.DMRange,
                    type: InputType.SmallToggle,
                    hidden: !this.props.data[Effected.OfferDM],
                },
                {
                    label: "Does this include enterprise versions?",
                    name: Effected.DMEnterprise,
                    type: InputType.SmallToggle,
                    hidden: !this.props.data[Effected.OfferDM] ||
                        !this.props.data[Effected.DMRange],
                },
                {
                    label: "What alternatives to a data management solution could you provide?",
                    notes: (React.createElement(React.Fragment, null, "E.g. If the customer requires controlling the data usage of each line, suggesting account caps\u00A0instead")),
                    name: Effected.DMAlternative,
                    type: InputType.LargeText,
                },
            ] }));
    }
}
