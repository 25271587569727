import React from "react";
import Table from "@Components/Table";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import GetTotalOobCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalOobCost";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
export default class OOB extends React.Component {
    render() {
        const covered = CalculateUsageCovered(this.props.tender, this.props.proposal);
        const fields = [
            {
                header: "Item Name",
                field: "name",
                leftAlign: true,
            },
            {
                header: "Cost Per Item",
                field: "costPerItem",
                display: (data) => (React.createElement("div", { className: styles.number }, data.costPerItem)),
                rightAlign: true,
            },
            {
                header: "Expected Cost",
                field: "expectedCost",
                display: (data) => (React.createElement("div", { className: styles.number }, Number.isNaN(data.expectedCost) ? (React.createElement(React.Fragment, null, "0.00")) : (React.createElement(React.Fragment, null, data.expectedCost)))),
                rightAlign: true,
            },
        ];
        const data = Object.keys(this.props.data).map((key) => {
            const costPerItem = this.props.data[key];
            const expectedCost = covered[key].remaining * costPerItem;
            return {
                costPerItem: format(costPerItem),
                name: key,
                expectedCost: format(expectedCost),
            };
        });
        const totalCost = GetTotalOobCost(this.props.tender, this.props.proposal);
        return (React.createElement(React.Fragment, null,
            React.createElement(Table, { fields: fields, data: data, noNav: true, className: styles.table }),
            React.createElement("div", { className: styles.total },
                React.createElement("div", null),
                React.createElement("div", { className: styles.value }, format(totalCost)))));
    }
}
