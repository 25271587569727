import React from "react";
import { generate as uuidv4 } from "short-uuid";
import Button from "@Components/Button";
import Form, { InputType } from "@Components/Form";
import buttons from "@Components/Sass/buttons.module.scss";
import { ToastStatus } from "@Components/Toast";
import Bundle from "@Shared/Entities/Supplier/Response/Proposal/Bundle";
import { AllowanceTypeGroups, UnitType, } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { AllowanceTypes, Effected, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import Factory from "@Shared/Factory";
import constraints from "@Shared/Entities/Supplier/Response/Proposal/Bundle/constraints";
import GetUnits from "@Shared/Helpers/CalculateUsageCovered/GetUnits";
import { validation } from "@Shared/Validate";
import { editBundles } from "@Store/Actions/Suppliers/responses";
import { connect } from "react-redux";
import AddBundle from "./AddBundle";
import styles from "./index.module.scss";
class Bundles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bundles: [],
            ids: [],
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.open !== this.props.open) {
            const bundles = ((this.props.data || {}).bundles || [])
                .filter(({ roamLikeHome }) => !roamLikeHome)
                .filter((element) => this.filter(element))
                .map((bundle) => {
                return bundle instanceof Bundle ? bundle.data : bundle;
            });
            bundles.sort((a, b) => {
                const { open } = this.props;
                if (a.allowanceTypeGroup === open &&
                    b.allowanceTypeGroup !== open) {
                    return -1;
                }
                if (a.allowanceTypeGroup !== open &&
                    b.allowanceTypeGroup === open) {
                    return 1;
                }
                return 0;
            });
            this.setState({
                bundles,
                ids: bundles.map(({ id }) => id),
            });
        }
    }
    filter = ({ allowanceTypeGroup: group }) => {
        const { open } = this.props;
        if (open === AllowanceTypeGroups.UK ||
            open === AllowanceTypeGroups.IDD) {
            return (group === AllowanceTypeGroups.UK ||
                group === AllowanceTypeGroups.IDD);
        }
        return open === group;
    };
    getUnitValues = (bundle) => {
        let { mins, sms, mms, dataMB } = bundle;
        const { applyToRows = [] } = bundle;
        const isQTYUsed = (unitType) => applyToRows.some((allowanceType) => GetUnits(allowanceType) === unitType);
        if (!isQTYUsed(UnitType.Mins)) {
            mins = undefined;
        }
        if (!isQTYUsed(UnitType.SMS)) {
            sms = undefined;
        }
        if (!isQTYUsed(UnitType.MMS)) {
            mms = undefined;
        }
        if (!isQTYUsed(UnitType.Data)) {
            dataMB = undefined;
        }
        return { mins, sms, mms, dataMB };
    };
    save = () => {
        let bundles = (this.props.data?.bundles || []).filter(({ id }) => !this.state.ids.includes(id));
        const editedBundles = [];
        const errors = [];
        let index = 0;
        for (const bundle of this.state.bundles) {
            try {
                const allowanceTypeGroup = bundle.allowanceTypeGroup || this.props.open;
                const data = bundle instanceof Bundle ? bundle.data : bundle;
                const color = data.color || this.props.getColor(index);
                const { mins, sms, mms, dataMB } = this.getUnitValues(data);
                const bundleData = {
                    id: uuidv4(),
                    allowanceTypeGroup,
                    ...data,
                    mins,
                    sms,
                    mms,
                    dataMB,
                    color,
                };
                validation(bundleData, {
                    ...constraints,
                    name: {
                        presence: true,
                        length: {
                            minimum: 1,
                        },
                    },
                });
                editedBundles.push(Factory.Bundle(bundleData));
            }
            catch (error) {
                errors[index] = error.fails;
            }
            index++;
        }
        bundles = [...bundles, ...editedBundles];
        if (errors.length === 0) {
            this.props.editBundles({
                id: this.props.responseId,
                bundles,
            });
            return Promise.resolve().then(this.props.close);
        }
        this.props.notify({
            title: "Oops",
            description: "Bundles are invalid",
            status: ToastStatus.Error,
        });
        this.props.setErrors(errors);
        return Promise.resolve();
    };
    change = (data) => {
        const { bundles } = data;
        this.setState({ bundles });
    };
    getPossibleAllowanceTypes = () => {
        switch (this.props.open) {
            case AllowanceTypeGroups.UK:
            case AllowanceTypeGroups.IDD:
                return [
                    //uk
                    AllowanceTypes["UK Data (GB)"],
                    AllowanceTypes["Std Calls (mins)"],
                    AllowanceTypes["Std SMS"],
                    AllowanceTypes["Biz rate Calls (mins)"],
                    AllowanceTypes["Prem Calls"],
                    AllowanceTypes["Prem SMS"],
                    AllowanceTypes["Std MMS"],
                    AllowanceTypes["Dir Enq"],
                    AllowanceTypes["Free Calls (mins)"],
                    //idd
                    AllowanceTypes["UK IDD to EU (mins)"],
                    AllowanceTypes["UK IDD to USA & Canada (mins)"],
                    AllowanceTypes["UK IDD to APAC (mins)"],
                    AllowanceTypes["UK IDD to ROW (mins)"],
                    AllowanceTypes["Int SMS"],
                    AllowanceTypes["Int MMS"],
                ];
            case AllowanceTypeGroups.EU_Roaming:
                return [
                    AllowanceTypes["EU Data (GB)"],
                    AllowanceTypes["EU Calls (mins)"],
                    AllowanceTypes["EU SMS"],
                    AllowanceTypes["EU MMS"],
                ];
            case AllowanceTypeGroups.Non_EU_Roaming:
                return [
                    AllowanceTypes["Non-EU Data (GB)"],
                    AllowanceTypes["Non-EU Calls (mins)"],
                    AllowanceTypes["Non-EU SMS"],
                    AllowanceTypes["Non-EU MMS"],
                ];
        }
        return [];
    };
    render() {
        const formData = { bundles: this.state.bundles };
        return (React.createElement("div", { className: `${styles.wrapper} ${this.props.open === undefined ? "" : styles.open}` },
            React.createElement("div", { className: `${styles.form} ${this.props.open === undefined ? "" : styles.open}` }, this.props.open !== undefined && (React.createElement(React.Fragment, null,
                React.createElement("h1", null, "Add/Edit Bundles"),
                React.createElement("div", { className: styles.info }, "Please make use of the guidance notes on the right to create bundles that fit the customer's needs"),
                React.createElement(Form, { submit: this.save, data: formData, change: this.change, errors: this.props.errors, buttons: false, labelLeft: true, input: [
                        {
                            name: Effected.Bundles,
                            type: InputType.RepeatGroup,
                            groupInputs: AddBundle(this.getPossibleAllowanceTypes(), this.props.getColor),
                            repeatText: "Add new bundle",
                            repeatButtonRound: true,
                            roundEdges: true,
                            addButton: "NEW BUNDLE",
                            defaultObj: {},
                        },
                    ] },
                    React.createElement("div", { className: styles.footer },
                        React.createElement("div", { className: styles.buttons },
                            React.createElement("div", { className: styles.left },
                                React.createElement(Button, { dark: true, className: buttons.buttons, click: this.props.close }, "CLOSE")),
                            React.createElement("div", { className: styles.right },
                                React.createElement(Button, { cta: true, className: buttons.buttons, type: "submit" }, "SAVE"))))))))));
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        editBundles: (data) => editBundles(dispatch, data),
    };
};
export default connect(null, mapDispatchToProps)(Bundles);
