// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--mCWMt{font-size:.85rem}.total--ejUD8{display:flex;align-items:center;justify-content:space-between;margin-bottom:1em}.total--ejUD8 .value--UlXJu{min-width:8em;padding:.5em 1.3em;color:var(--inverted-text-color);font-weight:bold;text-align:right;background-color:var(--selected-input-color);border-radius:5px}.number--EF6Gu{color:var(--alt-highlighted-text-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/OOB/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,gBAAgB,CAAC,cAAO,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,iBAAiB,CAAC,4BAAc,aAAa,CAAC,kBAAkB,CAAC,gCAAgC,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,4CAA4C,CAAC,iBAAiB,CAAC,eAAQ,uCAAuC","sourcesContent":[".table{font-size:.85rem}.total{display:flex;align-items:center;justify-content:space-between;margin-bottom:1em}.total .value{min-width:8em;padding:.5em 1.3em;color:var(--inverted-text-color);font-weight:bold;text-align:right;background-color:var(--selected-input-color);border-radius:5px}.number{color:var(--alt-highlighted-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--mCWMt",
	"total": "total--ejUD8",
	"value": "value--UlXJu",
	"number": "number--EF6Gu"
};
export default ___CSS_LOADER_EXPORT___;
