import React from "react";
import Table from "@Components/Table";
import { displayMonthData, displayRowKey } from "@Components/UsageTable";
import styles from "@Components/UsageTable/Popout/index.module.scss";
export default class SuperUsers extends React.Component {
    getFieldsAndData = () => {
        const data = this.props.superUsers.flatMap(({ lineID, usage }) => {
            return Object.keys(usage).map((rowKey, index) => {
                const months = usage[rowKey];
                const isFirstRow = index === 0;
                return {
                    lineID: isFirstRow ? lineID : undefined,
                    rowKey,
                    className: isFirstRow ? "" : styles.styledRow,
                    ...months,
                };
            });
        });
        const { lineID, rowKey, className, ...months } = [...data][0];
        const dataMonths = Object.keys(months);
        const fields = [
            {
                header: "",
                field: "lineID",
                alias: ({ lineID }) => {
                    if (lineID !== undefined) {
                        return `Line ${lineID}`;
                    }
                },
                leftAlign: true,
                className: styles.lineIDCol,
            },
            {
                header: "",
                field: "rowKey",
                display: (data) => {
                    return displayRowKey(data.rowKey);
                },
                leftAlign: true,
            },
            ...[...dataMonths].map((month) => ({
                header: month,
                field: month,
                display: (data) => {
                    return displayMonthData(data[month], data.rowKey);
                },
                rightAlign: true,
            })),
            {
                header: "Avg.",
                field: "average",
                alias: (data) => {
                    const total = dataMonths.reduce((total, monthKey) => {
                        return total + data[monthKey];
                    }, 0);
                    const avg = total / dataMonths.length;
                    return displayMonthData(avg, data.rowKey);
                },
                rightAlign: true,
            },
            {
                hidden: true,
                field: "className",
            },
        ];
        return { fields, data };
    };
    render() {
        const { fields, data } = this.getFieldsAndData();
        return (React.createElement(Table, { fields: fields, data: data, noPadding: true, padding: "0.2rem", className: styles.superUsersTable }));
    }
}
