import React, { useState } from "react";
import Iframe from "react-iframe";
import ColouredBorderBox from "@Components/ColouredBorderBox";
import InfoPopup from "@Components/InfoPopup";
import styles from "./index.module.scss";
export default function Testimonials() {
    const [iframeUrl, setIframeUrl] = useState("");
    const openPopup = (url) => {
        setIframeUrl(url);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ColouredBorderBox, { title: "Testimonials" },
            React.createElement("div", { className: styles.wrapper },
                React.createElement("div", { className: styles.content },
                    React.createElement("div", { className: styles.message },
                        React.createElement("img", { src: "https://news.predictmobile.com/wp-content/uploads/2021/09/Leanne-play-2.jpg", width: 540, height: 255, onClick: () => openPopup("https://www.youtube.com/embed/blwd3rsTW38"), className: styles.video }),
                        React.createElement("img", { src: "https://news.predictmobile.com/wp-content/uploads/2021/09/Douglas-play-2.jpg", width: 540, height: 255, onClick: () => openPopup("https://www.youtube.com/embed/ZizTYqAv9zI"), className: styles.video }))))),
        React.createElement(InfoPopup, { trigger: iframeUrl !== "", content: () => (React.createElement(Iframe, { url: iframeUrl, width: "900", height: "506", frameBorder: 0, allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share", allowFullScreen: true })), onClose: () => setIframeUrl("") })));
}
