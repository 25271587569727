// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".blue--SEB76{color:var(--alt-highlighted-text-color)}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/CustomerService/index.module.scss"],"names":[],"mappings":"AAAA,aAAM,uCAAuC","sourcesContent":[".blue{color:var(--alt-highlighted-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue": "blue--SEB76"
};
export default ___CSS_LOADER_EXPORT___;
