// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--l8n3I{font-size:.85rem}.table--l8n3I .allowanceList--oqTnR{margin:auto}.table--l8n3I .allowanceList--oqTnR .allowance--dbWXj{display:flex;flex-direction:column;width:fit-content;padding-bottom:.5em;line-height:1.2em;text-align:left}.table--l8n3I .allowanceList--oqTnR .allowance--dbWXj:last-child{padding-bottom:0}.table--l8n3I .number--ocghg{color:var(--alt-highlighted-text-color)}.total--eMTxE{display:flex;align-items:center;justify-content:space-between;margin-bottom:1em}.total--eMTxE .value--ztDtl{min-width:8em;padding:.5em 1.3em;color:var(--inverted-text-color);font-weight:bold;text-align:right;background-color:var(--selected-input-color);border-radius:5px}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/Builder/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,gBAAgB,CAAC,oCAAsB,WAAW,CAAC,sDAAiC,YAAY,CAAC,qBAAqB,CAAC,iBAAiB,CAAC,mBAAmB,CAAC,iBAAiB,CAAC,eAAe,CAAC,iEAA4C,gBAAgB,CAAC,6BAAe,uCAAuC,CAAC,cAAO,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,iBAAiB,CAAC,4BAAc,aAAa,CAAC,kBAAkB,CAAC,gCAAgC,CAAC,gBAAgB,CAAC,gBAAgB,CAAC,4CAA4C,CAAC,iBAAiB","sourcesContent":[".table{font-size:.85rem}.table .allowanceList{margin:auto}.table .allowanceList .allowance{display:flex;flex-direction:column;width:fit-content;padding-bottom:.5em;line-height:1.2em;text-align:left}.table .allowanceList .allowance:last-child{padding-bottom:0}.table .number{color:var(--alt-highlighted-text-color)}.total{display:flex;align-items:center;justify-content:space-between;margin-bottom:1em}.total .value{min-width:8em;padding:.5em 1.3em;color:var(--inverted-text-color);font-weight:bold;text-align:right;background-color:var(--selected-input-color);border-radius:5px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--l8n3I",
	"allowanceList": "allowanceList--oqTnR",
	"allowance": "allowance--dbWXj",
	"number": "number--ocghg",
	"total": "total--eMTxE",
	"value": "value--ztDtl"
};
export default ___CSS_LOADER_EXPORT___;
