import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import GuidanceNotesIcon from "@Components/Icons/GuidanceNotes";
import Popout from "@Components/UsageTable/Popout";
import Usage from "@Components/UsageTable/Tables";
import { Network } from "@Shared/Entities/Customer/Usage/enums";
import { Pages } from "@Shared/Entities/Supplier/Response/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { findRegion } from "@SmartComponents/Proposal/GuidanceNotes/region";
import { additionalInfo, contractDetails, dataIDDandRoaming, handsets, lineRequirements, MDM, network, } from "@SmartComponents/Proposal/GuidanceNotes/TenderQuestions";
import styles from "./index.module.scss";
export default class GuidanceNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: false,
            notes: {},
        };
    }
    componentDidMount() {
        this.updateNotes();
    }
    componentDidUpdate(previousProps) {
        if (previousProps.page !== this.props.page) {
            this.updateNotes();
        }
    }
    updateNotes = () => {
        const notes = (() => {
            this.setState({ shown: true });
            switch (this.props.page) {
                case Pages.Introduction:
                    return {
                        Tariff: true,
                        ["Previous Usage Data"]: true,
                        ["Customer Service"]: true,
                        ["Line Requirements"]: true,
                        ["Contract Details"]: true,
                        ["Data"]: true,
                        ["Network"]: true,
                        ["Handsets"]: true,
                        ["MDM"]: true,
                        ["Additional info"]: true,
                    };
                case Pages.Tariff:
                    return {
                        Tariff: true,
                        ["Previous Usage Data"]: true,
                        ["Customer Service"]: true,
                        ["Line Requirements"]: true,
                        ["Contract Details"]: true,
                        ["Data"]: true,
                        ["Network"]: true,
                        ["Handsets"]: true,
                        ["MDM"]: true,
                        ["Additional info"]: true,
                    };
                case Pages.OOB:
                    return {
                        Tariff: true,
                        ["Previous Usage Data"]: true,
                        ["Customer Service"]: true,
                        ["Line Requirements"]: true,
                        ["Contract Details"]: true,
                        ["Data"]: true,
                        ["Network"]: true,
                        ["Handsets"]: true,
                        ["MDM"]: true,
                        ["Additional info"]: true,
                    };
                case Pages.HardwareFund:
                    return {
                        ["Handsets"]: true,
                    };
                case Pages.Network:
                    return {
                        ["Network"]: true,
                    };
                case Pages.AdditionalLines:
                    return {
                        ["Line Requirements"]: true,
                        ["Contract Details"]: true,
                    };
                case Pages.MDM:
                    return {
                        ["MDM"]: true,
                    };
                case Pages.CustomerService:
                    return {
                        ["Customer Service"]: true,
                        ["Additional info"]: true,
                    };
                case Pages.ReviewAndSubmit:
                    return {
                        Tariff: true,
                        ["Previous Usage Data"]: true,
                        ["Customer Service"]: true,
                        ["Line Requirements"]: true,
                        ["Contract Details"]: true,
                        ["Data"]: true,
                        ["Network"]: true,
                        ["Handsets"]: true,
                        ["MDM"]: true,
                        ["Additional info"]: true,
                    };
                default:
                    return {};
            }
        })();
        this.setState({ notes });
    };
    toggle = () => {
        this.setState({
            shown: !this.state.shown,
        });
    };
    showNotes = () => {
        if (this.state.shown === false) {
            return;
        }
        const { tender } = this.props;
        if (tender === undefined) {
            return;
        }
        const displayUsage = () => {
            return (React.createElement(Usage, { tender: tender, breakdown: tender.breakdown, openPopout: this.props.openPopout }));
        };
        const displayValues = (array) => array.map(({ title, value, display, hidden }) => {
            if (value === undefined || hidden) {
                return;
            }
            if (display !== undefined) {
                return (React.createElement("div", { className: styles.detail, key: `${value}-${title}` },
                    React.createElement("div", { className: styles.title }, title),
                    React.createElement("div", { className: styles.value }, display(value))));
            }
            if (Array.isArray(value)) {
                const elements = value.map((value_) => (React.createElement("li", { className: styles.value, key: value_ }, value_)));
                return (React.createElement("ul", { className: styles.list, key: `${value}-${title}` },
                    React.createElement("div", { className: styles.title }, title),
                    elements));
            }
            return (React.createElement("div", { className: styles.detail, key: `${value}-${title}` },
                React.createElement("div", { className: styles.title }, title),
                React.createElement("div", { className: styles.value }, value)));
        });
        const displayNotes = (displays) => (React.createElement("div", { className: `${styles.notesWrapper} ${this.props.popout === undefined ? "" : styles.notVisible}` },
            React.createElement("div", { className: styles.notesContent }, displays
                .map(({ title, elements, usage }) => {
                const open = this.state.notes[title];
                if (open) {
                    const content = usage
                        ? displayUsage()
                        : displayValues(elements);
                    const contentEmpty = Array.isArray(content) &&
                        content.every((element) => element === undefined);
                    console.log({ contentEmpty, content });
                    if (contentEmpty) {
                        return null;
                    }
                    return (React.createElement("div", { className: styles.notesItem, key: title }, content));
                }
            })
                .filter((element) => element !== null))));
        const { details } = tender;
        const tariff = [
            {
                title: "Current network",
                value: (() => {
                    if (details.currentNetwork === undefined ||
                        details.currentNetwork === Network.None) {
                        return "N/A";
                    }
                    return displayEnum(Network, details.currentNetwork);
                })(),
            },
        ];
        const customerService = [
            {
                title: "Main office location",
                value: findRegion(this.props.tender),
            },
        ];
        const displays = [
            { title: "Previous Usage Data", usage: true },
            { elements: tariff, title: "Tariff" },
            { elements: customerService, title: "Customer Service" },
            {
                elements: lineRequirements(details, tender.isSmallBusiness),
                title: "Line Requirements",
            },
            {
                elements: contractDetails(this.props.tender),
                title: "Contract Details",
            },
            {
                elements: dataIDDandRoaming(details, tender.isSmallBusiness),
                title: "Data",
            },
            {
                elements: network(tender, tender.isSmallBusiness),
                title: "Network",
            },
            {
                elements: handsets(details, this.props.devices, tender.isSmallBusiness),
                title: "Handsets",
            },
            { elements: MDM(details, tender.isSmallBusiness), title: "MDM" },
            {
                elements: additionalInfo(details, tender.isSmallBusiness),
                title: "Additional info",
            },
        ];
        return (React.createElement("div", { className: styles.guidanceNotes }, displayNotes(displays)));
    };
    render() {
        const { shown } = this.state;
        const { height } = this.props;
        if (!shown) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement(Grid2, { xs: 12, lg: 4, sx: {
                backgroundColor: "var(--card-color)",
                height: height,
                overflow: "hidden",
                overflowY: "scroll",
                padding: 0,
                zIndex: 6,
            }, className: styles.noScroll },
            React.createElement("div", { style: {
                    overflow: "hidden",
                    height: height,
                    overflowY: "scroll",
                } },
                React.createElement("div", { className: `${styles.wrapper} ` },
                    React.createElement("div", { className: `${styles.content} 
                            ` },
                        React.createElement("div", { className: styles.headerTitle },
                            React.createElement(GuidanceNotesIcon, { size: "1.25rem" }),
                            " Guidance Notes"),
                        this.showNotes()))),
            React.createElement(Popout, { show: this.props.popout, stats: this.props.tender.stats, breakdown: this.props.tender.breakdown, close: this.props.closePopout, changePopout: this.props.openPopout })));
    }
}
