import React from "react";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import { getIconFromPlatform } from "@Helpers/Network";
import { Effected, TermLength, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
import { format } from "@Shared/Helpers/Money";
import accordionStyles from "@SmartComponents/TenderResponses/PagePopup/Summary/Accordion/index.module.scss";
export default class AdditionalLines extends React.Component {
    render() {
        const networkLogo = (platform) => {
            const Icon = getIconFromPlatform(platform);
            if (Icon === undefined) {
                return "---";
            }
            return React.createElement(Icon, null);
        };
        const inputChange = (id) => (input) => {
            const { value, name } = input;
            const { additionalLines = {} } = this.props.data;
            this.props.inputChange({
                name: `${Effected.AdditionalLines}.${id}`,
                value: { ...additionalLines[id], [name]: value },
            });
        };
        return (React.createElement(React.Fragment, null,
            !this.props.tender?.isSmallBusiness && (React.createElement(Card, { title: React.createElement(React.Fragment, null, "Additional Lines Hardware Fund") },
                React.createElement(Form, { submit: this.props.save, data: this.props.data, errors: this.props.errors, noSave: true, inputChange: this.props.inputChange, labelLeft: true, setErrors: this.props.setErrors, input: [
                        {
                            label: "Are additional lines added during the contract co-terminus?",
                            name: Effected.AdditionalLinesCoTerminus,
                            type: InputType.SmallToggle,
                        },
                        {
                            label: "Please detail the type of co-terminus you can offer for this Tender",
                            name: Effected.TimeLeftTerm,
                            type: InputType.LargeToggle,
                            toggleOptions: [
                                {
                                    value: TermLength.Additional_lines_wrapped_into_next_upgrade_period,
                                    label: displayEnum(TermLength, TermLength.Additional_lines_wrapped_into_next_upgrade_period),
                                },
                                {
                                    value: TermLength.Fully_for_remaining_months_aligned_to_main_account,
                                    label: displayEnum(TermLength, TermLength.Fully_for_remaining_months_aligned_to_main_account),
                                },
                            ],
                            hidden: !this.props.data[Effected.AdditionalLinesCoTerminus],
                        },
                        {
                            label: "How much is the hardware fund per additional line?",
                            name: Effected.HWFundPerLine,
                            type: InputType.Currency,
                            noDecimal: true,
                        },
                        {
                            label: "How much is the hardware fund per additional data sim?",
                            name: Effected.HWFundPerDataSim,
                            type: InputType.Currency,
                            noDecimal: true,
                        },
                        {
                            label: "Is the hardware fund amount dependant on remaining months of the contract?",
                            name: Effected.HWProRata,
                            type: InputType.SmallToggle,
                            hidden: !this.props.data[Effected.AdditionalLinesCoTerminus],
                        },
                        {
                            label: "Additional information",
                            name: Effected.AdditionalLinesInfo,
                            type: InputType.LargeText,
                            characters: 1000,
                            notes: "Please detail the specific terms of this additional funding",
                        },
                    ] }))),
            groupedTariff(this.props.data?.tariffComponents || []).map((gts) => {
                return (React.createElement(Card, { title: React.createElement(React.Fragment, null, "Additional Lines"), key: gts.id },
                    React.createElement("ul", null,
                        React.createElement("li", { className: accordionStyles.subitem },
                            React.createElement("div", null, "Network"),
                            React.createElement("div", { className: accordionStyles.value }, networkLogo(gts.platform))),
                        React.createElement("li", { className: accordionStyles.subitem },
                            React.createElement("div", null, "Tariff type"),
                            React.createElement("div", { className: accordionStyles.value }, gts.type)),
                        gts.lines > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                            React.createElement("div", null, "Lines"),
                            React.createElement("div", { className: accordionStyles.value }, gts.lines))),
                        gts.dataLines > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                            React.createElement("div", null, "Data lines"),
                            React.createElement("div", { className: accordionStyles.value }, gts.dataLines))),
                        React.createElement("li", { className: accordionStyles.subitem },
                            React.createElement("div", null, "Data Allowance"),
                            React.createElement("div", { className: accordionStyles.value }, `${gts.dataAllowance} GB`)),
                        gts.additionalLineCost > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                            React.createElement("div", null, "Cost for an additional line"),
                            React.createElement("div", { className: accordionStyles.value }, format(gts.additionalLineCost))))),
                    React.createElement(Form, { data: this.props.data?.additionalLines?.[gts.id] || {}, errors: this.props.errors[Effected.AdditionalLines]?.[gts.id], noSave: true, inputChange: inputChange(gts.id), labelLeft: true, setErrors: this.props.setErrors, input: [
                            {
                                label: `How much does 1 additional voice line cost?`,
                                name: Effected.AdditionalLineCost,
                                type: InputType.Currency,
                                hidden: !gts.lines,
                            },
                            {
                                label: `How much does 1 additional data only line cost?`,
                                name: Effected.AdditionalDataLineCost,
                                type: InputType.Currency,
                                hidden: !gts.dataLines,
                            },
                        ] })));
            })));
    }
}
