import { UnitType } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { AllowanceTypes, ComponentTypes, Effected, QTYType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import ConvertUnits from "@Shared/Helpers/CalculateUsageCovered/ConvertUnits";
import getUsage from "@SmartComponents/Proposal/Oob/DisplayTable/usage";
import { displayEnum } from "../DisplayEnum";
import GetUnits from "./GetUnits";
export default (tender, proposal) => {
    let out = {};
    // const out: Record<string, any> = {}
    const usage = getUsage(tender.breakdown);
    usage[AllowanceTypes["UK Data (GB)"]] = Math.min(usage[AllowanceTypes["UK Data (GB)"]], ConvertUnits(tender.isSmallBusiness
        ? tender.details.overallData / 3
        : tender.details.overallData, UnitType.Data, tender.useNewDataConversion));
    for (const key in usage) {
        let used = usage[key] || 0;
        if (tender.isSmallBusiness) {
            used = used * 3;
        }
        const coveredByTender = 0;
        const coveredByBundles = 0;
        const users = tender.breakdown?.analysis?.[key]?.total || tender.stats.totalLines;
        const usagePerUser = users === 0 ? 0 : used / users;
        const costOfBundles = 0;
        out[key] = {
            used,
            remaining: used,
            coveredByTender,
            coveredByBundles,
            users,
            usagePerUser,
            costOfBundles,
        };
    }
    out = usageCoveredByTariff(tender, proposal, out);
    out = usageCoveredByRoamLikeHome(tender, proposal, out);
    out = usageCoveredByBundles(tender, proposal, out);
    return out;
};
const usageCoveredByTariff = (tender, proposal, out) => {
    const tariffComponents = proposal.get(Effected.TariffComponents) || [];
    for (const tariffComponent of tariffComponents) {
        const { items = [] } = tariffComponent;
        if (!Array.isArray(items)) {
            continue;
        }
        for (const item of items) {
            const key = displayEnum(AllowanceTypes, item.allowanceType);
            if (out[key] === undefined) {
                continue;
            }
            let { remaining, coveredByTender, users } = out[key];
            let coveredUsers = tariffComponent[Effected.TariffComponentNumberLines];
            if (tariffComponent[Effected.TariffComponentType] ===
                ComponentTypes.Shared_Data) {
                coveredUsers = users;
            }
            const qty = Number(ConvertUnits(item.allowanceQTY, GetUnits(item.allowanceType), tender.useNewDataConversion));
            let covered = 0;
            if (item.allowanceQTYType === QTYType.Individual ||
                item.allowanceQTYType === QTYType.Pooled ||
                item.allowanceQTYType === QTYType.Aggregated) {
                covered = qty * coveredUsers;
            }
            if (item.allowanceQTYType === QTYType.Shared) {
                covered = qty;
            }
            if (item.allowanceQTYType === QTYType.Unlimited) {
                covered =
                    Number(ConvertUnits(1000, GetUnits(item.allowanceType), tender.useNewDataConversion)) * coveredUsers;
            }
            remaining = Math.max(remaining - covered, 0);
            coveredByTender = coveredByTender + covered;
            out[key] = { ...out[key], remaining, coveredByTender };
        }
    }
    return out;
};
const usageCoveredByRoamLikeHome = (tender, proposal, out) => {
    const { rackRate = {} } = proposal;
    const bundles = (proposal.bundles || []).filter(({ roamLikeHome = false }) => roamLikeHome);
    if (bundles.length === 0) {
        return out;
    }
    const roamLikeHomeAvailable = {
        [UnitType.Data]: 0,
        [UnitType.Mins]: 0,
        [UnitType.SMS]: 0,
        [UnitType.MMS]: 0,
    };
    for (const key of [
        AllowanceTypes["UK Data (GB)"],
        AllowanceTypes["Std Calls (mins)"],
        AllowanceTypes["Std SMS"],
        AllowanceTypes["Std MMS"],
    ]) {
        const { used, coveredByTender } = out[key];
        const tenderCoverageLeft = Math.max(coveredByTender - used, 0);
        const unitType = GetUnits(key);
        roamLikeHomeAvailable[unitType] += tenderCoverageLeft;
    }
    for (const bundle of bundles) {
        const { applyToRows } = bundle;
        const sorted = [...applyToRows].sort((a, b) => rackRate[a] - rackRate[b]);
        const hasUsage = sorted.some((key) => {
            return (out[key]?.used || 0) > 0;
        });
        if (tender.usageId !== undefined && !hasUsage) {
            continue;
        }
        const limits = {
            [UnitType.Data]: bundle.dataMB === undefined ? undefined : Number(bundle.dataMB),
            [UnitType.SMS]: bundle.sms === undefined ? undefined : Number(bundle.sms),
            [UnitType.MMS]: bundle.mms === undefined ? undefined : Number(bundle.mms),
            [UnitType.Mins]: bundle.mins === undefined
                ? undefined
                : bundle.mins * 60,
        };
        const canApply = {
            [UnitType.Data]: limits[UnitType.Data]
                ? Math.min(limits[UnitType.Data] * bundle.numBundles, roamLikeHomeAvailable[UnitType.Data])
                : roamLikeHomeAvailable[UnitType.Data],
            [UnitType.SMS]: limits[UnitType.SMS]
                ? Math.min(limits[UnitType.SMS] * bundle.numBundles, roamLikeHomeAvailable[UnitType.SMS])
                : roamLikeHomeAvailable[UnitType.SMS],
            [UnitType.MMS]: limits[UnitType.MMS]
                ? Math.min(limits[UnitType.MMS] * bundle.numBundles, roamLikeHomeAvailable[UnitType.MMS])
                : roamLikeHomeAvailable[UnitType.MMS],
            [UnitType.Mins]: limits[UnitType.Mins]
                ? Math.min(limits[UnitType.Mins] * bundle.numBundles, roamLikeHomeAvailable[UnitType.Mins])
                : roamLikeHomeAvailable[UnitType.Mins],
        };
        let cost = true;
        for (const key of sorted) {
            if (out[key] === undefined) {
                continue;
            }
            let { remaining, coveredByBundles, costOfBundles, used } = out[key];
            const type = GetUnits(key);
            coveredByBundles += canApply[type];
            remaining -= canApply[type];
            remaining = Math.max(remaining, 0);
            if (cost && used > 0) {
                costOfBundles += bundle.cost * bundle.numBundles;
                cost = false;
            }
            out[key] = {
                ...out[key],
                remaining,
                coveredByBundles,
                costOfBundles,
            };
        }
    }
    return out;
};
const usageCoveredByBundles = (tender, proposal, out) => {
    const { rackRate = {} } = proposal;
    const bundles = (proposal.bundles || []).filter(({ roamLikeHome = false }) => roamLikeHome === false);
    if (bundles.length === 0) {
        return out;
    }
    for (const bundle of bundles) {
        const { applyToRows } = bundle;
        const sorted = [...applyToRows].sort((a, b) => rackRate[a] - rackRate[b]);
        const hasUsage = sorted.some((key) => {
            return (out[key]?.used || 0) > 0;
        });
        if (tender.usageId !== undefined && !hasUsage) {
            continue;
        }
        const canApply = {
            [UnitType.Data]: (bundle.dataMB
                ? bundle.dataMB === "Unlimited"
                    ? 1000
                    : bundle.dataMB
                : 0) * bundle.numBundles,
            [UnitType.SMS]: (bundle.sms
                ? bundle.sms === "Unlimited"
                    ? 1000
                    : bundle.sms
                : 0) * bundle.numBundles,
            [UnitType.MMS]: (bundle.mms
                ? bundle.mms === "Unlimited"
                    ? 1000
                    : bundle.mms
                : 0) * bundle.numBundles,
            [UnitType.Mins]: (bundle.mins
                ? bundle.mins === "Unlimited"
                    ? 60000
                    : bundle.mins * 60
                : 0) * bundle.numBundles,
        };
        let cost = true;
        for (const key of sorted) {
            if (out[key] === undefined) {
                continue;
            }
            let { remaining, coveredByBundles, costOfBundles, used } = out[key];
            const type = GetUnits(key);
            coveredByBundles += canApply[type];
            remaining -= canApply[type];
            remaining = Math.max(remaining, 0);
            if (cost && used > 0) {
                costOfBundles += bundle.cost * bundle.numBundles;
                cost = false;
            }
            out[key] = {
                ...out[key],
                remaining,
                coveredByBundles,
                costOfBundles,
            };
        }
    }
    return out;
};
