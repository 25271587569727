import React from "react";
import Card from "@Components/Card";
import ProposalSummary from "@Components/ProposalSummary";
export default class ReviewAndSubmit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
        };
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, { title: "Review and Submit" },
                React.createElement(ProposalSummary, { response: this.props.response, devices: this.props.devices, tender: this.props.tender }))));
    }
}
