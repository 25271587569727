import React from "react";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import Input from "@Components/Form/Input";
import Delete from "@Components/Icons/Delete";
import Table from "@Components/Table";
import { Effected, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import GetTotalLeasingCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalLeasingCost";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
export default class LeasingPrices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
    }
    static defaultProps = {
        networkLockedData: [],
        pickedHandsets: [],
        leasingErrors: {},
    };
    updateLeasing = (id, value, field) => {
        const data = this.props.leasingData;
        let index = data.findIndex(({ deviceId }) => deviceId === id);
        if (index === -1) {
            index = data.length;
        }
        let object = data[index];
        let number_ = Number(value);
        if (Number.isNaN(number_)) {
            number_ = 0;
        }
        object[field] = number_;
        object = this.findCosts(field, object);
        data[index] = object;
        this.props.update({ name: Effected.LeasingData, value: data });
    };
    findCosts = (field, object) => {
        switch (field) {
            case "quantity":
            case "monthlyCost":
                if (object.monthlyCost !== undefined &&
                    object.quantity !== undefined) {
                    object.totalMonthlyCost =
                        object.quantity * object.monthlyCost;
                }
                break;
            case "totalMonthlyCost":
                if (object.quantity !== undefined) {
                    object.monthlyCost =
                        object.totalMonthlyCost / object.quantity;
                }
                break;
        }
        return object;
    };
    addDevice = () => {
        const data = this.props.leasingData;
        const id = this.state.data.deviceId;
        if (id === undefined || data.some(({ deviceId }) => deviceId === id)) {
            return Promise.resolve();
        }
        data.push({
            deviceId: id,
            latestId: id,
        });
        this.props.update({ name: Effected.LeasingData, value: data });
        return Promise.resolve();
    };
    inputChange = ({ value }) => {
        const data = { deviceId: value };
        this.setState({
            data,
        });
    };
    deleteDevice = (id) => () => {
        let data = this.props.leasingData;
        data = data.filter(({ deviceId }) => deviceId !== id);
        this.props.update({ name: Effected.LeasingData, value: data });
        return Promise.resolve();
    };
    render() {
        const totalLeasingCost = GetTotalLeasingCost(this.props.leasingData);
        const fields = [
            {
                field: "deviceId",
                hidden: true,
            },
            {
                header: "Device Name",
                field: "deviceName",
                alias: ({ latestId }) => {
                    const device = this.props.devices.find(({ id }) => id === latestId);
                    return device ? device.name : "";
                },
                leftAlign: true,
            },
            {
                header: "Quantity",
                field: "quantity",
                display: (data) => {
                    return (React.createElement(Input, { type: "number", value: data.quantity, name: data.deviceId, valueChanged: (id, value) => this.updateLeasing(id, value, "quantity"), error: data.error[data.deviceId]?.quantity }));
                },
            },
            {
                header: "Monthly Leasing Cost per Device",
                field: "monthlyCost",
                display: (data) => {
                    return (React.createElement(Input, { type: "currency", value: data.monthlyCost, name: data.deviceId, valueChanged: (id, value) => this.updateLeasing(id, value, "monthlyCost"), error: data.error[data.deviceId]?.monthlyCost }));
                },
            },
            {
                header: "Total Monthly Leasing Cost",
                field: "totalMonthlyCost",
                display: (data) => {
                    return (React.createElement(Input, { type: "currency", value: data.totalMonthlyCost, name: data.deviceId, error: data.error[data.deviceId]?.totalMonthlyCost, disabled: true }));
                },
            },
            {
                header: "",
                field: "delete",
                display: ({ deviceId }) => {
                    return (React.createElement(Delete, { noFill: true, color: `#DD4B39`, onClick: this.deleteDevice(deviceId), className: styles.deleteButton }));
                },
            },
        ];
        const leasingFields = [
            ...fields,
            {
                field: "error",
                hidden: true,
                alias: () => this.props.leasingErrors,
            },
        ];
        const networkLockedData = this.props.leasingData;
        const deviceOptions = this.props.devices
            .filter(({ id, deletedAt }) => deletedAt === undefined &&
            !this.props.leasingData.some(({ latestId }) => latestId === id))
            .map(({ name, id }) => ({ label: name, value: id }));
        return (React.createElement(Card, { title: "Device Prices", className: this.props.className },
            React.createElement(Table, { fields: leasingFields, data: networkLockedData, noNav: true }),
            React.createElement(Form, { submit: this.addDevice, data: this.state.data, labelLeft: true, inputChange: this.inputChange, className: styles.form, buttonText: "ADD", input: [
                    {
                        label: "Which device would you like to offer? (optional)",
                        name: "deviceId",
                        type: InputType.DropdownOptions,
                        options: deviceOptions,
                    },
                ] }),
            React.createElement("div", { className: styles.totalWrapper },
                React.createElement("div", { className: styles.filler }),
                React.createElement("div", { className: styles.total },
                    "Total: ",
                    format(totalLeasingCost)))));
    }
}
