import React from "react";
import Table from "@Components/Table";
import { Effected, Platform, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
export default class AdditionalLinesTable extends React.Component {
    render() {
        if (this.props.tariffComponents.length === 0) {
            return React.createElement(React.Fragment, null);
        }
        const data = groupedTariff(this.props.tariffComponents || []);
        const fields = [
            {
                header: "Network",
                field: "platform",
                leftAlign: true,
                alias: ({ platform }) => {
                    return displayEnum(Platform, platform);
                },
            },
            {
                header: "Tariff Type",
                leftAlign: true,
                field: "type",
            },
            {
                header: "Lines",
                rightAlign: true,
                field: "lines",
            },
            {
                header: "Data Allowance",
                rightAlign: true,
                field: "dataAllowance",
                alias: ({ dataAllowance }) => {
                    return `${dataAllowance} GB`;
                },
            },
            {
                header: "Cost",
                field: "cost",
                rightAlign: true,
                alias: ({ id }) => {
                    return (React.createElement("div", { className: styles.number }, format(this.props.data[id]?.[Effected.AdditionalLineCost])));
                },
            },
        ];
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", null, this.props.title),
            React.createElement(Table, { data: data, fields: fields, noNav: true, className: styles.table })));
    }
}
