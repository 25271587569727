import SVG from "@Components/Icons/SVG";
export default class VArrowRight extends SVG {
    path = [
        "M 1.1231 1.1231",
        "a 3.8462 3.8462 90 0 1 5.4462 0",
        "l 46.1538 46.1538",
        "a 3.8462 3.8462 90 0 1 0 5.4462",
        "l -46.1538 46.1538",
        "a 3.8462 3.8462 90 0 1 -5.4462 -5.4462",
        "L 44.5615 50",
        "L 1.1231 6.5692",
        "a 3.8462 3.8462 90 0 1 0 -5.4462 z",
    ];
    evenOdd = true;
    strokeDetails = {
        stroke: `#FFFFFF`,
        strokeWidth: "5",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
}
