import React from "react";
import Form, { InputType } from "@Components/Form";
import { Effected, MDMNames, MDMOS, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export default class MDMForm extends React.Component {
    render() {
        return (React.createElement(Form, { submit: this.props.submit, data: this.props.data, errors: this.props.errors, noSave: this.props.buttonText === undefined, buttonText: this.props.buttonText, inputChange: this.props.inputChange, labelLeft: true, input: [
                {
                    label: "Do you offer Mobile Device Management? (MDM)",
                    name: Effected.OfferMDM,
                    type: InputType.SmallToggle,
                },
                {
                    label: "Which MDM tool are you offering?",
                    name: Effected.MDMName,
                    type: InputType.DropdownEnum,
                    options: MDMNames,
                    hidden: !this.props.data[Effected.OfferMDM],
                },
                {
                    label: "Please specify MDM tool version?",
                    name: Effected.MDMDetails,
                    type: InputType.Text,
                    hidden: !this.props.data[Effected.OfferMDM] ||
                        this.props.data[Effected.MDMName] ===
                            MDMNames.Other ||
                        this.props.data[Effected.MDMName] === undefined,
                },
                {
                    label: "What is the name of the MDM?",
                    name: Effected.OtherName,
                    type: InputType.Text,
                    hidden: !this.props.data[Effected.OfferMDM] ||
                        this.props.data[Effected.MDMName] !==
                            MDMNames.Other,
                },
                {
                    label: "Which of the following applies to your MDM solution?",
                    name: Effected.MDMOS,
                    type: InputType.DropdownEnum,
                    options: MDMOS,
                    placeholder: "Pick an option...",
                    hidden: !this.props.data[Effected.OfferMDM],
                },
                {
                    label: (React.createElement(React.Fragment, null, "Is the MDM solution included free as part of your tariff solution?")),
                    name: Effected.IsCloudFree,
                    type: InputType.SmallToggle,
                    hidden: !this.props.data[Effected.OfferMDM],
                },
                {
                    label: (React.createElement(React.Fragment, null, "Please detail cost per line per month for your MDM solution")),
                    name: Effected.MDMLineCost,
                    type: InputType.Currency,
                    hidden: !this.props.data[Effected.OfferMDM] ||
                        this.props.data.isCloudFree === true,
                },
                {
                    label: "What is the minimum commitment period for the MDM solution?",
                    name: Effected.MinCommitmentPeriod,
                    type: InputType.DropdownEnum,
                    options: TimePeriods,
                    placeholder: "Pick a time period...",
                    hidden: !this.props.data[Effected.OfferMDM],
                },
                {
                    label: "How long is your minimum commitment period in months?",
                    name: Effected.MinCommitmentPeriodOther,
                    type: InputType.Number,
                    hidden: !this.props.data[Effected.OfferMDM] ||
                        this.props.data.minCommitmentPeriod !==
                            TimePeriods.Other,
                },
                {
                    label: "Do you offer a range of MDM options?",
                    name: Effected.MDMRange,
                    type: InputType.SmallToggle,
                    hidden: !this.props.data[Effected.OfferMDM],
                },
                {
                    label: "Does this include enterprise versions?",
                    name: Effected.EnterpriseVersions,
                    type: InputType.SmallToggle,
                    hidden: !this.props.data[Effected.OfferMDM] ||
                        !this.props.data.MDMRange,
                },
                {
                    label: (React.createElement(React.Fragment, null, "Would you suggest any alternatives to your MDM solution, if so please detail?")),
                    name: Effected.AlternativeSolution,
                    type: InputType.LargeText,
                    characters: 1000,
                    hidden: !this.props.data[Effected.OfferMDM],
                },
                {
                    label: "Do you have any further concise information on MDM the customer should know?",
                    name: Effected.FurtherMDM,
                    type: InputType.LargeText,
                    characters: 1000,
                    hidden: !this.props.data[Effected.OfferMDM],
                },
            ] }));
    }
}
