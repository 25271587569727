import React, { useEffect, useState } from "react";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import HoverTooltip from "@Components/HoverTooltip";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { getIconFromPlatform } from "@Helpers/Network";
import { AdditionalDataType, Effected, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import Factory from "@Shared/Factory";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
import { format } from "@Shared/Helpers/Money";
import accordionStyles from "@SmartComponents/TenderResponses/PagePopup/Summary/Accordion/index.module.scss";
export default (props) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const currentData = props.data[Effected.AdditionalData] || {};
        let changed = false;
        for (const { id } of groupedTariff(props.data?.tariffComponents || [])) {
            const currentObject = currentData[id] || {};
            if (currentObject[Effected.AdditionalDataNoNewLine] === undefined) {
                changed = true;
                currentObject[Effected.AdditionalDataNoNewLine] = false;
            }
            currentData[id] = currentObject;
        }
        if (!changed) {
            return setLoading(false);
        }
        const proposal = {
            ...props.data,
            [Effected.AdditionalData]: currentData,
        };
        const response = Factory.Response({
            ...props.response.toSave,
            proposal,
        });
        props.save(response, true).then(() => {
            setLoading(false);
        });
    }, []);
    if (loading) {
        return React.createElement(LoadingFallback, null);
    }
    const inputChange = (id) => (input) => {
        const { value, name } = input;
        props.inputChange({
            name: `${Effected.AdditionalData}.${id}.${name}`,
            value,
        });
    };
    const networkLogo = (platform) => {
        const Icon = getIconFromPlatform(platform);
        if (Icon === undefined) {
            return "---";
        }
        return React.createElement(Icon, null);
    };
    return (React.createElement(React.Fragment, null, groupedTariff(props.data?.tariffComponents || []).map((gts) => {
        return (React.createElement(Card, { title: React.createElement(React.Fragment, null, "Additional Data"), key: gts.id },
            React.createElement("ul", null,
                React.createElement("li", { className: accordionStyles.subitem },
                    React.createElement("div", null, "Network"),
                    React.createElement("div", { className: accordionStyles.value }, networkLogo(gts.platform))),
                React.createElement("li", { className: accordionStyles.subitem },
                    React.createElement("div", null, "Tariff type"),
                    React.createElement("div", { className: accordionStyles.value }, gts.type)),
                gts.lines > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                    React.createElement("div", null, "Lines"),
                    React.createElement("div", { className: accordionStyles.value }, gts.lines))),
                gts.dataLines > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                    React.createElement("div", null, "Data lines"),
                    React.createElement("div", { className: accordionStyles.value }, gts.dataLines))),
                React.createElement("li", { className: accordionStyles.subitem },
                    React.createElement("div", null, "Data Allowance"),
                    React.createElement("div", { className: accordionStyles.value }, `${gts.dataAllowance} GB`)),
                gts.additionalLineCost > 0 && (React.createElement("li", { className: accordionStyles.subitem },
                    React.createElement("div", null, "Cost for an additional line"),
                    React.createElement("div", { className: accordionStyles.value }, format(gts.additionalLineCost))))),
            React.createElement(Form, { data: props.data?.[Effected.AdditionalData]?.[gts.id] || {}, errors: props.errors?.[Effected.AdditionalData]?.[gts.id] || {}, noSave: true, inputChange: inputChange(gts.id), labelLeft: true, setErrors: props.setErrors, input: [
                    {
                        label: "Can data only be increased by adding a new line?",
                        name: Effected.AdditionalDataNoNewLine,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Is additional data automatically added? ie. aggregated data tariff",
                        name: Effected.AdditionalDataAuto,
                        hidden: props.data[Effected.AdditionalData]?.[gts.id]?.[Effected.AdditionalDataNoNewLine],
                        type: InputType.SmallToggle,
                    },
                    {
                        label: (React.createElement(React.Fragment, null,
                            "When adding additional data, what is the minimum amount?",
                            React.createElement(HoverTooltip, null, "We only accept an amount of 1GB and up for accurate cost calculations"))),
                        name: Effected.AdditionalDataMinQty,
                        hidden: props.data[Effected.AdditionalData]?.[gts.id]?.[Effected.AdditionalDataNoNewLine],
                        type: InputType.DropdownOptions,
                        options: [
                            {
                                value: 1,
                                label: "1GB",
                            },
                            {
                                value: 2,
                                label: "2GB",
                            },
                            {
                                value: 3,
                                label: "3GB",
                            },
                            {
                                value: 4,
                                label: "4GB",
                            },
                            {
                                value: 5,
                                label: "5GB",
                            },
                            {
                                value: 10,
                                label: "10GB",
                            },
                            {
                                value: 20,
                                label: "20GB",
                            },
                            {
                                value: 25,
                                label: "25GB",
                            },
                            {
                                value: 50,
                                label: "50GB",
                            },
                            {
                                value: 75,
                                label: "75GB",
                            },
                            {
                                value: 100,
                                label: "100GB",
                            },
                        ],
                    },
                    {
                        label: (React.createElement(React.Fragment, null,
                            "Is this data added per line or to the total data pool?",
                            React.createElement(HoverTooltip, null, "For the avoidance of doubt, aggregated data is \"per\u00A0line\""))),
                        name: Effected.AdditionalDataType,
                        hidden: props.data[Effected.AdditionalData]?.[gts.id]?.[Effected.AdditionalDataNoNewLine],
                        type: InputType.LargeToggle,
                        toggleOptions: [
                            {
                                value: AdditionalDataType.Per_Line,
                                label: "Per Line",
                            },
                            {
                                value: AdditionalDataType.To_the_Pool,
                                label: "To the Pool",
                            },
                        ],
                    },
                    {
                        label: `How much does ${props.data?.additionalData?.[gts.id]?.[Effected.AdditionalDataMinQty] || 1}GB cost?`,
                        name: Effected.AdditionalDataCost,
                        hidden: props.data[Effected.AdditionalData]?.[gts.id]?.[Effected.AdditionalDataNoNewLine],
                        type: InputType.Currency,
                    },
                ] })));
    })));
};
