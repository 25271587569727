import { DetailsFields, LikeHardwareFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Effected } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import groupedTariff from "@Shared/Helpers/GroupedTariff";
import { validation } from "@Shared/Validate";
import { additionalDataCostValidConstraints, additionalDataPresenceConstraints, additionalDataValidConstraints, } from "@SmartComponents/Proposal/AdditionalData/constraints";
import { additionalLinesCostValidConstraints, additionalLinesPresenceConstraints, additionalLinesValidConstraints, } from "@SmartComponents/Proposal/AdditionalLines/constraints";
import { customerServicePresenceConstraints, customerServiceValidConstraints, } from "@SmartComponents/Proposal/CustomerService/constraints";
import { dmPresenceConstraints, dmValidConstraints, } from "@SmartComponents/Proposal/DM/constraints";
import { hardwareFundPresenceConstraints, hardwareFundValidConstraints, } from "@SmartComponents/Proposal/HardwareFund/constraints";
import { devicePricesConstraints } from "@SmartComponents/Proposal/HardwareFund/DevicePrices/constraints";
import { leasingPricesConstraints } from "@SmartComponents/Proposal/HardwareFund/LeasingPrices/constraints";
import { mdmPresenceConstraints, mdmValidConstraints, } from "@SmartComponents/Proposal/MDM/constraints";
import { networkPresenceConstraints, networkValidConstraints, } from "@SmartComponents/Proposal/Network/constraints";
import { oobPresenceConstraints, oobValidConstraints, } from "@SmartComponents/Proposal/Oob/constraints";
import { allowanceConstraints, tariffComponentConstraints, tariffPresenceConstraints, tariffValidConstraints, } from "@SmartComponents/Proposal/Tariff/constraints";
export const getAllData = (data) => {
    return {
        ...data,
        ...getTariffData(data),
        [Effected.Rack_Rate]: getOOBData(data),
        ...getHardwareFundData(data),
        ...getNetworkData(data),
        ...getAdditionalLinesData(data),
        ...getMDMData(data),
        ...getCustomerServiceData(data),
    };
};
//tariff
export const validateTariff = (data) => {
    const tariffComplete = checkPresenceTariff(data);
    if (tariffComplete === false) {
        return false;
    }
    return checkValidTariff(data);
};
export const checkPresenceTariff = (data) => {
    const tariffData = getTariffData(data);
    try {
        validation(tariffData, tariffPresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidTariff = (data) => {
    const tariffData = getTariffData(data);
    const tariffComponents = tariffData[Effected.TariffComponents] || [];
    let errors = {};
    try {
        validation(tariffData, tariffValidConstraints);
    }
    catch (error) {
        errors = error.fails;
    }
    const componentsErrors = checkValidTariffComponents(tariffComponents);
    if (componentsErrors !== undefined) {
        errors[Effected.TariffComponents] = componentsErrors;
    }
    if (Object.keys(errors).length === 0) {
        return true;
    }
    throw errors;
};
export const checkValidTariffComponents = (components) => {
    const errorsArray = [];
    let index = 0;
    for (const component of components) {
        let errors = {};
        try {
            validation(component, tariffComponentConstraints);
        }
        catch (error) {
            errors = error.fails;
        }
        const { [Effected.TariffComponentItems]: allowances = [] } = component;
        const allowanceErrors = [];
        let ii = 0;
        for (const allowance of allowances) {
            try {
                validation(allowance, allowanceConstraints);
            }
            catch (error) {
                allowanceErrors[ii] = error.fails;
            }
            ii++;
        }
        if (allowanceErrors.length === 0) {
            if (Object.keys(errors).length > 0) {
                errorsArray[index] = errors;
            }
        }
        else {
            errorsArray[index] = {
                ...errors,
                [Effected.TariffComponentItems]: allowanceErrors,
            };
        }
        index++;
    }
    if (errorsArray.length > 0) {
        return errorsArray;
    }
};
export const getTariffData = (data) => {
    return {
        [Effected.PlanName]: data[Effected.PlanName],
        [Effected.Discount]: data[Effected.Discount],
        [Effected.ProposalDependant]: data[Effected.ProposalDependant] || false,
        [Effected.SpecificChangesOrRequirements]: data[Effected.SpecificChangesOrRequirements],
        [Effected.ESims]: data[Effected.ESims] || false,
        [Effected.IsManagementFee]: data[Effected.IsManagementFee] || false,
        [Effected.ManagementFeeChargeMethod]: data[Effected.ManagementFeeChargeMethod],
        [Effected.ManagementFee]: data[Effected.ManagementFee],
        [Effected.HasMinimumSpend]: data[Effected.HasMinimumSpend] || false,
        [Effected.MinimumSpend]: data[Effected.MinimumSpend],
        [Effected.ReducedHWFund]: data[Effected.ReducedHWFund] || false,
        [Effected.TariffComponents]: data[Effected.TariffComponents],
    };
};
//OOB
export const validateOOB = (data, hasUsage, covered) => {
    const oobComplete = checkPresenceOOB(data, hasUsage, covered);
    if (oobComplete === false) {
        return false;
    }
    return checkValidOOB(data, hasUsage, covered);
};
export const checkPresenceOOB = (data, hasUsage, covered) => {
    const oobData = getOOBData(data);
    try {
        validation(oobData, oobPresenceConstraints(hasUsage, covered));
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidOOB = (data, hasUsage, covered) => {
    const oobData = getOOBData(data);
    try {
        validation(oobData, oobValidConstraints(hasUsage, covered));
        return true;
    }
    catch (error) {
        throw error.fails;
    }
};
export const getOOBData = (data) => {
    return data[Effected.Rack_Rate] || {};
};
//Hardware Fund
export const validateHardwareFund = (data, devices, tender) => {
    const hardwareFundComplete = checkPresenceHardwareFund(data, tender);
    if (hardwareFundComplete === false) {
        return false;
    }
    return checkValidHardwareFund(data, devices, tender);
};
export const checkPresenceHardwareFund = (data, tender) => {
    const hardwareFundData = getHardwareFundData(data);
    try {
        validation({
            ...hardwareFundData,
            [DetailsFields.LikeHardwareFunded]: tender.details.likeHardwareFunded,
            [DetailsFields.SimOnlyFunded]: tender.details.simOnlyFunded,
        }, hardwareFundPresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidHardwareFund = (data, devices, tender) => {
    const hardwareFundData = getHardwareFundData(data);
    let errors = {};
    try {
        validation({
            ...hardwareFundData,
            [DetailsFields.LikeHardwareFunded]: tender.details.likeHardwareFunded,
            [DetailsFields.SimOnlyFunded]: tender.details.simOnlyFunded,
        }, hardwareFundValidConstraints);
    }
    catch (error) {
        errors = error.fails;
    }
    const componentsErrors = {};
    const devicePrices = hardwareFundData[Effected.DevicePrices] || [];
    //if statement to check if standard devices are needed
    if (tender.details.likeHardwareFunded !== LikeHardwareFunded.Leasing) {
        componentsErrors[Effected.DevicePrices] =
            checkValidDevicePrices(devicePrices);
    }
    //if statement to check if leasing is needed
    if (tender.details.likeHardwareFunded === LikeHardwareFunded.Leasing &&
        data[Effected.OfferLeasing] === true) {
        const leasingData = hardwareFundData[Effected.LeasingData] || [];
        componentsErrors[Effected.LeasingData] =
            checkValidLeasingData(leasingData);
    }
    const cleanedComponentErrors = Object.keys(componentsErrors).reduce((object, key) => {
        if (componentsErrors[key] !== undefined &&
            Object.keys(componentsErrors[key]).length > 0) {
            object[key] = componentsErrors[key];
        }
        return object;
    }, {});
    if (Object.keys(cleanedComponentErrors).length > 0) {
        errors = { ...errors, ...cleanedComponentErrors };
    }
    if (Object.keys(errors).length === 0) {
        return true;
    }
    throw errors;
};
const checkValidDevicePrices = (devicePriceData) => {
    return checkValidDeviceData(devicePriceData, devicePricesConstraints);
};
const checkValidLeasingData = (leasingData) => {
    const data = leasingData;
    return checkValidDeviceData(data, leasingPricesConstraints);
};
const checkValidDeviceData = (data, constraints) => {
    const errors = {};
    for (const object of data) {
        try {
            validation(object, constraints);
        }
        catch (error) {
            errors[object.deviceId] = error.fails;
        }
    }
    if (errors.length !== 0) {
        return errors;
    }
};
export const getHardwareFundData = (data) => {
    return {
        [Effected.TotalFund]: data[Effected.TotalFund],
        [Effected.CashHW]: data[Effected.CashHW] || false,
        [Effected.HWFundTaken]: data[Effected.HWFundTaken],
        [Effected.DrawDown]: data[Effected.DrawDown],
        [Effected.DeductSims]: data[Effected.DeductSims] || false,
        [Effected.DeductedPerSim]: data[Effected.DeductedPerSim],
        [Effected.SimCost]: data[Effected.SimCost] || false,
        [Effected.ChargePerSim]: data[Effected.ChargePerSim],
        //device prices
        [Effected.DevicePrices]: data[Effected.DevicePrices],
        [Effected.OfferLeasing]: data[Effected.OfferLeasing] || false,
        [Effected.SetupFee]: data[Effected.SetupFee],
        [Effected.MinimumLeaseTerm]: data[Effected.MinimumLeaseTerm],
        //leasing device prices
        [Effected.LeasingData]: data[Effected.LeasingData],
        [Effected.LeasingRequiresInsurance]: data[Effected.LeasingRequiresInsurance] || false,
        [Effected.DirectDebit]: data[Effected.DirectDebit] || false,
        [Effected.DirectDebitCost]: castToNumber(data[Effected.DirectDebitCost]),
        [Effected.LeasingLumpSum]: data[Effected.LeasingLumpSum],
        [Effected.LeasingNotes]: data[Effected.LeasingNotes],
        [Effected.HandsetProfitMargin]: data[Effected.HandsetProfitMargin] || false,
        [Effected.AppleDEP]: data[Effected.AppleDEP] || false,
        [Effected.DeviceInsurance]: data[Effected.DeviceInsurance] || false,
        [Effected.ChargeForDelivery]: data[Effected.ChargeForDelivery] || false,
        [Effected.DeliveryChargeMethod]: data[Effected.DeliveryChargeMethod],
        [Effected.DeliveryCharge]: data[Effected.DeliveryCharge],
        [Effected.ExtraDeviceDetails]: data[Effected.ExtraDeviceDetails],
    };
};
//Network
export const validateNetwork = (data) => {
    const networkComplete = checkPresenceNetwork(data);
    if (networkComplete === false) {
        return false;
    }
    return checkValidNetwork(data);
};
export const checkPresenceNetwork = (data) => {
    const networkData = getNetworkData(data);
    try {
        validation(networkData, networkPresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidNetwork = (data) => {
    const networkData = getNetworkData(data);
    try {
        validation(networkData, networkValidConstraints);
        return true;
    }
    catch (error) {
        throw error.fails;
    }
};
const castToNumber = (n) => (n ? Number(n) : undefined);
export const getNetworkData = (data) => {
    return {
        [Effected.MultipleNetworks]: data[Effected.MultipleNetworks] || false,
        [Effected.SelectNetworks]: data[Effected.SelectNetworks],
        [Effected.BillingRelationship]: data[Effected.BillingRelationship],
        [Effected.BillingRelationshipChoice]: data[Effected.BillingRelationshipChoice],
        [Effected.NetworkRelationship]: data[Effected.NetworkRelationship],
        [Effected.KeepSims]: data[Effected.KeepSims] || false,
        [Effected.Increases]: data[Effected.Increases] || false,
        [Effected.IncreaseMeasurement]: data[Effected.IncreaseMeasurement],
        [Effected.AdditionalIncrease]: data[Effected.AdditionalIncrease] || false,
        [Effected.AdditionalIncreasePercentage]: castToNumber(data[Effected.AdditionalIncreasePercentage]),
        [Effected.MandatoryRise]: data[Effected.MandatoryRise] || false,
        [Effected.MandatoryRisePercentage]: castToNumber(data[Effected.MandatoryRisePercentage]),
        [Effected.BreakClause]: data[Effected.BreakClause] || false,
        [Effected.AdditionalNetworkInfo]: data[Effected.AdditionalNetworkInfo],
    };
};
//Additional Lines
export const validateAdditionalLines = (data, isSmallBusiness) => {
    const additionalLinesComplete = checkPresenceAdditionalLines(data, isSmallBusiness);
    if (additionalLinesComplete === false) {
        return false;
    }
    const tariffComponents = data[Effected.TariffComponents] || [];
    return checkValidAdditionalLines(data, tariffComponents, isSmallBusiness);
};
export const checkPresenceAdditionalLines = (data, isSmallBusiness) => {
    const additionalLinesData = getAdditionalLinesData(data);
    try {
        validation(additionalLinesData, additionalLinesPresenceConstraints(isSmallBusiness));
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidAdditionalLines = (data, tariffComponents, isSmallBusiness) => {
    const additionalLinesData = getAdditionalLinesData(data);
    const lineCosts = additionalLinesData[Effected.AdditionalLines] || {};
    const errors = {};
    try {
        validation(additionalLinesData, additionalLinesValidConstraints(isSmallBusiness));
    }
    catch (error) {
        throw error.fails;
    }
    const lineCostsErrors = checkValidLineCosts(lineCosts, tariffComponents);
    if (lineCostsErrors !== undefined) {
        errors[Effected.AdditionalLines] = lineCostsErrors;
    }
    if (Object.keys(errors).length === 0) {
        return true;
    }
    throw errors;
};
const checkValidLineCosts = (lineCosts, tariffComponents) => {
    const errorsObject = [];
    const groups = groupedTariff(tariffComponents);
    for (const { id, lines, dataLines } of groups) {
        try {
            const data = {
                lines,
                dataLines,
                ...lineCosts[id],
            };
            validation(data, additionalLinesCostValidConstraints);
        }
        catch (error) {
            errorsObject[id] = error.fails;
        }
    }
    if (Object.keys(errorsObject).length > 0) {
        return errorsObject;
    }
};
export const getAdditionalLinesData = (data) => {
    return {
        [Effected.AdditionalLinesCoTerminus]: data[Effected.AdditionalLinesCoTerminus] || false,
        [Effected.TimeLeftTerm]: data[Effected.TimeLeftTerm],
        [Effected.HWFundPerLine]: data[Effected.HWFundPerLine],
        [Effected.HWFundPerDataSim]: data[Effected.HWFundPerDataSim],
        [Effected.HWProRata]: data[Effected.HWProRata] || false,
        [Effected.AdditionalLinesInfo]: data[Effected.AdditionalLinesInfo],
        [Effected.AdditionalLines]: data[Effected.AdditionalLines],
    };
};
//Additional Data
export const validateAdditionalData = (data) => {
    const additionalDataComplete = checkPresenceAdditionalData(data);
    if (additionalDataComplete === false) {
        return false;
    }
    const tariffComponents = data[Effected.TariffComponents] || [];
    return checkValidAdditionalData(data, tariffComponents);
};
export const checkPresenceAdditionalData = (data) => {
    const additionalDataData = getAdditionalDataData(data);
    try {
        validation(additionalDataData, additionalDataPresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidAdditionalData = (data, tariffComponents) => {
    const additionalDataData = getAdditionalDataData(data);
    const dataCosts = additionalDataData[Effected.AdditionalData] || {};
    const errors = {};
    try {
        validation(additionalDataData, additionalDataValidConstraints);
    }
    catch (error) {
        throw error.fails;
    }
    const dataCostsErrors = checkValidDataCosts(dataCosts, tariffComponents);
    if (dataCostsErrors !== undefined) {
        errors[Effected.AdditionalData] = dataCostsErrors;
    }
    if (Object.keys(errors).length === 0) {
        return true;
    }
    throw errors;
};
const checkValidDataCosts = (dataCosts, tariffComponents) => {
    const errorsObject = [];
    const groups = groupedTariff(tariffComponents);
    for (const { id } of groups) {
        const dataCostObject = dataCosts[id] || {};
        const cleanedObject = {
            [Effected.AdditionalDataNoNewLine]: dataCostObject[Effected.AdditionalDataNoNewLine] || false,
            [Effected.AdditionalDataMinQty]: dataCostObject[Effected.AdditionalDataMinQty],
            [Effected.AdditionalDataType]: dataCostObject[Effected.AdditionalDataType],
            [Effected.AdditionalDataCost]: dataCostObject[Effected.AdditionalDataCost],
            [Effected.AdditionalDataAuto]: dataCostObject[Effected.AdditionalDataAuto] || false,
        };
        try {
            validation(cleanedObject, additionalDataCostValidConstraints);
        }
        catch (error) {
            errorsObject[id] = error.fails;
        }
    }
    if (Object.keys(errorsObject).length > 0) {
        return errorsObject;
    }
};
export const getAdditionalDataData = (data) => {
    return {
        [Effected.AdditionalData]: data[Effected.AdditionalData],
    };
};
//MDM
export const validateMDM = (data, noMDM) => {
    const mdmComplete = checkPresenceMDM(data, noMDM);
    if (mdmComplete === false) {
        return false;
    }
    return checkValidMDM(data, noMDM);
};
export const checkPresenceMDM = (data, noMDM) => {
    if (noMDM) {
        return true;
    }
    const mdmData = getMDMData(data);
    try {
        validation(mdmData, mdmPresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidMDM = (data, noMDM) => {
    if (noMDM) {
        return true;
    }
    const mdmData = getMDMData(data);
    try {
        validation(mdmData, mdmValidConstraints);
        return true;
    }
    catch (error) {
        throw error.fails;
    }
};
export const getMDMData = (data) => {
    return {
        [Effected.OfferMDM]: data[Effected.OfferMDM],
        [Effected.MDMName]: data[Effected.MDMName],
        [Effected.OtherName]: data[Effected.OtherName],
        [Effected.MDMOS]: data[Effected.MDMOS],
        [Effected.MinCommitmentPeriod]: data[Effected.MinCommitmentPeriod],
        [Effected.MinCommitmentPeriodOther]: data[Effected.MinCommitmentPeriodOther] !== undefined &&
            data[Effected.MinCommitmentPeriodOther] !== ""
            ? Number(data[Effected.MinCommitmentPeriodOther])
            : undefined,
        [Effected.IsCloudFree]: data[Effected.IsCloudFree] || false,
        [Effected.MDMLineCost]: data[Effected.MDMLineCost],
        [Effected.MDMRange]: data[Effected.MDMRange] || false,
        [Effected.EnterpriseVersions]: data[Effected.EnterpriseVersions] || false,
        [Effected.AlternativeSolution]: data[Effected.AlternativeSolution],
        [Effected.FurtherMDM]: data[Effected.FurtherMDM],
    };
};
//DM
export const validatedDM = (data) => {
    const dmComplete = checkPresenceDM(data);
    if (dmComplete === false) {
        return false;
    }
    return checkValidDM(data);
};
export const checkPresenceDM = (data) => {
    const dmData = getDMData(data);
    try {
        validation(dmData, dmPresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidDM = (data) => {
    const dmData = getDMData(data);
    try {
        validation(dmData, dmValidConstraints);
        return true;
    }
    catch (error) {
        throw error.fails;
    }
};
export const getDMData = (data) => {
    return {
        [Effected.OfferDM]: data[Effected.OfferDM],
        [Effected.DMTool]: data[Effected.DMTool],
        [Effected.DMOS]: data[Effected.DMOS],
        [Effected.DMFeatures]: data[Effected.DMFeatures],
        [Effected.DMFree]: data[Effected.DMFree] || false,
        [Effected.DMLineCost]: data[Effected.DMOS],
        [Effected.DMMinCommitmentPeriod]: data[Effected.DMMinCommitmentPeriod],
        [Effected.DMMinCommitmentPeriodOther]: data[Effected.DMMinCommitmentPeriodOther],
        [Effected.DMRange]: data[Effected.DMRange] || false,
        [Effected.DMEnterprise]: data[Effected.DMEnterprise] || false,
        [Effected.DMAlternative]: data[Effected.DMAlternative],
    };
};
//Customer Service
export const validateCustomerService = (data) => {
    const customerServiceComplete = checkPresenceCustomerService(data);
    if (customerServiceComplete === false) {
        return false;
    }
    return checkValidCustomerService(data);
};
export const checkPresenceCustomerService = (data) => {
    const customerServiceData = getCustomerServiceData(data);
    try {
        validation(customerServiceData, customerServicePresenceConstraints);
        return true;
    }
    catch {
        return false;
    }
};
export const checkValidCustomerService = (data) => {
    const customerServiceData = getCustomerServiceData(data);
    try {
        validation(customerServiceData, customerServiceValidConstraints);
        return true;
    }
    catch (error) {
        throw error.fails;
    }
};
export const getCustomerServiceData = (data) => {
    return {
        [Effected.AccountManaged]: data[Effected.AccountManaged] || false,
        [Effected.AccountManagerVisit]: data[Effected.AccountManagerVisit] || false,
        [Effected.AccountManagerVisitFrequency]: data[Effected.AccountManagerVisitFrequency],
        [Effected.ComplaintProcess]: data[Effected.ComplaintProcess] || false,
        [Effected.TicketSystem]: data[Effected.TicketSystem] || false,
        [Effected.VIPService]: data[Effected.VIPService] || false,
        [Effected.VIPServiceResponseTime]: data[Effected.VIPServiceResponseTime],
        [Effected.ResponseTime]: data[Effected.ResponseTime],
        [Effected.ResolutionTime]: data[Effected.ResolutionTime],
        [Effected.FreeNextDay]: data[Effected.FreeNextDay],
        [Effected.OngoingAnalysis]: data[Effected.OngoingAnalysis] || false,
        [Effected.AnalysisFrequency]: data[Effected.AnalysisFrequency],
        [Effected.OngoingAnalysisMethod]: data[Effected.OngoingAnalysisMethod],
        [Effected.DisposeHandsets]: data[Effected.DisposeHandsets] || false,
        [Effected.WEEECertificates]: data[Effected.WEEECertificates] || false,
        [Effected.FullRecycleValue]: data[Effected.FullRecycleValue] || false,
        [Effected.CustomTerms]: data[Effected.CustomTerms] || false,
        [Effected.PACCodeCharge]: data[Effected.PACCodeCharge] || false,
        [Effected.ChargeMethod]: data[Effected.ChargeMethod],
        [Effected.PACCost]: data[Effected.PACCost],
        [Effected.TerminationPenalty]: data[Effected.TerminationPenalty] || false,
        [Effected.PenaltyPerLine]: data[Effected.PenaltyPerLine],
        [Effected.PaperStatements]: data[Effected.PaperStatements] || false,
        [Effected.CostForStatements]: data[Effected.CostForStatements],
        [Effected.NonDirectDebitCharges]: data[Effected.NonDirectDebitCharges] || false,
        [Effected.NonDirectDebitCost]: data[Effected.NonDirectDebitCost],
        [Effected.ExtraChargesInTenders]: data[Effected.ExtraChargesInTenders] || false,
        [Effected.AdditionalChargeDetails]: data[Effected.AdditionalChargeDetails],
        [Effected.TotalMonthlyCost]: data[Effected.TotalMonthlyCost],
    };
};
