// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--LLrQU{font-size:.85rem}.number--Z040R{color:var(--alt-highlighted-text-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/LeasePricesTable/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,gBAAgB,CAAC,eAAQ,uCAAuC","sourcesContent":[".table{font-size:.85rem}.number{color:var(--alt-highlighted-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--LLrQU",
	"number": "number--Z040R"
};
export default ___CSS_LOADER_EXPORT___;
