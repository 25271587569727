// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".arrow--vARQ2{width:1.2em}.arrowRow--eC8V_{background-color:#f5f5f5 !important}.explanationBox--ACY_J{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);display:flex;margin-bottom:.5em;background-color:var(--tag-background-color)}.explanationBox--ACY_J .text--AzC7l{flex:1;font-size:.85rem}.explanationBox--ACY_J .text--AzC7l .superUsers--Hv5V2{padding-right:.5rem;font-weight:bold}.explanationBox--ACY_J .explanationLogo--g61ZS{width:30px}.table--W6V69{font-size:.75rem}.table--W6V69 .avgHeader--cViUw{white-space:nowrap}.table--W6V69 .titleColumn--VxBBk{width:12vw;padding-left:.5em !important}.table--W6V69 .monthData--MUmuB{width:12%}.placeholder--hal4A{width:20px;padding:2px}.placeholder--hal4A.logo--U8OH4{cursor:pointer}.selectedRow--sYi_B{font-weight:bold}", "",{"version":3,"sources":["webpack://./src/gui/Components/UsageTable/Tables/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,WAAW,CAAC,iBAAU,mCAAmC,CAAC,uBAAgB,2EAA2E,CAAC,YAAY,CAAC,kBAAkB,CAAC,4CAA4C,CAAC,oCAAsB,MAAM,CAAC,gBAAgB,CAAC,uDAAkC,mBAAmB,CAAC,gBAAgB,CAAC,+CAAiC,UAAU,CAAC,cAAO,gBAAgB,CAAC,gCAAkB,kBAAkB,CAAC,kCAAoB,UAAU,CAAC,4BAA4B,CAAC,gCAAkB,SAAS,CAAC,oBAAa,UAAU,CAAC,WAAW,CAAC,gCAAkB,cAAc,CAAC,oBAAa,gBAAgB","sourcesContent":[".arrow{width:1.2em}.arrowRow{background-color:#f5f5f5 !important}.explanationBox{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);display:flex;margin-bottom:.5em;background-color:var(--tag-background-color)}.explanationBox .text{flex:1;font-size:.85rem}.explanationBox .text .superUsers{padding-right:.5rem;font-weight:bold}.explanationBox .explanationLogo{width:30px}.table{font-size:.75rem}.table .avgHeader{white-space:nowrap}.table .titleColumn{width:12vw;padding-left:.5em !important}.table .monthData{width:12%}.placeholder{width:20px;padding:2px}.placeholder.logo{cursor:pointer}.selectedRow{font-weight:bold}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": "arrow--vARQ2",
	"arrowRow": "arrowRow--eC8V_",
	"explanationBox": "explanationBox--ACY_J",
	"text": "text--AzC7l",
	"superUsers": "superUsers--Hv5V2",
	"explanationLogo": "explanationLogo--g61ZS",
	"table": "table--W6V69",
	"avgHeader": "avgHeader--cViUw",
	"titleColumn": "titleColumn--VxBBk",
	"monthData": "monthData--MUmuB",
	"placeholder": "placeholder--hal4A",
	"logo": "logo--U8OH4",
	"selectedRow": "selectedRow--sYi_B"
};
export default ___CSS_LOADER_EXPORT___;
