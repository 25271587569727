import React from "react";
export default class TenderResponseIcon extends React.Component {
    render() {
        return (React.createElement("svg", { fill: "none", height: "149", viewBox: "0 0 152 149", width: "152", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { clipRule: "evenodd", d: "M76.7999 148.8C117.89 148.8 151.2 115.49 151.2 74.4C151.2 33.31 117.89 0 76.7999 0C35.7099 0 2.3999 33.31 2.3999 74.4C2.3999 115.49 35.7099 148.8 76.7999 148.8Z", fill: "#F0F6F6", fillRule: "evenodd", stroke: "white", strokeWidth: "1em", strokeLinejoin: "round" }),
            this.props.number === 1 && (React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                React.createElement("path", { d: "M17.6758 26.2002H15.6563V18.0877C15.6563 17.1195 15.6792 16.3518 15.725 15.7846C15.5932 15.9221 15.4299 16.0739 15.2352 16.24C15.0461 16.4062 14.4044 16.9333 13.3102 17.8213L12.2961 16.5408L15.9914 13.6361H17.6758V26.2002Z", fill: "white" }))),
            this.props.number === 2 && (React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                React.createElement("path", { d: "M21.0623 26.2002H12.5459V24.6705L15.7857 21.4135C16.7425 20.4338 17.3756 19.7406 17.685 19.3338C18.0001 18.9213 18.2292 18.5346 18.3725 18.1736C18.5157 17.8127 18.5873 17.426 18.5873 17.0135C18.5873 16.4463 18.4154 15.9994 18.0717 15.6729C17.7337 15.3463 17.2639 15.183 16.6623 15.183C16.1811 15.183 15.7141 15.2718 15.2615 15.4494C14.8146 15.627 14.2962 15.9479 13.7061 16.4119L12.6146 15.0799C13.3136 14.4898 13.9925 14.0716 14.6514 13.8252C15.3102 13.5788 16.012 13.4557 16.7568 13.4557C17.9256 13.4557 18.8623 13.7622 19.567 14.3752C20.2717 14.9825 20.624 15.8018 20.624 16.833C20.624 17.4002 20.5209 17.9387 20.3146 18.4486C20.1141 18.9585 19.8019 19.4856 19.3779 20.0299C18.9597 20.5684 18.2607 21.2989 17.2811 22.2213L15.0982 24.3354V24.4213H21.0623V26.2002Z", fill: "white" }))),
            this.props.number === 3 && (React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                React.createElement("path", { d: "M19.7898 16.5236C19.7898 17.32 19.5578 17.9846 19.0937 18.5174C18.6297 19.0445 17.9766 19.3997 17.1344 19.583V19.6518C18.1427 19.7778 18.899 20.0929 19.4031 20.5971C19.9073 21.0955 20.1594 21.7601 20.1594 22.5908C20.1594 23.7997 19.7326 24.7335 18.8789 25.3924C18.0253 26.0455 16.8107 26.3721 15.2352 26.3721C13.843 26.3721 12.6685 26.1458 11.7117 25.6932V23.8971C12.2445 24.1606 12.8089 24.364 13.4047 24.5072C14.0005 24.6505 14.5734 24.7221 15.1234 24.7221C16.0974 24.7221 16.825 24.5416 17.3062 24.1807C17.7875 23.8197 18.0281 23.2611 18.0281 22.5049C18.0281 21.8346 17.7617 21.3419 17.2289 21.0268C16.6961 20.7117 15.8596 20.5541 14.7195 20.5541H13.6281V18.9127H14.7367C16.7419 18.9127 17.7445 18.2195 17.7445 16.833C17.7445 16.2945 17.5698 15.8791 17.2203 15.5869C16.8708 15.2947 16.3552 15.1486 15.6734 15.1486C15.1979 15.1486 14.7396 15.2174 14.2984 15.3549C13.8573 15.4867 13.3359 15.7473 12.7344 16.1369L11.7461 14.7275C12.8977 13.8796 14.2354 13.4557 15.7594 13.4557C17.0255 13.4557 18.0138 13.7278 18.7242 14.2721C19.4346 14.8163 19.7898 15.5669 19.7898 16.5236Z", fill: "white" }))),
            React.createElement("path", { clipRule: "evenodd", d: "M60.3999 110.4C65.9227 110.4 70.3999 105.923 70.3999 100.4C70.3999 94.8775 65.9227 90.4004 60.3999 90.4004C54.8771 90.4004 50.3999 94.8775 50.3999 100.4C50.3999 105.923 54.8771 110.4 60.3999 110.4Z", fillRule: "evenodd", stroke: "url(#paint0_linear)", strokeWidth: "1.12" }),
            React.createElement("path", { clipRule: "evenodd", d: "M58.0006 40.3118C58.7235 40.124 59.4581 40.0224 60.1898 40.0032L60.1898 40.0034C62.3841 39.9455 64.5594 40.6379 66.3651 42.0013C66.4818 42.0795 66.5622 42.2033 66.5877 42.3455C66.6132 42.4866 66.5799 42.6328 66.4975 42.7485C66.4151 42.8643 66.2896 42.9394 66.1523 42.9566C66.014 42.9739 65.8757 42.9303 65.7688 42.8379C63.6021 41.2013 60.8449 40.6511 58.2439 41.3272C53.3977 42.5862 50.468 47.657 51.6842 52.6733C52.9005 57.6895 57.7995 60.7301 62.6457 59.4712C67.4919 58.2123 70.4294 53.1414 69.2132 48.1251C69.1671 47.8571 69.3289 47.5972 69.584 47.5292C69.838 47.4611 70.1008 47.6073 70.1862 47.8652C71.535 53.4265 68.2618 59.0904 62.889 60.4864C57.5162 61.8823 52.0521 58.4862 50.7035 52.9249C49.3546 47.3636 52.6278 41.7077 58.0006 40.3118ZM71.1357 41.1971C71.2122 41.1108 71.3142 41.0529 71.426 41.0346L71.426 41.0346C71.6388 41.0032 71.8477 41.1148 71.9448 41.3128C72.0429 41.5118 72.0076 41.7524 71.8576 41.9118C70.4701 43.4143 68.4754 45.64 66.4771 47.8697C64.4719 50.107 62.4632 52.3483 61.0608 53.8671C60.8754 54.0661 60.5723 54.0803 60.3703 53.8996L56.1018 50.0011C55.989 49.9148 55.9154 49.7839 55.9007 49.6387C55.885 49.4945 55.9291 49.3494 56.0213 49.2397C56.1145 49.1311 56.2469 49.0661 56.3872 49.0631C56.5274 49.059 56.6638 49.1169 56.7609 49.2214L60.6683 52.787C62.036 51.2985 63.8887 49.2327 65.7448 47.163C67.736 44.9428 69.7311 42.7182 71.1357 41.1971Z", fill: "url(#paint1_linear)", fillRule: "evenodd" }),
            React.createElement("path", { clipRule: "evenodd", d: "M58.0006 65.1116C58.7235 64.9238 59.4581 64.8223 60.1898 64.803L60.1898 64.8032C62.3841 64.7453 64.5594 65.4377 66.3651 66.8011C66.4818 66.8793 66.5622 67.0032 66.5877 67.1453C66.6132 67.2864 66.5799 67.4326 66.4975 67.5483C66.4151 67.6641 66.2896 67.7392 66.1523 67.7565C66.014 67.7737 65.8757 67.7301 65.7688 67.6377C63.6021 66.0011 60.8449 65.4509 58.2439 66.127C53.3977 67.386 50.468 72.4568 51.6842 77.4731C52.9005 82.4893 57.7995 85.5299 62.6457 84.271C67.4919 83.0121 70.4294 77.9412 69.2132 72.9249C69.1671 72.6569 69.3289 72.397 69.584 72.329C69.838 72.2609 70.1008 72.4071 70.1862 72.665C71.535 78.2263 68.2618 83.8902 62.889 85.2862C57.5162 86.6821 52.0521 83.286 50.7035 77.7247C49.3546 72.1634 52.6278 66.5075 58.0006 65.1116ZM71.1357 65.9969C71.2122 65.9106 71.3142 65.8527 71.426 65.8344L71.426 65.8344C71.6388 65.803 71.8477 65.9146 71.9448 66.1126C72.0429 66.3116 72.0076 66.5522 71.8576 66.7116C70.4701 68.2141 68.4754 70.4398 66.4771 72.6695C64.4719 74.9068 62.4632 77.1481 61.0608 78.6669C60.8754 78.8659 60.5723 78.8801 60.3703 78.6994L56.1018 74.8009C55.989 74.7146 55.9154 74.5837 55.9007 74.4385C55.885 74.2943 55.9291 74.1492 56.0213 74.0395C56.1145 73.9309 56.2469 73.8659 56.3872 73.8629C56.5274 73.8588 56.6638 73.9167 56.7609 74.0212L60.6683 77.5868C62.036 76.0983 63.8887 74.0325 65.7448 71.9628C67.736 69.7426 69.7311 67.518 71.1357 65.9969Z", fill: "url(#paint2_linear)", fillRule: "evenodd" }),
            React.createElement("rect", { fill: "#00A555", height: "3.2", rx: "1.6", width: "24", x: "80", y: "48.7998" }),
            React.createElement("rect", { fill: "#00A555", height: "3.2", rx: "1.6", width: "17.6", x: "80", y: "98.4004" }),
            React.createElement("rect", { fill: "#00A555", height: "3.2", rx: "1.6", width: "30.4", x: "80", y: "74.4004" }),
            React.createElement("defs", null,
                React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "paint0_linear", x1: "50.3999", x2: "70.4455", y1: "110.419", y2: "110.419" },
                    React.createElement("stop", { stopColor: "#72BF49" }),
                    React.createElement("stop", { offset: "1", stopColor: "#25ABDD" })),
                React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "paint1_linear", x1: "50.3999", x2: "72.0491", y1: "60.8191", y2: "60.8191" },
                    React.createElement("stop", { stopColor: "#72BF49" }),
                    React.createElement("stop", { offset: "1", stopColor: "#25ABDD" })),
                React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "paint2_linear", x1: "50.3999", x2: "72.0491", y1: "85.6189", y2: "85.6189" },
                    React.createElement("stop", { stopColor: "#72BF49" }),
                    React.createElement("stop", { offset: "1", stopColor: "#25ABDD" })),
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { fill: "white", height: "32", width: "32", y: "4.7998" })))));
    }
}
