import React from "react";
import Button from "@Components/Button";
import Card from "@Components/Card";
import FormInput from "@Components/Form/Input";
import Tick from "@Components/Icons/Tick";
import Table from "@Components/Table";
import { AllowanceTypeGroups, UnitType, } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { AllowanceTypes } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import ConvertUnitsForDisplay from "@Shared/Helpers/CalculateUsageCovered/ConvertUnitsForDisplay";
import { format } from "@Shared/Helpers/Money";
import { roundToFormat } from "@Shared/Helpers/Numbers";
import { displayAllowanceTooltip } from "@Helpers/DisplayAllowanceTooltip";
import { DataSize } from "@Shared/Entities/Customer/Usage/enums";
import GetUnits from "@Shared/Helpers/CalculateUsageCovered/GetUnits";
import { dataConvert } from "@Shared/Helpers/Data";
import styles from "./index.module.scss";
export default class DisplayTable extends React.Component {
    static defaultProps = {
        rackRate: {},
        errors: {},
    };
    componentDidMount() {
        const network = this.props.response.meta?.primaryPlatform;
        const libraryRackRates = (this.props.supplier.OOBLibrary || {})[network] || {};
        const rackRate = { ...this.props.rackRate };
        Object.keys(libraryRackRates).map((name) => {
            if (rackRate[name] === undefined) {
                rackRate[name] = libraryRackRates[name];
            }
        });
        this.props.autofill(rackRate);
    }
    displayTable = (fields, data, filterRows, hideForSmallBusiness = false) => {
        const filteredRows = data.filter((element) => filterRows(element));
        if (filteredRows.length === 0 || hideForSmallBusiness) {
            return "No usage in this category";
        }
        return (React.createElement(Table, { fields: fields, data: filteredRows, getKey: ({ name }) => name, noNav: true, noMargin: true, setErrors: this.props.setErrors }));
    };
    render() {
        const covered = CalculateUsageCovered(this.props.tender, this.props.response.proposal);
        const fields = [
            {
                field: "bundles",
                hidden: true,
                alias: ({ name }) => {
                    const bundles = this.props.response.proposal.bundles || [];
                    return bundles.filter(({ applyToRows }) => applyToRows.includes(name));
                },
            },
            {
                field: "style",
                hidden: true,
                alias: ({ name }) => {
                    const bundles = (this.props.response.proposal.bundles || []).filter(({ applyToRows }) => applyToRows.includes(name));
                    if (bundles.length === 1) {
                        return { background: bundles[0].color };
                    }
                    if (bundles.length > 0) {
                        let background = `repeating-linear-gradient(135deg, `;
                        const stripeThickness = 20;
                        for (const [index, { color }] of bundles.entries()) {
                            background += color;
                            if (index > 0) {
                                background += ` ${index * stripeThickness}px`;
                            }
                            background += `, ${color} ${(index + 1) * stripeThickness}px`;
                            if (index !== bundles.length - 1) {
                                background += ", ";
                            }
                        }
                        background += ")";
                        return { background };
                    }
                },
            },
            {
                header: "",
                field: "checkbox",
                leftAlign: true,
                display: (data) => {
                    const { bundles = [] } = data;
                    return bundles.length > 0 ? (React.createElement("div", { className: styles.filler },
                        React.createElement(Tick, { size: "1vw", color: "#58585a", noFill: true }))) : (React.createElement("div", { className: styles.filler }));
                },
                className: styles.checkbox,
            },
            {
                header: "",
                field: "name",
                widthControl: true,
                leftAlign: true,
                className: styles.name,
                display: (data) => {
                    const name = data.name.replaceAll("(mins)", "");
                    return name;
                },
            },
            {
                field: "tooltip",
                widthControl: true,
                display: ({ name }) => {
                    return displayAllowanceTooltip(name);
                },
                className: styles.tooltip,
            },
            {
                hidden: true,
                field: "enum",
            },
            {
                header: "Cost per item",
                field: "costPerItem",
                widthControl: true,
                type: "input",
                display: (data) => {
                    const costPerItem = this.props.rackRate[data.name];
                    const error = this.props.errors[data.name];
                    const change = (name, value) => {
                        this.props.update({ name, value });
                    };
                    return (React.createElement("div", { className: styles.inputStyle },
                        React.createElement(FormInput, { value: costPerItem, type: "currency", valueChanged: change, name: data.name, error: error, setErrors: this.props.setErrors })));
                },
                className: styles.costCol,
            },
            {
                header: "Average Usage",
                widthControl: true,
                field: "usage",
                display: ({ name }) => {
                    const { used = 0 } = covered[name];
                    const type = GetUnits(name);
                    if (type === UnitType.Data) {
                        return dataConvert(used, DataSize.MB);
                    }
                    return roundToFormat(ConvertUnitsForDisplay(used, name), 0);
                },
            },
            {
                header: "Included In Tariff",
                widthControl: true,
                field: "inclTariff",
                display: ({ name }) => {
                    const { coveredByTender = 0 } = covered[name];
                    const type = GetUnits(name);
                    if (type === UnitType.Data) {
                        return dataConvert(coveredByTender, DataSize.MB);
                    }
                    return roundToFormat(ConvertUnitsForDisplay(coveredByTender, name), 0);
                },
            },
            {
                header: "Included In Bundles",
                widthControl: true,
                field: "inclBundle",
                display: ({ name }) => {
                    const { coveredByBundles = 0 } = covered[name];
                    const type = GetUnits(name);
                    if (type === UnitType.Data) {
                        return dataConvert(coveredByBundles, DataSize.MB);
                    }
                    return roundToFormat(ConvertUnitsForDisplay(coveredByBundles, name), 0);
                },
            },
            {
                header: "Remaining",
                widthControl: true,
                field: "remaining",
                display: ({ name }) => {
                    const { remaining = 0 } = covered[name];
                    const type = GetUnits(name);
                    if (type === UnitType.Data) {
                        return dataConvert(remaining, DataSize.MB);
                    }
                    return roundToFormat(ConvertUnitsForDisplay(remaining, name), 0);
                },
            },
            {
                header: "Bundle Cost",
                widthControl: true,
                field: "bundleCost",
                display: ({ name }) => {
                    const { costOfBundles = 0 } = covered[name];
                    return format(costOfBundles);
                },
            },
            {
                header: "Cost",
                widthControl: true,
                field: "cost",
                display: ({ name }) => {
                    const { remaining = 0 } = covered[name];
                    const costPerItem = this.props.rackRate[name] || 0;
                    return format(ConvertUnitsForDisplay(remaining, name) * costPerItem);
                },
            },
        ];
        const ukUsage = [
            {
                name: AllowanceTypes["UK Data (GB)"],
            },
            {
                name: AllowanceTypes["Std Calls (mins)"],
            },
            {
                name: AllowanceTypes["Std SMS"],
            },
            {
                name: AllowanceTypes["Biz rate Calls (mins)"],
            },
            {
                name: AllowanceTypes["Prem Calls"],
            },
            {
                name: AllowanceTypes["Prem SMS"],
            },
            {
                name: AllowanceTypes["Std MMS"],
            },
            {
                name: AllowanceTypes["Dir Enq"],
            },
            {
                name: AllowanceTypes["Free Calls (mins)"],
            },
        ];
        const iddUsage = [
            {
                name: AllowanceTypes["UK IDD to EU (mins)"],
            },
            {
                name: AllowanceTypes["UK IDD to USA & Canada (mins)"],
            },
            {
                name: AllowanceTypes["UK IDD to APAC (mins)"],
            },
            {
                name: AllowanceTypes["UK IDD to ROW (mins)"],
            },
            {
                name: AllowanceTypes["Int SMS"],
            },
            {
                name: AllowanceTypes["Int MMS"],
            },
        ];
        const euUsage = [
            {
                name: AllowanceTypes["EU Data (GB)"],
            },
            {
                name: AllowanceTypes["EU Calls (mins)"],
            },
            {
                name: AllowanceTypes["EU SMS"],
            },
            {
                name: AllowanceTypes["EU MMS"],
            },
        ];
        const nonEUUsage = [
            {
                name: AllowanceTypes["Non-EU Data (GB)"],
            },
            {
                name: AllowanceTypes["Non-EU Calls (mins)"],
            },
            {
                name: AllowanceTypes["Non-EU SMS"],
            },
            {
                name: AllowanceTypes["Non-EU MMS"],
            },
        ];
        const filterRows = ({ name }) => {
            if (!this.props.tender.usageId) {
                return true;
            }
            const { used } = covered[name];
            if (used > 0) {
                return true;
            }
            const bundles = this.props.response.proposal.bundles || [];
            const bundlesApplied = bundles.filter(({ applyToRows }) => applyToRows.includes(name));
            if (bundlesApplied.length > 0) {
                return true;
            }
        };
        const bundlesButton = (group) => (React.createElement("div", { className: styles.addButton },
            React.createElement(Button, { click: () => this.props.openBundles(group) }, "ADD / EDIT STANDARD BUNDLES")));
        const roamLikeHomeButton = (group) => (React.createElement("div", { className: styles.addButton },
            React.createElement(Button, { click: () => this.props.openRoamLikeHome(group), cta: true }, "ADD / EDIT ROAM LIKE HOME")));
        const { tender } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, null,
                React.createElement("div", { className: styles.header },
                    "UK Usage",
                    bundlesButton(AllowanceTypeGroups.UK)),
                React.createElement("div", { className: styles.tableContainer }, this.displayTable(fields, ukUsage, filterRows))),
            React.createElement(Card, null,
                React.createElement("div", { className: styles.header },
                    "IDD Usage",
                    bundlesButton(AllowanceTypeGroups.IDD)),
                React.createElement("div", { className: styles.tableContainer }, this.displayTable(fields, iddUsage, filterRows, tender?.isSmallBusiness && !tender?.details?.iddCost))),
            React.createElement(Card, null,
                React.createElement("div", { className: styles.header },
                    "EU Roaming Usage",
                    React.createElement("div", { className: styles.buttonGrouped },
                        roamLikeHomeButton(AllowanceTypeGroups.EU_Roaming),
                        bundlesButton(AllowanceTypeGroups.EU_Roaming))),
                React.createElement("div", { className: styles.tableContainer }, this.displayTable(fields, euUsage, filterRows, tender?.isSmallBusiness && !tender?.details?.iddCost))),
            React.createElement(Card, null,
                React.createElement("div", { className: styles.header },
                    "Non-EU Roaming Usage",
                    React.createElement("div", { className: styles.buttonGrouped },
                        roamLikeHomeButton(AllowanceTypeGroups.Non_EU_Roaming),
                        bundlesButton(AllowanceTypeGroups.Non_EU_Roaming))),
                React.createElement("div", { className: styles.tableContainer }, this.displayTable(fields, nonEUUsage, filterRows, tender?.isSmallBusiness && !tender?.details?.iddCost)))));
    }
}
