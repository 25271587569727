// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highlightedText--inpbA{color:var(--alt-highlighted-text-color)}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Network/index.module.scss"],"names":[],"mappings":"AAAA,wBAAiB,uCAAuC","sourcesContent":[".highlightedText{color:var(--alt-highlighted-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlightedText": "highlightedText--inpbA"
};
export default ___CSS_LOADER_EXPORT___;
