import React from "react";
import Card from "@Components/Card";
import Input from "@Components/Form/Input";
import Table from "@Components/Table";
import { Effected } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export default class DevicePrices extends React.Component {
    static defaultProps = {
        data: [],
        pickedHandsets: [],
        errors: {},
    };
    update = (id, value) => {
        const { data } = this.props;
        let index = data.findIndex(({ deviceId }) => deviceId === id);
        if (index === -1) {
            index = data.length;
        }
        const currentObject = data[index];
        currentObject.price = value;
        this.props.update({
            name: `${Effected.DevicePrices}.${index}`,
            value: currentObject,
        });
    };
    render() {
        const fields = [
            {
                field: "deviceId",
                hidden: true,
            },
            {
                header: "Device Name",
                field: "deviceName",
                alias: ({ latestId }) => {
                    const device = this.props.devices.find(({ id }) => id === latestId);
                    return device ? device.name : "";
                },
                leftAlign: true,
            },
            {
                header: "Price",
                field: "price",
                display: (data) => {
                    return (React.createElement(Input, { type: "currency", value: data.price, name: data.deviceId, valueChanged: data.function, error: data.error[data.deviceId] }));
                },
            },
            {
                field: "function",
                hidden: true,
                alias: () => this.update,
            },
            {
                field: "error",
                hidden: true,
                alias: () => this.props.errors,
            },
        ];
        const { data } = this.props;
        return (React.createElement(Card, { title: "Device Prices", className: this.props.className },
            React.createElement(Table, { fields: fields, data: data, noNav: true })));
    }
}
