import React from "react";
import Form, { InputType } from "@Components/Form";
import { Network as NetworkEnum } from "@Shared/Entities/Customer/Usage/enums";
import { BillingRelationship, Effected, Measurement, NetworkRelationship, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import Card from "@Components/Card";
import styles from "./index.module.scss";
export default class Network extends React.Component {
    render() {
        return (React.createElement(Card, { title: this.props.isUpdate ? "" : "Network Relationships" },
            React.createElement(Form, { errors: this.props.errors, inputChange: this.props.inputChange, data: this.props.data, submit: this.props.save, labelLeft: true, setErrors: this.props.setErrors, noSave: !this.props.isUpdate, buttonText: this.props.isUpdate ? "UPDATE" : undefined, input: [
                    {
                        label: "Can you connect to more than one network?",
                        name: Effected.MultipleNetworks,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "Please select the other network(s) you can connect to?",
                        name: Effected.SelectNetworks,
                        type: InputType.DropdownOptions,
                        options: Object.keys(NetworkEnum)
                            .filter((key) => !Number.isNaN(Number(NetworkEnum[key])))
                            .filter((key) => NetworkEnum[key] !==
                            NetworkEnum.None)
                            .map((key) => ({
                            label: key,
                            value: NetworkEnum[key],
                        })),
                        multi: true,
                        hidden: !this.props.data[Effected.MultipleNetworks],
                    },
                    // {
                    //     label: "Any other secondary networks?",
                    //     name: Effected.SecondaryNetworks,
                    //     type: InputType.MultiAddText,
                    //     placeholder:
                    //         "Please press the comma key or space between networks...",
                    //     separators: [",", " "],
                    //     hidden: !this.props.data[
                    //         Effected.MultipleNetworks
                    //     ],
                    // },
                    {
                        label: "Please specify name of other network?",
                        name: Effected.OtherNetwork,
                        type: InputType.Text,
                        hidden: !(this.props.data[Effected.SelectNetworks] || []).includes(NetworkEnum.Other),
                    },
                    {
                        label: "Billing relationship ability",
                        name: Effected.BillingRelationship,
                        type: InputType.DropdownEnum,
                        options: BillingRelationship,
                    },
                    {
                        label: "Which method of billing are you proposing for this Tender?",
                        name: Effected.BillingRelationshipChoice,
                        type: InputType.DropdownOptions,
                        options: [
                            {
                                value: BillingRelationship.Network_Billed,
                                label: "Network Billed",
                            },
                            {
                                value: BillingRelationship.Own_Billing_Engine,
                                label: "Own Billing Engine",
                            },
                        ],
                        hidden: this.props.data[Effected.BillingRelationship] !== BillingRelationship.Both,
                    },
                    {
                        label: "Your network relationship",
                        name: Effected.NetworkRelationship,
                        type: InputType.DropdownEnum,
                        options: NetworkRelationship,
                    },
                    {
                        label: "Can the customer use their existing sims for this tender?",
                        name: Effected.KeepSims,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: (React.createElement(React.Fragment, null,
                            "Is there an annual",
                            " ",
                            React.createElement("span", { className: styles.highlightedText }, "network"),
                            " ",
                            "price increase?")),
                        name: Effected.Increases,
                        type: InputType.SmallToggle,
                    },
                    {
                        label: "What measurement is used?",
                        name: Effected.IncreaseMeasurement,
                        type: InputType.DropdownEnum,
                        options: Measurement,
                        hidden: !this.props.data[Effected.Increases],
                    },
                    {
                        label: "Is there an additional percentage cost increase?",
                        name: Effected.AdditionalIncrease,
                        type: InputType.SmallToggle,
                        hidden: !this.props.data[Effected.Increases],
                    },
                    {
                        label: "What percentage (%)",
                        name: Effected.AdditionalIncreasePercentage,
                        type: InputType.Percentage,
                        hidden: !this.props.data[Effected.Increases] ||
                            !this.props.data[Effected.AdditionalIncrease],
                    },
                    {
                        label: "Is there a contract break clause if price exceeds the contracted T&Cs for annual price?",
                        name: Effected.BreakClause,
                        type: InputType.SmallToggle,
                        hidden: !this.props.data[Effected.Increases],
                    },
                    {
                        label: (React.createElement(React.Fragment, null,
                            "Is there a mandatory annual",
                            " ",
                            React.createElement("span", { className: styles.highlightedText }, "supplier"),
                            " ",
                            "price rise?")),
                        name: Effected.MandatoryRise,
                        type: InputType.SmallToggle,
                        hidden: !this.props.data[Effected.Increases],
                    },
                    {
                        label: "What percentage (%)",
                        name: Effected.MandatoryRisePercentage,
                        type: InputType.Percentage,
                        hidden: !this.props.data[Effected.Increases] ||
                            !this.props.data[Effected.MandatoryRise],
                    },
                    {
                        label: "Additional information",
                        name: Effected.AdditionalNetworkInfo,
                        type: InputType.LargeText,
                        characters: 1000,
                    },
                ] })));
    }
}
