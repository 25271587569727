import React from "react";
import Table from "@Components/Table";
import { ComponentTypes, Effected, QTYType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import GetTotalTariffCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalTariffCost";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { format } from "@Shared/Helpers/Money";
import { getIconFromPlatform } from "@Helpers/Network";
import styles from "./index.module.scss";
export default class Builder extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const totalCost = GetTotalTariffCost(this.props.data);
        return (React.createElement(React.Fragment, null,
            React.createElement(Table, { data: this.props.data, fields: [
                    {
                        header: "Network",
                        field: Effected.TariffComponentNetwork,
                        display: (data) => {
                            const Icon = getIconFromPlatform(data.networkComponent);
                            if (Icon !== undefined) {
                                return React.createElement(Icon, null);
                            }
                        },
                    },
                    {
                        header: "Item",
                        field: Effected.TariffComponentType,
                        leftAlign: true,
                        display: (data) => {
                            return displayEnum(ComponentTypes, data[Effected.TariffComponentType]);
                        },
                    },
                    {
                        field: Effected.TariffComponentItems,
                        header: "Allowances",
                        display: (data) => {
                            const allowances = data[Effected.TariffComponentItems] || [];
                            if (allowances.length === 0) {
                                return "---";
                            }
                            return (React.createElement("div", { className: styles.allowanceList }, allowances.map((allowance) => (React.createElement("div", { key: Object.values(allowance).join("-"), className: styles.allowance },
                                React.createElement("div", null, allowance[Effected
                                    .TariffComponentAllowanceType]),
                                React.createElement("div", null, allowance[Effected
                                    .TariffComponentAllowanceQtyType] === QTYType.Unlimited
                                    ? "Unlimited"
                                    : `${allowance[Effected
                                        .TariffComponentAllowanceQty]} ${displayEnum(QTYType, allowance[Effected
                                        .TariffComponentAllowanceQtyType])}`))))));
                        },
                    },
                    {
                        field: Effected.TariffComponentNumberLines,
                        header: "Amount",
                        display: (data) => (React.createElement("div", { className: styles.number }, data[Effected.TariffComponentNumberLines])),
                    },
                    {
                        field: Effected.TariffComponentStandardCost,
                        header: "Standard Cost",
                        display: (data) => {
                            if (data[Effected.TariffComponentType] ===
                                ComponentTypes.Network_ATC) {
                                return "";
                            }
                            return (React.createElement("div", { className: styles.number }, format(data[Effected
                                .TariffComponentStandardCost])));
                        },
                        rightAlign: true,
                    },
                    {
                        field: Effected.TariffComponentDiscount,
                        header: "Discount",
                        display: (data) => {
                            if (data[Effected.TariffComponentType] ===
                                ComponentTypes.Network_ATC) {
                                return "";
                            }
                            return (React.createElement("div", { className: styles.number }, format(data[Effected.TariffComponentDiscount])));
                        },
                        rightAlign: true,
                    },
                    {
                        field: Effected.TariffComponentNetCost,
                        header: "Net Cost",
                        display: (data) => {
                            if (data[Effected.TariffComponentType] ===
                                ComponentTypes.Network_ATC) {
                                return "";
                            }
                            return (React.createElement("div", { className: styles.number }, format(data[Effected.TariffComponentNetCost])));
                        },
                        rightAlign: true,
                    },
                    {
                        field: Effected.TariffComponentTotalCost,
                        header: "Total Cost",
                        display: (data) => {
                            let value = data[Effected.TariffComponentTotalCost] || 0;
                            if (data[Effected.TariffComponentType] ===
                                ComponentTypes.Network_ATC) {
                                value = -value;
                            }
                            return (React.createElement("div", { className: styles.number }, format(value)));
                        },
                        rightAlign: true,
                    },
                ], noNav: true, className: styles.table }),
            React.createElement("div", { className: styles.total },
                React.createElement("div", null),
                React.createElement("div", { className: styles.value }, format(totalCost)))));
    }
}
