import React from "react";
export default class CustomerSelectIcon extends React.Component {
    render() {
        return (React.createElement("svg", { fill: "none", height: "149", viewBox: "0 0 149 149", width: "149", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { clipRule: "evenodd", d: "M74.4 148.8C115.49 148.8 148.8 115.49 148.8 74.4C148.8 33.31 115.49 0 74.4 0C33.31 0 0 33.31 0 74.4C0 115.49 33.31 148.8 74.4 148.8Z", fill: "#F0F6F6", fillRule: "evenodd", stroke: "white", strokeWidth: "1em", strokeLinejoin: "round" }),
            this.props.number === 1 && (React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                React.createElement("path", { d: "M17.6758 26.2002H15.6563V18.0877C15.6563 17.1195 15.6792 16.3518 15.725 15.7846C15.5932 15.9221 15.4299 16.0739 15.2352 16.24C15.0461 16.4062 14.4044 16.9333 13.3102 17.8213L12.2961 16.5408L15.9914 13.6361H17.6758V26.2002Z", fill: "white" }))),
            this.props.number === 2 && (React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                React.createElement("path", { d: "M21.0623 26.2002H12.5459V24.6705L15.7857 21.4135C16.7425 20.4338 17.3756 19.7406 17.685 19.3338C18.0001 18.9213 18.2292 18.5346 18.3725 18.1736C18.5157 17.8127 18.5873 17.426 18.5873 17.0135C18.5873 16.4463 18.4154 15.9994 18.0717 15.6729C17.7337 15.3463 17.2639 15.183 16.6623 15.183C16.1811 15.183 15.7141 15.2718 15.2615 15.4494C14.8146 15.627 14.2962 15.9479 13.7061 16.4119L12.6146 15.0799C13.3136 14.4898 13.9925 14.0716 14.6514 13.8252C15.3102 13.5788 16.012 13.4557 16.7568 13.4557C17.9256 13.4557 18.8623 13.7622 19.567 14.3752C20.2717 14.9825 20.624 15.8018 20.624 16.833C20.624 17.4002 20.5209 17.9387 20.3146 18.4486C20.1141 18.9585 19.8019 19.4856 19.3779 20.0299C18.9597 20.5684 18.2607 21.2989 17.2811 22.2213L15.0982 24.3354V24.4213H21.0623V26.2002Z", fill: "white" }))),
            this.props.number === 3 && (React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { clipRule: "evenodd", d: "M 17 36.7998 C 25.8366 36.7998 33 29.6364 33 20.7998 C 33 11.9632 25.8366 4.7998 17 4.7998 C 8.1634 4.7998 1 11.9632 1 20.7998 C 1 29.6364 8.1634 36.7998 17 36.7998 Z", fill: "#73C04A", fillRule: "evenodd" }),
                React.createElement("path", { d: "M19.7898 16.5236C19.7898 17.32 19.5578 17.9846 19.0937 18.5174C18.6297 19.0445 17.9766 19.3997 17.1344 19.583V19.6518C18.1427 19.7778 18.899 20.0929 19.4031 20.5971C19.9073 21.0955 20.1594 21.7601 20.1594 22.5908C20.1594 23.7997 19.7326 24.7335 18.8789 25.3924C18.0253 26.0455 16.8107 26.3721 15.2352 26.3721C13.843 26.3721 12.6685 26.1458 11.7117 25.6932V23.8971C12.2445 24.1606 12.8089 24.364 13.4047 24.5072C14.0005 24.6505 14.5734 24.7221 15.1234 24.7221C16.0974 24.7221 16.825 24.5416 17.3062 24.1807C17.7875 23.8197 18.0281 23.2611 18.0281 22.5049C18.0281 21.8346 17.7617 21.3419 17.2289 21.0268C16.6961 20.7117 15.8596 20.5541 14.7195 20.5541H13.6281V18.9127H14.7367C16.7419 18.9127 17.7445 18.2195 17.7445 16.833C17.7445 16.2945 17.5698 15.8791 17.2203 15.5869C16.8708 15.2947 16.3552 15.1486 15.6734 15.1486C15.1979 15.1486 14.7396 15.2174 14.2984 15.3549C13.8573 15.4867 13.3359 15.7473 12.7344 16.1369L11.7461 14.7275C12.8977 13.8796 14.2354 13.4557 15.7594 13.4557C17.0255 13.4557 18.0138 13.7278 18.7242 14.2721C19.4346 14.8163 19.7898 15.5669 19.7898 16.5236Z", fill: "white" }))),
            React.createElement("g", { clipPath: "url(#clip1)" },
                React.createElement("path", { clipRule: "evenodd", d: "M75.1998 35.2002C60.6463 35.2002 48.7998 47.4248 48.7998 62.4421C48.7998 77.4594 60.6466 89.6839 75.1998 89.6839C89.753 89.6839 101.6 77.4594 101.6 62.4421C101.6 47.4248 89.753 35.2002 75.1998 35.2002ZM75.1998 87.0262C62.0655 87.0262 51.3754 75.9955 51.3754 62.4421C51.3754 48.8886 62.0652 37.8579 75.1998 37.8579C88.3344 37.8579 99.0242 48.8886 99.0242 62.4421C99.0242 75.9955 88.3344 87.0262 75.1998 87.0262ZM93.4853 85.6974C92.7106 86.3618 91.8102 87.0262 90.9097 87.5557L90.9096 113.604L75.9788 101.909C75.9647 101.902 75.9506 101.895 75.9366 101.888C75.6896 101.765 75.4468 101.644 75.2041 101.644C74.9425 101.644 74.686 101.774 74.4294 101.909L59.4883 113.604V87.5557C58.5879 87.0262 57.6874 86.3618 56.9127 85.6974V116.261C56.9127 116.791 57.3 117.191 57.6874 117.455C57.8132 117.59 57.9439 117.59 58.2005 117.59C58.4571 117.59 58.8444 117.455 58.9702 117.326L75.1991 104.701L91.4228 117.32C91.8102 117.59 92.3233 117.72 92.7107 117.455C93.2288 117.191 93.4853 116.791 93.4853 116.261V85.6974Z", fill: "url(#paint0_linear)", fillRule: "evenodd" }),
                React.createElement("path", { d: "M69.5936 66.4004H67.6631L66.5803 62.2004C66.5402 62.0509 66.4709 61.7428 66.3725 61.2762C66.2777 60.8059 66.223 60.4905 66.2084 60.3301C66.1865 60.527 66.1318 60.8441 66.0443 61.2816C65.9568 61.7155 65.8894 62.0254 65.842 62.2113L64.7646 66.4004H62.8396L60.7998 58.4051H62.4678L63.4904 62.7691C63.6691 63.5749 63.7985 64.273 63.8787 64.8637C63.9006 64.6559 63.9498 64.335 64.0264 63.9012C64.1066 63.4637 64.1813 63.1246 64.2506 62.884L65.4154 58.4051H67.0178L68.1826 62.884C68.2337 63.0845 68.2975 63.3908 68.374 63.8027C68.4506 64.2147 68.5089 64.5684 68.549 64.8637C68.5855 64.5793 68.6438 64.2257 68.724 63.8027C68.8042 63.3762 68.8771 63.0316 68.9428 62.7691L69.96 58.4051H71.6279L69.5936 66.4004ZM79.8912 62.3918C79.8912 63.7152 79.5631 64.7324 78.9068 65.4434C78.2506 66.1543 77.31 66.5098 76.085 66.5098C74.86 66.5098 73.9193 66.1543 73.2631 65.4434C72.6068 64.7324 72.2787 63.7116 72.2787 62.3809C72.2787 61.0501 72.6068 60.0348 73.2631 59.3348C73.923 58.6311 74.8673 58.2793 76.0959 58.2793C77.3245 58.2793 78.2633 58.6329 78.9123 59.3402C79.5649 60.0475 79.8912 61.0647 79.8912 62.3918ZM74.0561 62.3918C74.0561 63.285 74.2256 63.9577 74.5646 64.4098C74.9037 64.8618 75.4105 65.0879 76.085 65.0879C77.4376 65.0879 78.1139 64.1892 78.1139 62.3918C78.1139 60.5908 77.4412 59.6902 76.0959 59.6902C75.4214 59.6902 74.9128 59.9181 74.5701 60.3738C74.2274 60.8259 74.0561 61.4986 74.0561 62.3918ZM88.6412 66.4004H86.4865L83.0084 60.352H82.9592C83.0284 61.4202 83.0631 62.1822 83.0631 62.6379V66.4004H81.5482V58.4051H83.6865L87.1592 64.3934H87.1975C87.1428 63.3543 87.1154 62.6197 87.1154 62.1895V58.4051H88.6412V66.4004Z", fill: "#00A555" })),
            React.createElement("defs", null,
                React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "paint0_linear", x1: "48.7998", x2: "101.72", y1: "117.676", y2: "117.676" },
                    React.createElement("stop", { stopColor: "#72BF49" }),
                    React.createElement("stop", { offset: "1", stopColor: "#25ABDD" })),
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { fill: "white", height: "32", width: "32", y: "4.7998" })),
                React.createElement("clipPath", { id: "clip1" },
                    React.createElement("rect", { fill: "white", height: "83", width: "53", x: "48.7998", y: "35.2002" })))));
    }
}
