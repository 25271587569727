import React from "react";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import { DeliveryChargeMethod, LeasingType, LikeHardwareFunded, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Effected, Months, TimeFrame, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import DevicePrices from "@SmartComponents/Proposal/HardwareFund/DevicePrices";
import LeasingPrices from "@SmartComponents/Proposal/HardwareFund/LeasingPrices";
import styles from "./index.module.scss";
export default class HardwareFund extends React.Component {
    render() {
        const hardwareForm = (React.createElement(Card, { title: "Hardware Fund", className: styles.card },
            React.createElement(Form, { submit: this.props.save, data: this.props.data, errors: this.props.errors, buttons: false, inputChange: this.props.inputChange, labelLeft: true, setErrors: this.props.setErrors, input: [
                    {
                        label: "Total hardware fund provided:",
                        name: Effected.TotalFund,
                        type: InputType.Currency,
                        noDecimal: true,
                    },
                    {
                        label: "Can the H/W fund be taken as cash? ",
                        name: Effected.CashHW,
                        type: InputType.SmallToggle,
                        hidden: this.props.data[Effected.TotalFund] === 0,
                        noDecimal: true,
                    },
                    {
                        label: "How much of the HW fund can be taken as cash? ",
                        name: Effected.HWFundTaken,
                        type: InputType.Currency,
                        hidden: this.props.data[Effected.CashHW] !== true ||
                            this.props.data[Effected.TotalFund] === 0,
                        noDecimal: true,
                    },
                    {
                        label: "When will the H/W fund be available for draw down? ",
                        name: Effected.DrawDown,
                        type: InputType.DropdownEnum,
                        options: TimeFrame,
                        placeholder: "Pick a timeframe...",
                        hidden: this.props.data[Effected.TotalFund] === 0,
                    },
                    {
                        label: "Do you deduct the cost of sims from H/W fund? ",
                        name: Effected.DeductSims,
                        type: InputType.SmallToggle,
                        hidden: this.props.data[Effected.TotalFund] === 0,
                    },
                    {
                        label: "If yes, how much is deducted per sim?",
                        name: Effected.DeductedPerSim,
                        type: InputType.Currency,
                        hidden: this.props.data[Effected.DeductSims] !== true ||
                            this.props.data[Effected.TotalFund] === 0,
                    },
                    {
                        label: "Do you charge for SIM cards",
                        name: Effected.SimCost,
                        type: InputType.SmallToggle,
                        hidden: this.props.data[Effected.TotalFund] !== 0,
                    },
                    {
                        label: "How much do you charge per sim?",
                        name: Effected.ChargePerSim,
                        type: InputType.Currency,
                        hidden: this.props.data[Effected.SimCost] !== true ||
                            this.props.data[Effected.TotalFund] !== 0,
                    },
                ] })));
        return (React.createElement("div", null,
            this.props.tender.details.likeHardwareFunded ===
                LikeHardwareFunded.Included_in_tariff && hardwareForm,
            this.props.tender.details.likeHardwareFunded !==
                LikeHardwareFunded.Leasing && (React.createElement(DevicePrices, { devices: this.props.devices, priceCheck: this.props.tender.priceCheckDevices, data: this.props.data[Effected.DevicePrices], errors: this.props.errors[Effected.DevicePrices], update: this.props.inputChange, save: this.props.save, className: styles.card })),
            this.props.tender.details.likeHardwareFunded ===
                LikeHardwareFunded.Leasing && (React.createElement(Card, { title: "Leased Devices", className: styles.card },
                React.createElement(Form, { submit: this.props.save, data: this.props.data, errors: this.props.errors, labelLeft: true, noSave: true, inputChange: this.props.inputChange, setErrors: this.props.setErrors, input: [
                        {
                            label: (React.createElement(React.Fragment, null,
                                "The client would like",
                                " ",
                                React.createElement("span", { className: styles.highlighted },
                                    displayEnum(LeasingType, this.props.tender.details
                                        .leasingType),
                                    ", Leasing"),
                                ". Do you offer this type of leasing?")),
                            name: Effected.OfferLeasing,
                            type: InputType.SmallToggle,
                        },
                        {
                            label: "What is the setup fee?",
                            name: Effected.SetupFee,
                            type: InputType.Currency,
                            hidden: this.props.data[Effected.OfferLeasing] !== true,
                        },
                        {
                            label: "Lease term?",
                            name: Effected.MinimumLeaseTerm,
                            type: InputType.DropdownEnum,
                            hidden: this.props.data[Effected.OfferLeasing] !== true,
                            options: Months,
                        },
                    ] }),
                this.props.data[Effected.OfferLeasing] === true && (React.createElement(LeasingPrices, { devices: this.props.devices, priceCheck: this.props.tender.priceCheckDevices, leasingData: this.props.data[Effected.LeasingData], leasingErrors: this.props.errors[Effected.LeasingData], update: this.props.inputChange, save: this.props.save, leaseTerm: this.props.data[Effected.MinimumLeaseTerm], className: styles.card })),
                React.createElement(Form, { submit: this.props.save, data: this.props.data, errors: this.props.errors, labelLeft: true, buttons: false, inputChange: this.props.inputChange, setErrors: this.props.setErrors, input: [
                        {
                            label: "Does your leasing solution require the customer to insure devices?",
                            name: Effected.LeasingRequiresInsurance,
                            type: InputType.SmallToggle,
                            hidden: this.props.data[Effected.OfferLeasing] !== true,
                        },
                        {
                            label: "Is this price dependant on customer paying by direct debit?",
                            name: Effected.DirectDebit,
                            type: InputType.SmallToggle,
                            hidden: this.props.data[Effected.OfferLeasing] !== true,
                        },
                        {
                            label: "What is the percentage cost if not using direct debit? (%)",
                            name: Effected.DirectDebitCost,
                            type: InputType.Percentage,
                            hidden: this.props.data[Effected.OfferLeasing] !== true ||
                                !this.props.data[Effected.DirectDebit],
                        },
                        {
                            label: "What is the end of leasing lump sum payment, if applicable?",
                            name: Effected.LeasingLumpSum,
                            type: InputType.Currency,
                            hidden: this.props.data[Effected.OfferLeasing] !== true,
                        },
                        {
                            label: "Additional notes about leasing",
                            name: Effected.LeasingNotes,
                            type: InputType.LargeText,
                            hidden: this.props.data[Effected.OfferLeasing] !== true,
                        },
                    ] }))),
            (this.props.tender.details.likeHardwareFunded ===
                LikeHardwareFunded.Leasing ||
                this.props.tender.details.likeHardwareFunded ===
                    LikeHardwareFunded.Included_in_tariff) && (React.createElement(Card, { title: "Information About Devices", className: styles.card },
                React.createElement(Form, { submit: this.props.save, data: this.props.data, errors: this.props.errors, labelLeft: true, buttons: false, inputChange: this.props.inputChange, setErrors: this.props.setErrors, input: [
                        {
                            label: "Are handsets supplied with a profit margin added to customer price?",
                            name: Effected.HandsetProfitMargin,
                            type: InputType.SmallToggle,
                        },
                        {
                            label: "Are Apple devices you supply DEP compliant?",
                            name: Effected.AppleDEP,
                            type: InputType.SmallToggle,
                        },
                        {
                            label: "Do you offer device insurance?",
                            name: Effected.DeviceInsurance,
                            type: InputType.SmallToggle,
                        },
                        {
                            label: "Do you charge for delivery?",
                            name: Effected.ChargeForDelivery,
                            type: InputType.SmallToggle,
                        },
                        {
                            label: "Is the charge per device or per shipment?",
                            name: Effected.DeliveryChargeMethod,
                            type: InputType.LargeToggle,
                            long: true,
                            toggleOptions: [
                                {
                                    value: DeliveryChargeMethod.PerDevice,
                                    label: "Per device",
                                },
                                {
                                    value: DeliveryChargeMethod.PerShipment,
                                    label: "Per shipment",
                                },
                            ],
                            hidden: this.props.data[Effected.ChargeForDelivery] !== true,
                        },
                        {
                            label: "How much?",
                            name: Effected.DeliveryCharge,
                            type: InputType.Currency,
                            hidden: this.props.data[Effected.ChargeForDelivery] !== true,
                        },
                        {
                            label: "Extra details about devices",
                            name: Effected.ExtraDeviceDetails,
                            type: InputType.LargeText,
                        },
                    ] }))),
            this.props.tender.details.simOnlyFunded !== undefined &&
                hardwareForm));
    }
}
