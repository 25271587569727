import React from "react";
import Table from "@Components/Table";
import { displayAllowance } from "@Helpers/DisplayAllowance";
import { ComponentTypes, Effected, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { format } from "@Shared/Helpers/Money";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import styles from "./index.module.scss";
const fields = [
    {
        field: Effected.TariffComponentType,
        header: "",
        display: (data) => {
            return (React.createElement("div", { className: styles.title }, displayEnum(ComponentTypes, data[Effected.TariffComponentType])));
        },
    },
    {
        field: Effected.TariffComponentItems,
        header: "Allowances",
        display: (data) => {
            const items = data[Effected.TariffComponentItems] || [];
            const allowances = items.map((item, index) => (React.createElement("div", { key: `${item.allowanceType}-${index}` }, displayAllowance(item))));
            if (allowances.length === 0) {
                return React.createElement("div", { className: styles.tableValue }, "---");
            }
            return React.createElement("div", { className: styles.tableValue }, allowances);
        },
    },
    {
        field: Effected.TariffComponentNumberLines,
        header: "No.",
        display: (data) => {
            return (React.createElement("div", { className: styles.tableValue }, data[Effected.TariffComponentNumberLines]));
        },
    },
    {
        field: "netWithMargin",
        header: "Net Cost",
        display: (data) => {
            return (React.createElement("div", { className: `${styles.tableValue} ${styles.cost}` }, format(data.netWithMargin)));
        },
    },
    {
        field: "totalWithMargin",
        header: "Total Cost",
        display: (data) => {
            return (React.createElement("div", { className: `${styles.tableValue} ${styles.cost} ${styles.total}` }, format(data.totalWithMargin)));
        },
    },
];
export default class TariffTable extends React.Component {
    render() {
        return (React.createElement(Table, { data: this.props.data, fields: fields, noNav: true, light: true, noPadding: true, padding: "0.5rem" }));
    }
}
