import equal from "fast-deep-equal";
import React from "react";
import Card from "@Components/Card";
import LoadingFallback from "@Components/Pages/Fallbacks/Loading";
import { Effected } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import Factory from "@Shared/Factory";
import MDMForm from "@SmartComponents/Proposal/MDM/Form";
export default class Mdm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }
    async componentDidMount() {
        let library = this.props.supplier.MDMLibrary || {}, { data } = this.props;
        library = JSON.parse(JSON.stringify(library));
        data = JSON.parse(JSON.stringify(data));
        const proposal = { [Effected.OfferMDM]: false, ...library, ...data };
        if (!equal(proposal, data)) {
            const response = Factory.Response({
                ...this.props.response.toSave,
                proposal,
            });
            await this.props.save(response, true);
        }
        this.setState({
            loading: false,
        });
    }
    render() {
        if (this.state.loading) {
            return React.createElement(LoadingFallback, null);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, { title: "Mobile Device Management", subTitle: "If the customer has indicated MDM is not required set the first question to 'No'" },
                React.createElement(MDMForm, { submit: this.props.save, inputChange: this.props.inputChange, data: this.props.data, errors: this.props.errors }))));
    }
}
