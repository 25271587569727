import { AllowanceTypes, ComponentTypes, Effected, Platform, QTYType, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export default (tariffComponents) => {
    const tariffSummaries = [];
    const sharedTariffSections = [];
    const enhancedTariffComponents = tariffComponents.map((tc) => ({
        netWithMargin: tc.net,
        totalWithMargin: tc.total,
        ...tc,
    }));
    for (const comp of enhancedTariffComponents) {
        if (comp.type < ComponentTypes["Voice & Text"] ||
            comp.type === ComponentTypes.Aggregated_Data) {
            sharedTariffSections.push(comp);
            continue;
        }
        let hasData = false;
        let dataAllowance = 0;
        let type = "Individual";
        let costPerGB = 0;
        const platform = comp[Effected.TariffComponentNetwork];
        for (const allowance of comp.items) {
            if (allowance.allowanceType === AllowanceTypes["UK Data (GB)"]) {
                hasData = true;
                dataAllowance =
                    allowance.allowanceQTYType === QTYType.Unlimited
                        ? "unlimited"
                        : allowance.allowanceQTY;
                if (allowance.allowanceQTYType === QTYType.Shared) {
                    type = "Shared";
                }
                if (allowance.allowanceQTYType === QTYType.Pooled) {
                    type = "Pooled";
                }
                if (allowance.allowanceQTYType === QTYType.Aggregated) {
                    type = "Aggregated";
                }
                if (allowance.allowanceQTYType !== QTYType.Unlimited) {
                    if (comp.networkComponent === Platform.EE_Teams) {
                        costPerGB = 2;
                    }
                    if ([
                        Platform["O2 - Plan.com"],
                        Platform["EE - Plan.com"],
                    ].includes(platform)) {
                        costPerGB = 4.5;
                    }
                }
            }
        }
        if (!hasData) {
            if (comp.type === ComponentTypes["Voice & Text"]) {
                sharedTariffSections.push(comp);
            }
            continue;
        }
        type =
            comp.type === ComponentTypes.MBB
                ? `${type} MBB`
                : `Voice with ${type} Data`;
        tariffSummaries.push({
            id: `${platform}-${type}-${comp.numLines}-${dataAllowance}`,
            platform,
            type,
            lines: comp.type === ComponentTypes.MBB ? 0 : comp.numLines,
            dataLines: comp.type === ComponentTypes.MBB ? comp.numLines : 0,
            dataAllowance,
            costOfLine: comp.netWithMargin,
            costPerGB,
        });
    }
    tariffSummaries.sort((a, b) => b.lines - a.lines);
    const sharedTariffSectionsByPlatform = sharedTariffSections.reduce((byPlatform, comp) => {
        const platform = comp[Effected.TariffComponentNetwork];
        if (!byPlatform[platform]) {
            byPlatform[platform] = [];
        }
        byPlatform[platform].push(comp);
        return byPlatform;
    }, {});
    for (const [platform, sharedTariffSections] of Object.entries(sharedTariffSectionsByPlatform)) {
        let hasShared = false, hasAggregated = false, lines = 0, dataLines = 0, dataAllowance = 0, costOfLine = 0, costPerGB = 0;
        for (const comp of sharedTariffSections) {
            if (comp.type === ComponentTypes.Leader) {
                dataLines++;
                continue;
            }
            if (comp.type === ComponentTypes["Leader inc. Shared Data"]) {
                lines++;
            }
            if (comp.type === ComponentTypes["Voice & Text"]) {
                lines += comp.numLines;
            }
            if (comp.type === ComponentTypes.Data_Sharers) {
                dataLines += comp.numLines;
            }
            if (comp.type === ComponentTypes.Aggregated_Data) {
                dataLines += comp.numLines;
            }
            for (const allowance of comp.items) {
                if (allowance.allowanceQTYType === QTYType.Shared &&
                    allowance.allowanceType === "UK Data (GB)") {
                    hasShared = true;
                    dataAllowance += allowance.allowanceQTY;
                }
                if (allowance.allowanceQTYType === QTYType.Aggregated &&
                    allowance.allowanceType === "UK Data (GB)") {
                    hasAggregated = true;
                    dataAllowance += allowance.allowanceQTY;
                }
            }
            if (comp.type === ComponentTypes.Shared_Data) {
                costPerGB += comp.netWithMargin;
            }
            else if (comp.type !== ComponentTypes["Leader inc. Shared Data"]) {
                costOfLine += comp.netWithMargin;
            }
        }
        if (hasShared || hasAggregated) {
            const pf = Number(platform);
            const sOrA = hasAggregated ? "Aggregated" : "Shared";
            const type = lines > 0 ? `Voice with ${sOrA} Data` : `${sOrA} Data`;
            if ([Platform["O2 - Plan.com"], Platform["EE - Plan.com"]].includes(pf)) {
                costPerGB = 4.5;
            }
            if (pf === Platform.EE_Teams) {
                costPerGB = 2;
            }
            tariffSummaries.unshift({
                id: `${platform}-${type}-${lines}-${dataAllowance}`,
                platform: pf,
                type,
                lines,
                dataLines: Math.max(dataLines - lines, 0),
                dataAllowance,
                costPerGB,
                costOfLine,
            });
        }
    }
    return tariffSummaries;
};
