import React from "react";
import { DisplayLargeText } from "@Components/DisplayLargeText";
import HoverTooltip from "@Components/HoverTooltip";
import Arrow from "@Components/Icons/Arrow";
import Table from "@Components/Table";
import { LeasingType, LikeHardwareFunded, Months, } from "@Shared/Entities/Customer/Tender/Details/enums";
import { AnalysisMethod, ChargeMethod, DMFeatures, Effected, FreeNextDay, Frequency, MDMNames, MDMOS, SmallTimeFrame, TimePeriods, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import { displayBool } from "@Shared/Helpers/DisplayBool";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { format } from "@Shared/Helpers/Money";
import styles from "./index.module.scss";
// const displayDevicesPrices = (
//     devicePrices: { deviceId: string; price: number }[] = [],
//     devices: Device[] = []
// ) => {
//     const getNameAndPrice = ({
//         deviceId,
//         price,
//     }: {
//         deviceId: string
//         price: number
//     }) => {
//         const device =
//             devices.find(({ id }) => id === deviceId) || ({} as Device)
//
//         return {
//             subtitle: device.name,
//             value: format(price),
//         }
//     }
//
//     const displayed = createList([
//         {
//             subtitle: <div className={styles.header}>Sim Free</div>,
//             value: "",
//         },
//         ...devicePrices.map(getNameAndPrice),
//     ])
//
//     return <>{displayed}</>
// }
const getLeasingData = (tender, response, devices) => {
    const { proposal } = response;
    if (tender.details.likeHardwareFunded !== LikeHardwareFunded.Leasing) {
        return;
    }
    const leasingFields = [
        {
            field: "latestId",
            hidden: true,
        },
        {
            field: "name",
            header: "",
            display: ({ latestId }) => {
                const device = devices?.find(({ id }) => id === latestId);
                return device?.name;
            },
            leftAlign: true,
        },
        {
            field: "quantity",
            header: "Qty",
        },
        {
            field: "monthlyCost",
            header: "Monthly cost",
            display: ({ monthlyCost }) => format(monthlyCost),
            rightAlign: true,
        },
        {
            field: "totalMonthlyCost",
            header: "Total monthly cost",
            display: ({ totalMonthlyCost }) => format(totalMonthlyCost),
            rightAlign: true,
        },
    ];
    return {
        title: "Leasing",
        body: [
            createList([
                {
                    subtitle: `Offers ${displayEnum(LeasingType, tender.details.leasingType)}`,
                    value: displayBool(proposal.get(Effected.OfferLeasing)),
                },
                {
                    subtitle: "Setup fee",
                    value: format(proposal.get(Effected.SetupFee)),
                    hidden: !proposal.offerLeasing,
                },
                {
                    subtitle: "Lease term",
                    value: displayEnum(Months, proposal.get(Effected.MinimumLeaseTerm)),
                    hidden: !proposal.offerLeasing,
                },
            ], "leasing1"),
            proposal.offerLeasing ? (React.createElement(Table, { data: proposal.get(Effected.LeasingData), fields: leasingFields, noNav: true, key: "leasing2" })) : (React.createElement(React.Fragment, null)),
            proposal.offerLeasing ? (createList([
                {
                    subtitle: "Device insurance required",
                    value: displayBool(proposal.get(Effected.LeasingRequiresInsurance)),
                    hidden: !proposal.offerLeasing,
                },
                {
                    subtitle: "Price dependant on direct debit",
                    value: displayBool(proposal.get(Effected.DirectDebit)),
                    hidden: !proposal.offerLeasing,
                },
                {
                    subtitle: "Percentage cost if not using direct debit",
                    value: `${proposal.get(Effected.DirectDebitCost)}%`,
                    hidden: !proposal.offerLeasing ||
                        proposal.get(Effected.DirectDebitCost) ===
                            undefined,
                },
                {
                    subtitle: "End of leasing lump sum payment",
                    value: format(proposal.get(Effected.LeasingLumpSum)),
                    hidden: !proposal.offerLeasing,
                },
                {
                    subtitle: "Additional notes",
                    value: DisplayLargeText(proposal.get(Effected.LeasingNotes)),
                    hidden: proposal.offerLeasing ||
                        proposal.get(Effected.LeasingNotes) ===
                            undefined,
                },
            ], "leasing3")) : (React.createElement(React.Fragment, null)),
        ],
    };
};
const getFields = (tender, response, devices, comparison = false) => {
    const { proposal } = response;
    return [
        getLeasingData(tender, response, devices),
        {
            title: "MDM",
            hidden: tender.noMDM,
            body: createList([
                {
                    subtitle: "MDM offered",
                    value: displayBool(proposal.get(Effected.OfferMDM)),
                },
                {
                    subtitle: "Tool offered",
                    value: displayEnum(MDMNames, proposal.get(Effected.MDMName)),
                },
                {
                    subtitle: "Tool version",
                    value: proposal.get(Effected.MDMDetails),
                },
                {
                    subtitle: "MDM name",
                    value: proposal.get(Effected.OtherName),
                },
                {
                    subtitle: "Handset operating system",
                    value: displayEnum(MDMOS, proposal.get(Effected.MDMOS)),
                },
                {
                    subtitle: "MDM included in tariff solution",
                    value: displayBool(proposal.get(Effected.IsCloudFree)),
                },
                {
                    subtitle: "MDM per line per month",
                    value: format(proposal.get(Effected.MDMLineCost)),
                },
                {
                    subtitle: "Minimum commitment period",
                    value: displayEnum(TimePeriods, proposal.get(Effected.MinCommitmentPeriod)),
                },
                {
                    subtitle: "Min. commitment period in months",
                    value: proposal.get(Effected.MinCommitmentPeriodOther),
                },
                {
                    subtitle: "Do you offer a range of MDM options?",
                    value: displayBool(proposal.get(Effected.MDMRange)),
                },
                {
                    subtitle: "Are enterprise versions offered?",
                    value: displayBool(proposal.get(Effected.EnterpriseVersions)),
                },
            ]),
        },
        {
            title: "Data Management",
            hidden: tender.noDM,
            body: createList([
                {
                    subtitle: "Data management offered",
                    value: displayBool(proposal.get(Effected.OfferDM)),
                },
                {
                    subtitle: "Tool offered",
                    value: proposal.get(Effected.DMTool),
                },
                {
                    subtitle: "Handset operating system",
                    value: displayEnum(MDMOS, proposal.get(Effected.DMOS)),
                },
                {
                    subtitle: "Features",
                    value: (proposal.get(Effected.DMFeatures) || [])
                        .map((value) => displayEnum(DMFeatures, value))
                        .join(", "),
                    hidden: proposal.get(Effected.DMFeatures) === undefined ||
                        proposal.get(Effected.DMFeatures).length === 0,
                },
                {
                    subtitle: "Data management included in tariff solution",
                    value: displayBool(proposal.get(Effected.DMFree)),
                },
                {
                    subtitle: "Cost per line per month",
                    value: format(proposal.get(Effected.DMLineCost)),
                },
                {
                    subtitle: "Minimum commitment period",
                    value: displayEnum(TimePeriods, proposal.get(Effected.DMMinCommitmentPeriod)),
                },
                {
                    subtitle: "Min. commitment period in months",
                    value: proposal.get(Effected.DMMinCommitmentPeriodOther),
                },
                {
                    subtitle: "Do you offer a range of data management options?",
                    value: displayBool(proposal.get(Effected.DMRange)),
                },
                {
                    subtitle: "Are enterprise versions offered?",
                    value: displayBool(proposal.get(Effected.DMEnterprise)),
                },
            ]),
        },
        {
            title: "Customer Service",
            body: createList([
                {
                    subtitle: "Named account manager",
                    value: displayBool(proposal.get(Effected.AccountManaged)),
                },
                {
                    subtitle: "Site visits",
                    value: proposal.get(Effected.AccountManagerVisit)
                        ? displayEnum(Frequency, proposal.get(Effected.AccountManagerVisitFrequency))
                        : "No",
                },
                {
                    subtitle: "Formal complaint process",
                    value: displayBool(proposal.get(Effected.ComplaintProcess)),
                },
                {
                    subtitle: "Ticketing system",
                    value: displayBool(proposal.get(Effected.TicketSystem)),
                },
                {
                    subtitle: "VIP Response time for customer queries",
                    value: proposal.get(Effected.VIPServiceResponseTime) ===
                        undefined
                        ? "N/A"
                        : proposal.get(Effected.VIPServiceResponseTime) +
                            " hours",
                },
                {
                    subtitle: "Response time for customer queries",
                    value: displayEnum(SmallTimeFrame, proposal.get(Effected.ResponseTime)),
                },
                {
                    subtitle: "STD. resolution time",
                    value: displayEnum(SmallTimeFrame, proposal.get(Effected.ResolutionTime)),
                },
                {
                    subtitle: "Free next day 24 month swap service on handsets",
                    value: proposal.get(Effected.FreeNextDay) && comparison ? (React.createElement(React.Fragment, null,
                        "Yes *",
                        React.createElement(HoverTooltip, { className: styles.swapServiceTooltip }, "* Excluding iPhone"))) : (displayEnum(FreeNextDay, proposal.get(Effected.FreeNextDay))),
                },
                {
                    subtitle: "Billing analysis",
                    value: displayBool(proposal.get(Effected.OngoingAnalysis)),
                },
                {
                    subtitle: "Billing analysis frequency",
                    value: displayEnum(Frequency, proposal.get(Effected.AnalysisFrequency)),
                },
                {
                    subtitle: "Analysis method",
                    value: displayEnum(AnalysisMethod, proposal.get(Effected.OngoingAnalysisMethod)),
                },
                {
                    subtitle: "Disposal of old handsets",
                    value: displayBool(proposal.get(Effected.DisposeHandsets)),
                },
                {
                    subtitle: "WEEE provided",
                    value: displayBool(proposal.get(Effected.WEEECertificates)),
                },
                {
                    subtitle: "Full value returned on recycle",
                    value: displayBool(proposal.get(Effected.FullRecycleValue)),
                },
                {
                    subtitle: "Own T&Cs",
                    value: displayBool(proposal.get(Effected.CustomTerms)),
                },
                {
                    subtitle: "Keep current sims",
                    value: displayBool(proposal.get(Effected.KeepSims)),
                },
                {
                    subtitle: "Charge for PAC codes",
                    value: displayBool(proposal.get(Effected.PACCodeCharge)),
                },
                {
                    subtitle: "PAC code charge method",
                    value: displayEnum(ChargeMethod, proposal.get(Effected.ChargeMethod)),
                    hidden: !proposal.PACCodeCharge,
                },
                {
                    subtitle: "PAC Cost",
                    value: proposal.get(Effected.PACCost),
                },
                {
                    subtitle: "Termination penalty",
                    value: displayBool(proposal.get(Effected.TerminationPenalty)),
                },
                {
                    subtitle: "Cost per line",
                    value: proposal.get(Effected.PenaltyPerLine),
                },
                {
                    subtitle: "Cost for paper statement",
                    value: displayBool(proposal.get(Effected.PaperStatements)),
                },
                {
                    subtitle: "Cost per bill",
                    value: proposal.get(Effected.CostForStatements),
                },
                {
                    subtitle: "Non direct debit charge",
                    value: proposal.get(Effected.NonDirectDebitCost),
                },
            ]),
        },
        {
            key: "Future Lines",
            title: (React.createElement("span", null,
                "Future New Line Benefits",
                React.createElement(HoverTooltip, null, "Sometimes Suppliers offer a hardware fund depending on new line tenure and cost."))),
            body: createList([
                {
                    subtitle: "Hardware fund per voice line",
                    value: format(proposal.get(Effected.HWFundPerLine)),
                },
                {
                    subtitle: "Hardware fund per data line",
                    value: format(proposal.get(Effected.HWFundPerDataSim)),
                },
                {
                    subtitle: "Hardware fund pro rata",
                    value: displayBool(proposal.get(Effected.HWProRata)),
                },
            ]),
            hidden: tender.isSmallBusiness,
        },
    ]
        .filter((values) => values !== undefined)
        .filter((object) => !(object?.title === "MDM" && tender.noMDM) &&
        !(object?.title === "Data Management" && tender.noDM));
};
export const createList = (body, key = "list") => {
    const list = body.map(({ subtitle, value, hidden, }, index) => {
        return (React.createElement("li", { key: `${subtitle}-${index}-item`, className: `${styles.subitem} ${hidden ? styles.hidden : undefined}` },
            React.createElement("div", null, subtitle),
            React.createElement("div", { className: styles.value }, value === undefined ? "-" : value)));
    });
    return (React.createElement("ul", { key: key, className: styles.list }, list));
};
export default class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: {
                ["Key Points"]: true,
            },
        };
    }
    render() {
        const data = getFields(this.props.tender, this.props.data, this.props.devices, this.props.comparison);
        const items = data.map(({ title, key, body, hidden, }, index) => {
            const shown = this.state.shown[key || title];
            return (React.createElement("div", { key: index, className: `${styles.wrapper} ${styles.accordion}` }, !hidden && (React.createElement(React.Fragment, null,
                React.createElement("li", { className: `${styles.mainitem} ${title === "Tariff"
                        ? styles.borderTop
                        : ""}`, onClick: () => {
                        const { shown } = this.state;
                        shown[key || title] =
                            !shown[key || title];
                        this.setState({ shown });
                    } },
                    title,
                    React.createElement(Arrow, { size: "0.8em", rotate: shown ? 90 : 0 })),
                React.createElement("div", { className: `${shown ? "" : styles.closed} ${styles.bodyWrapper}` }, body)))));
        });
        return (React.createElement("ul", { className: `${styles.list} ${this.props.comparison ? styles.comparison : styles.outerList}` }, items));
    }
}
