import HoverTooltip from "@Components/HoverTooltip";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import Button from "@Components/Button";
import Card from "@Components/Card";
import Form, { InputType } from "@Components/Form";
import FormInput from "@Components/Form/Input";
import Delete from "@Components/Icons/Delete";
import Edit from "@Components/Icons/Edit";
import Table from "@Components/Table";
import { ToastStatus } from "@Components/Toast";
import { displayAllowance } from "@Helpers/DisplayAllowance";
import { ComponentTypes, Effected, ManagementFeeChargeMethod, Platform, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import GetTotalTariffCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalTariffCost";
import { displayData } from "@Shared/Helpers/Data";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { format } from "@Shared/Helpers/Money";
import { checkValidTariffComponents } from "@SmartComponents/Proposal/validation";
import { getIconFromPlatform } from "@Helpers/Network";
import styles from "./index.module.scss";
import TariffConstructForm from "./TariffConstructForm";
export default class Tariff extends React.Component {
    constructor(props) {
        super(props);
        const data = this.props.data[Effected.TariffComponents] || {};
        data[Effected.TariffComponentNetwork] =
            data[Effected.TariffComponentNetwork] ||
                this.props.response.meta?.primaryPlatform;
        this.state = {
            data: data,
            errors: {},
            edit: false,
        };
    }
    componentDidUpdate(previousProps) {
        const network = this.props.response.meta?.primaryPlatform;
        if (previousProps.response.meta?.primaryPlatform !== network) {
            const { data = {} } = this.state;
            data[Effected.TariffComponentNetwork] = network;
            this.setState({ data });
        }
    }
    changeDiscount = (name, value) => {
        this.props.inputChange({
            name,
            value,
        });
    };
    update = (data) => {
        this.setState({ data });
    };
    saveComponent = () => {
        const { data } = this.state;
        this.setState({ edit: false });
        const errors = checkValidTariffComponents([data]);
        if (errors !== undefined) {
            const keys = Object.keys(errors[0]);
            for (const key of keys) {
                const error = errors[0][key];
                if (typeof error[0] !== "string") {
                    for (const allowance of error) {
                        for (const key of Object.keys(allowance)) {
                            this.props.notify({
                                title: "Oops",
                                description: allowance[key],
                                status: ToastStatus.Error,
                            });
                        }
                    }
                    continue;
                }
                this.props.notify({
                    title: "Oops",
                    description: error,
                    status: ToastStatus.Error,
                });
            }
            this.setState({
                errors: errors[0],
            });
            return;
        }
        const { [Effected.TariffComponents]: tariffComponents = [] } = this.props.data;
        //need to swap round "amount" with "qty" for Shared Data
        if (data.type === ComponentTypes.Shared_Data ||
            data.type === ComponentTypes["Leader inc. Shared Data"]) {
            const gb = data.numLines;
            data.items[0].allowanceQTY = gb;
            data.numLines = 1;
        }
        tariffComponents.push(data);
        this.props.inputChange({
            name: Effected.TariffComponents,
            value: tariffComponents,
        });
        const network = this.props.response.meta?.primaryPlatform;
        this.setState({
            data: {
                [Effected.TariffComponentNetwork]: network,
            },
            errors: {},
        });
    };
    render() {
        const totalCostPreATC = GetTotalTariffCost(this.props.data[Effected.TariffComponents]);
        const totalTariffCost = totalCostPreATC - (this.props.data[Effected.Discount] || 0);
        const totalContractCost = totalTariffCost * (this.props.tender.details.length || 24);
        if (this.props.data[Effected.HasMinimumSpend] === false) {
            ;
            (this.props.data[Effected.MinimumSpend] = 0) &&
                (this.props.data[Effected.ReducedHWFund] = undefined) &&
                (this.props.data[Effected.ReducedEffect] = undefined);
        }
        const filterNetworks = (option) => {
            return ((this.props.supplier.incumbentCanView !== undefined ||
                this.props.supplier.offeredPlatforms.includes(option.value)) &&
                ![Platform.DAISY, Platform.Other].includes(option.value));
        };
        const filterComponentTypes = (platform) => {
            if (platform === undefined || Platform[platform] === undefined) {
                return [];
            }
            const data = this.props.data[Effected.TariffComponents] || [];
            return Object.keys(ComponentTypes)
                .filter((key) => {
                if (typeof ComponentTypes[key] === "number") {
                    return false;
                }
                if (Number(key) === ComponentTypes.Data_Sharers) {
                    return (data.some((object = {}) => object[Effected.TariffComponentType] ===
                        ComponentTypes.Data_Sharers &&
                        object[Effected.TariffComponentNetwork] ===
                            platform) === false);
                }
                if (Number(key) === ComponentTypes.Leader) {
                    return (data.some((object = {}) => object[Effected.TariffComponentType] ===
                        ComponentTypes.Leader &&
                        object[Effected.TariffComponentNetwork] ===
                            platform) === false);
                }
                if (Number(key) ===
                    ComponentTypes["Leader inc. Shared Data"]) {
                    return (platform === Platform["O2 - Plan.com"] &&
                        data.some((object = {}) => object[Effected.TariffComponentType] ===
                            ComponentTypes["Leader inc. Shared Data"]) === false);
                }
                if (Number(key) === ComponentTypes.Shared_Data) {
                    return platform !== Platform["O2 - Plan.com"];
                }
                return true;
            })
                .map((value) => ({
                value: Number(value),
                label: displayEnum(ComponentTypes, value),
            }));
        };
        const { tender } = this.props;
        return (React.createElement(Grid2, { container: true, spacing: 2, padding: 0, paddingY: 2 },
            React.createElement(Grid2, { xs: 12 },
                React.createElement(Card, { title: "Customer Solution", className: styles.noMargin },
                    React.createElement("div", { className: styles.solution },
                        React.createElement("div", { className: styles.customerRequirements },
                            React.createElement(Form, { className: styles.lineForm, inputChange: this.props.inputChange, data: this.props.data, errors: this.props.errors, labelLeft: true, buttons: false, horizontal: true, longerInput: true, setErrors: this.props.setErrors, input: [
                                    {
                                        label: "Plan Name",
                                        name: Effected.PlanName,
                                        type: InputType.Text,
                                        limit: 30,
                                    },
                                ] }),
                            React.createElement("ul", { className: styles.list },
                                React.createElement("li", { className: styles.listItem },
                                    "Contract Length",
                                    React.createElement("span", { className: styles.listValue },
                                        tender.details.length || 24,
                                        " months")),
                                React.createElement("li", { className: styles.listItem },
                                    "Voice Lines",
                                    React.createElement("span", { className: styles.listValue }, tender.details.voiceLines || 0)),
                                React.createElement("li", { className: styles.listItem },
                                    "Data Lines",
                                    React.createElement("span", { className: styles.listValue }, tender.details.dataLines || 0)),
                                React.createElement("li", { className: styles.listItem },
                                    "Data Requested",
                                    React.createElement("span", { className: styles.listValue }, displayData(tender.details.overallData, 0)))))))),
            React.createElement(Grid2, { xs: 12 },
                React.createElement(Card, { title: "Construct Tariff", className: styles.noMargin },
                    React.createElement(Grid2, { container: true, spacing: 4 },
                        this.props.data[Effected.TariffComponents] !==
                            undefined &&
                            Array.isArray(this.props.data[Effected.TariffComponents]) &&
                            this.props.data[Effected.TariffComponents].length >
                                0 ? (React.createElement(Grid2, { xs: 12 },
                            React.createElement(Table, { noNav: true, totalCost: true, data: this.props.data[Effected.TariffComponents], fields: [
                                    {
                                        header: "Network",
                                        field: Effected.TariffComponentNetwork,
                                        display: (data) => {
                                            const Icon = getIconFromPlatform(data.networkComponent);
                                            if (Icon !== undefined) {
                                                return React.createElement(Icon, null);
                                            }
                                        },
                                    },
                                    {
                                        header: "Product",
                                        field: Effected.TariffComponentType,
                                        display: (data) => {
                                            return displayEnum(ComponentTypes, data[Effected
                                                .TariffComponentType]);
                                        },
                                        leftAlign: true,
                                    },
                                    {
                                        header: "",
                                        display: (data) => {
                                            const edit = () => {
                                                const index = data.tableRowKey;
                                                const components = this.props.data[Effected
                                                    .TariffComponents];
                                                const editData = components[index];
                                                components.splice(index, 1);
                                                //need to swap back round "amount" with "qty" for Shared Data
                                                if (editData.type ===
                                                    ComponentTypes.Shared_Data ||
                                                    editData.type ===
                                                        ComponentTypes["Leader inc. Shared Data"]) {
                                                    const gb = editData
                                                        .items[0]
                                                        .allowanceQTY;
                                                    editData.numLines =
                                                        gb;
                                                    editData.items[0].allowanceQTY = 1;
                                                }
                                                this.setState({
                                                    data: editData,
                                                    edit: true,
                                                });
                                            };
                                            const remove = () => {
                                                const index = data.tableRowKey;
                                                const components = this.props.data[Effected
                                                    .TariffComponents];
                                                components.splice(index, 1);
                                                this.props.inputChange({
                                                    value: components,
                                                    name: Effected.TariffComponents,
                                                });
                                            };
                                            return (React.createElement("div", { className: styles.buttons },
                                                React.createElement(Button, { className: styles.button, click: edit },
                                                    React.createElement(Edit, { light: true, size: "1.1rem" })),
                                                React.createElement(Button, { className: styles.button, red: true, click: remove },
                                                    React.createElement(Delete, { light: true, size: "1.1rem" }))));
                                        },
                                        field: "",
                                    },
                                    {
                                        header: "Allowances",
                                        field: Effected.TariffComponentItems,
                                        display: (data) => {
                                            if (!data[Effected
                                                .TariffComponentItems]) {
                                                return React.createElement(React.Fragment, null);
                                            }
                                            const items = (Array.isArray(data[Effected
                                                .TariffComponentItems])
                                                ? data[Effected
                                                    .TariffComponentItems]
                                                : []).map((allowance, index) => {
                                                return (React.createElement("div", { key: index, className: styles.allowanceItem }, displayAllowance(allowance)));
                                            });
                                            return (React.createElement("div", { className: styles.allowanceList }, items));
                                        },
                                    },
                                    {
                                        header: "Amount",
                                        field: Effected.TariffComponentNumberLines,
                                    },
                                    {
                                        header: "Standard",
                                        field: Effected.TariffComponentStandardCost,
                                        display: (data) => {
                                            if (data[Effected
                                                .TariffComponentType] ===
                                                ComponentTypes.Network_ATC) {
                                                return "";
                                            }
                                            return (React.createElement(React.Fragment, null,
                                                "\u00A3",
                                                Number.parseFloat(data[Effected
                                                    .TariffComponentStandardCost]).toFixed(2)));
                                        },
                                    },
                                    {
                                        header: "Discount",
                                        field: Effected.TariffComponentDiscount,
                                        display: (data) => {
                                            if (data[Effected
                                                .TariffComponentType] ===
                                                ComponentTypes.Network_ATC) {
                                                return "";
                                            }
                                            return (React.createElement(React.Fragment, null,
                                                "\u00A3",
                                                Number.parseFloat(data[Effected
                                                    .TariffComponentDiscount]).toFixed(2)));
                                        },
                                    },
                                    {
                                        header: "Net",
                                        field: Effected.TariffComponentNetCost,
                                        display: (data) => {
                                            if (data[Effected
                                                .TariffComponentType] ===
                                                ComponentTypes.Network_ATC) {
                                                return "";
                                            }
                                            return (React.createElement(React.Fragment, null,
                                                "\u00A3",
                                                Number.parseFloat(data[Effected
                                                    .TariffComponentNetCost]).toFixed(2)));
                                        },
                                    },
                                    {
                                        header: "Total",
                                        field: Effected.TariffComponentTotalCost,
                                        display: (data) => {
                                            let value = data[Effected
                                                .TariffComponentTotalCost] || 0;
                                            if (data[Effected
                                                .TariffComponentType] ===
                                                ComponentTypes.Network_ATC) {
                                                value = -value;
                                            }
                                            return (React.createElement("div", { className: styles.totalCost },
                                                "\u00A3",
                                                Number.parseFloat(value).toFixed(2)));
                                        },
                                    },
                                ] }))) : (React.createElement(React.Fragment, null)),
                        React.createElement(Grid2, { xs: 12, lg: 7 },
                            React.createElement(TariffConstructForm, { save: this.saveComponent, update: this.update, data: this.state.data, errors: this.state.errors, edit: this.state.edit, filterNetworks: filterNetworks, filterComponentTypes: filterComponentTypes })),
                        React.createElement(Grid2, { xs: 12, lg: 5 },
                            React.createElement("div", { className: styles.summary },
                                React.createElement("div", { className: styles.item },
                                    React.createElement("div", null, "Tariff Cost Before Monthly Supplier Discount"),
                                    React.createElement("div", null, format(totalCostPreATC))),
                                React.createElement("div", { className: styles.item },
                                    React.createElement("div", null,
                                        "Monthly Supplier Discount",
                                        React.createElement(HoverTooltip, { light: true }, "This is the discount offered by you, not the network")),
                                    React.createElement("div", { className: styles.discount },
                                        React.createElement(FormInput, { type: "currency", value: this.props.data[Effected.Discount], valueChanged: this.changeDiscount, name: Effected.Discount, error: this.props.errors[Effected.Discount] }))),
                                React.createElement("div", { className: styles.item },
                                    React.createElement("div", null, "Total Tariff Cost"),
                                    React.createElement("div", null, format(totalTariffCost))),
                                React.createElement("div", { className: styles.item },
                                    React.createElement("div", null, "Total Contract Cost"),
                                    React.createElement("div", null, format(totalContractCost))),
                                React.createElement("div", { className: styles.subForm },
                                    React.createElement(Form, { data: this.props.data, errors: this.props.errors, inputChange: this.props.inputChange, noSave: true, submit: this.props.save, labelLeft: true, topRight: true, input: [
                                            {
                                                label: "Do you charge a management fee to administer the account?",
                                                type: InputType.SmallToggle,
                                                name: Effected.IsManagementFee,
                                            },
                                            {
                                                label: "Is this charged at an account level or per line?",
                                                type: InputType.LargeToggle,
                                                name: Effected.ManagementFeeChargeMethod,
                                                toggleOptions: [
                                                    {
                                                        label: "Account",
                                                        value: ManagementFeeChargeMethod.Account_Level,
                                                    },
                                                    {
                                                        label: "Per Line",
                                                        value: ManagementFeeChargeMethod.Per_Line,
                                                    },
                                                ],
                                                hidden: !this.props.data[Effected.IsManagementFee],
                                                long: true,
                                            },
                                            {
                                                label: this.props.data[Effected
                                                    .ManagementFeeChargeMethod] ===
                                                    ManagementFeeChargeMethod.Per_Line
                                                    ? "Cost per line?"
                                                    : "Total monthly cost?",
                                                type: InputType.Currency,
                                                name: Effected.ManagementFee,
                                                hidden: !this.props.data[Effected.IsManagementFee],
                                            },
                                            {
                                                label: "Is your proposal, including its cost and benefits, dependent on this customer taking or renewing another non-mobile product now or later?",
                                                type: InputType.SmallToggle,
                                                name: Effected.ProposalDependant,
                                            },
                                            {
                                                label: "Please detail what specific impact to the customer cost or GP this will have",
                                                type: InputType.LargeText,
                                                name: Effected.SpecificChangesOrRequirements,
                                                hidden: !this.props.data[Effected
                                                    .ProposalDependant],
                                            },
                                            {
                                                label: "Does your solution allow E-sims?",
                                                type: InputType.SmallToggle,
                                                name: Effected.ESims,
                                            },
                                            {
                                                label: "Does your proposal have network approval?",
                                                type: InputType.SmallToggle,
                                                name: Effected.NetworkSupport,
                                            },
                                            {
                                                label: "Is there a required minimum spend for this contract?",
                                                type: InputType.SmallToggle,
                                                name: Effected.HasMinimumSpend,
                                            },
                                            {
                                                label: "How much?",
                                                type: InputType.Currency,
                                                noDecimal: true,
                                                name: Effected.MinimumSpend,
                                                hidden: !this.props.data[Effected.HasMinimumSpend],
                                            },
                                            {
                                                label: "If minimum spend is not achieved, will this reduce your hardware fund offer?",
                                                type: InputType.SmallToggle,
                                                name: Effected.ReducedHWFund,
                                                hidden: !this.props.data[Effected.HasMinimumSpend],
                                            },
                                            {
                                                label: "Please briefly detail the anticipated effect",
                                                type: InputType.LargeText,
                                                name: Effected.ReducedEffect,
                                                hidden: !(this.props.data[Effected
                                                    .HasMinimumSpend] &&
                                                    this.props.data[Effected
                                                        .ReducedHWFund]),
                                            },
                                        ] })))))))));
    }
}
