// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highlighted--DnLkI{color:var(--alt-highlighted-text-color)}.card--pfJqE{font-size:.85rem}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/HardwareFund/index.module.scss"],"names":[],"mappings":"AAAA,oBAAa,uCAAuC,CAAC,aAAM,gBAAgB","sourcesContent":[".highlighted{color:var(--alt-highlighted-text-color)}.card{font-size:.85rem}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted": "highlighted--DnLkI",
	"card": "card--pfJqE"
};
export default ___CSS_LOADER_EXPORT___;
