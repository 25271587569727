// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SaveButton--Nk0PD{display:flex;justify-content:right;padding:.7em}.form--qcZ67{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.deleteButton--Zqs2r{width:1.12rem;margin-top:.37rem;cursor:pointer}.totalWrapper--Zo9XI{display:flex}.totalWrapper--Zo9XI .filler--qR0Fk{flex:1}.totalWrapper--Zo9XI .total--Y27p5{margin-right:1.1em;padding:15px;color:#fff;font-weight:700;line-height:16px;background:var(--alt-highlighted-text-color);border-radius:4px}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/HardwareFund/LeasingPrices/index.module.scss"],"names":[],"mappings":"AAAA,mBAAY,YAAY,CAAC,qBAAqB,CAAC,YAAY,CAAC,aAAM,2EAA2E,CAAC,qBAAc,aAAa,CAAC,iBAAiB,CAAC,cAAc,CAAC,qBAAc,YAAY,CAAC,oCAAsB,MAAM,CAAC,mCAAqB,kBAAkB,CAAC,YAAY,CAAC,UAAU,CAAC,eAAe,CAAC,gBAAgB,CAAC,4CAA4C,CAAC,iBAAiB","sourcesContent":[".SaveButton{display:flex;justify-content:right;padding:.7em}.form{padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.deleteButton{width:1.12rem;margin-top:.37rem;cursor:pointer}.totalWrapper{display:flex}.totalWrapper .filler{flex:1}.totalWrapper .total{margin-right:1.1em;padding:15px;color:#fff;font-weight:700;line-height:16px;background:var(--alt-highlighted-text-color);border-radius:4px}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SaveButton": "SaveButton--Nk0PD",
	"form": "form--qcZ67",
	"deleteButton": "deleteButton--Zqs2r",
	"totalWrapper": "totalWrapper--Zo9XI",
	"filler": "filler--qR0Fk",
	"total": "total--Y27p5"
};
export default ___CSS_LOADER_EXPORT___;
