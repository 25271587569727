import React from "react";
import Checkbox from "@Components/Form/Checkbox";
import Arrow from "@Components/Icons/Arrow";
import Logo from "@Components/Icons/Logos/Logo";
import Table from "@Components/Table";
import { displayMonthData, displayRowKey } from "@Components/UsageTable";
import getBrokerName from "@Helpers/GetBrokerName";
import { displayCountry } from "@Shared/Helpers/Country";
import styles from "./index.module.scss";
const totalRowTitle = "Total";
export default class UsageData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrows: {},
            checkedFields: {},
        };
    }
    getAverage = ({ arrow, countryBreakdown, avg, isSearchMatch, noArrow, popout, tableRowKey, title, checkbox, topRow, className, ...months }) => {
        const monthsArray = Object.values(months);
        if (monthsArray.length === 0) {
            return 0;
        }
        const total = monthsArray.reduce((total, current) => {
            return total + Number(current);
        }, 0);
        return total / monthsArray.length;
    };
    showOpenedRows = (rows, tableKey) => {
        const { arrows = {} } = this.state;
        const table = arrows[tableKey] || {};
        Object.keys(table).map((rowKey) => {
            const row = table[rowKey];
            if (row) {
                const rowIndex = rows.findIndex(({ title }) => {
                    return title === rowKey;
                });
                const rowData = rows[rowIndex].countryBreakdown;
                rows.splice(rowIndex + 1, 0, ...rowData);
                const table = this.state.checkedFields[tableKey] || {};
                const countriesSelected = table[rowKey] || {};
                const totalSelected = Object.keys(countriesSelected)
                    .filter((key) => countriesSelected[key] === true)
                    .reduce((total, key) => {
                    const countryRow = rowData.find(({ title }) => title === key);
                    const { title, topRow, ...countryData } = countryRow;
                    Object.keys(countryData).map((month) => {
                        total[month] =
                            (total[month] || 0) + countryData[month];
                    });
                    return total;
                }, {});
                rows.splice(rowIndex + 1, 0, {
                    title: totalRowTitle,
                    ...totalSelected,
                    topRow: rowKey,
                    className: styles.selectedRow,
                });
            }
        });
    };
    showPopoutIcon = (object) => {
        const openPopout = () => {
            this.props.openPopout(object);
        };
        return (React.createElement(Logo, { className: `${styles.placeholder} ${styles.logo}`, onClick: openPopout }));
    };
    getTables = () => {
        const { breakdown: { stats, analysis }, } = this.props;
        if (!stats) {
            return React.createElement(React.Fragment, null);
        }
        const dataMonths = Object.keys(stats?.UK?.Data || {}).sort((a, b) => {
            return new Date(a).getTime() - new Date(b).getTime();
        });
        const fields = (tableTitle) => [
            {
                header: tableTitle,
                field: "title",
                leftAlign: true,
                display: (data) => {
                    return displayRowKey(data.title);
                },
                className: styles.titleColumn,
                widthControl: true,
            },
            {
                header: "",
                field: "arrow",
                leftAlign: true,
                widthControl: true,
                display: (data) => {
                    const topRow = data.topRow;
                    if (topRow !== undefined && data.title !== totalRowTitle) {
                        const countryName = data.title;
                        const changeCheckbox = () => {
                            const { checkedFields = {} } = this.state;
                            const table = checkedFields[tableTitle] || {};
                            const row = table[topRow] || {};
                            row[countryName] = !row[countryName];
                            table[topRow] = row;
                            checkedFields[tableTitle] = table;
                            this.setState({
                                checkedFields,
                            });
                        };
                        const table = this.state.checkedFields[tableTitle] || {};
                        const row = table[topRow] || {};
                        const shown = row[countryName];
                        return (React.createElement(Checkbox, { change: changeCheckbox, checked: shown }));
                    }
                    const title = tableTitle.replace(" ", "");
                    const { arrows = {} } = this.state;
                    const table = arrows[title] || {};
                    const shown = table[data.title];
                    const toggleArrow = () => {
                        table[data.title] = !shown;
                        arrows[title] = table;
                        this.setState({
                            arrows,
                        });
                    };
                    const showArrow = data.arrow;
                    if (showArrow) {
                        const dataExists = data.countryBreakdown !== undefined &&
                            data.countryBreakdown.length > 0;
                        if (dataExists) {
                            return (React.createElement(Arrow, { className: styles.arrow, onClick: toggleArrow, rotate: shown ? 90 : 0 }));
                        }
                    }
                },
            },
            ...[...dataMonths].map((month) => ({
                header: month,
                field: month,
                widthControl: true,
                alias: (data) => {
                    return displayMonthData(data[month], data.topRow || data.title);
                },
                rightAlign: true,
                hidden: this.props.tender?.isSmallBusiness,
                className: styles.monthData,
            })),
            {
                header: this.props.tender?.isSmallBusiness ? (React.createElement("div", { className: styles.avgHeader }, "Average monthly usage")) : ("Avg."),
                field: "avg",
                widthControl: true,
                alias: (data) => {
                    const avg = this.getAverage(data);
                    return displayMonthData(avg, data.topRow || data.title);
                },
                rightAlign: true,
                className: styles.monthData,
            },
            {
                header: "",
                field: "popout",
                display: (data) => {
                    const list = [
                        {
                            row: "Data",
                            table: "UK",
                            total: analysis.UK_Data?.total,
                        },
                        {
                            row: "Non-UK Data",
                            table: "UK",
                            total: analysis.Roaming_Data?.total,
                        },
                        {
                            row: "Prem. Calls",
                            table: "UK",
                            total: analysis.UK_Premium_Calls?.total,
                        },
                        {
                            row: "Biz. Rate Calls",
                            table: "UK",
                            total: analysis.UK_Business_Calls?.total,
                        },
                        {
                            row: "Prem. SMS",
                            table: "UK",
                            total: analysis.UK_Premium_SMS?.total,
                        },
                        {
                            row: "Prem. MMS",
                            table: "UK",
                            total: analysis.UK_Premium_MMS?.total,
                        },
                        {
                            row: "Call",
                            table: "IDD",
                            total: analysis.IDD_Calls?.total,
                        },
                        {
                            row: "SMS",
                            table: "IDD",
                            total: analysis.IDD_SMS?.total,
                        },
                        {
                            row: "MMS",
                            table: "IDD",
                            total: analysis.IDD_MMS?.total,
                        },
                        {
                            row: "Data",
                            table: "Roaming",
                            total: analysis.Roaming_Data?.total,
                        },
                        {
                            row: "Days",
                            table: "Roaming",
                            total: analysis.Roaming_Days?.total,
                        },
                        {
                            row: "Call",
                            table: "Roaming",
                            total: analysis.Roaming_Calls?.total,
                        },
                        {
                            row: "SMS",
                            table: "Roaming",
                            total: analysis.Roaming_SMS?.total,
                        },
                        {
                            row: "MMS",
                            table: "Roaming",
                            total: analysis.Roaming_MMS?.total,
                        },
                    ].filter(({ total }) => total > 0);
                    const object = list.find(({ row, table }) => {
                        return row === data.title && table === tableTitle;
                    });
                    if (object !== undefined) {
                        return this.showPopoutIcon(object);
                    }
                    return React.createElement("div", { className: styles.placeholder });
                },
            },
            {
                hidden: true,
                field: "topRow",
            },
            {
                hidden: true,
                field: "className",
            },
            {
                hidden: true,
                field: "countryBreakdown",
            },
        ];
        const createTable = (data, tableTitle) => {
            this.sortRows(data);
            this.showOpenedRows(data, tableTitle);
            return (React.createElement(Table, { fields: fields(tableTitle), data: data, alternate: true, noNav: true, className: styles.table, noPadding: true, padding: "0.2rem" }));
        };
        const ukData = Object.keys(stats.UK).map((title) => {
            const shortenTitle = () => {
                const premium = /premium/i;
                if (premium.test(title)) {
                    return title.replace(premium, "Prem.");
                }
                const standard = /standard/i;
                if (standard.test(title)) {
                    return title.replace(standard, "Std.");
                }
                const directory = /directory calls/i;
                if (directory.test(title)) {
                    return title.replace(directory, "Dir. Enquiries");
                }
                const business = /business/i;
                if (business.test(title)) {
                    return title.replace(business, "Biz.");
                }
                return title;
            };
            const shortenedTitle = shortenTitle();
            return {
                title: shortenedTitle,
                ...stats.UK[title],
            };
        });
        const getRoamingCountryBreakdown = (title, topRow) => {
            const countryStats = stats.Roaming[title]?.origin || {};
            const countryBreakdown = Object.keys(countryStats).map((origin) => {
                return Object.keys(countryStats[origin]).map((destination) => {
                    const countryTitle = title === "Data" || title === "Days"
                        ? displayCountry(origin, this.props.tender?.isSmallBusiness)
                        : `${displayCountry(origin, this.props.tender?.isSmallBusiness)} to ${displayCountry(destination, this.props.tender?.isSmallBusiness)}`;
                    return {
                        title: countryTitle,
                        topRow,
                        ...countryStats[origin][destination],
                    };
                });
            });
            return countryBreakdown.flat();
        };
        const showOutOfUKUsage = !this.props.tender?.isSmallBusiness ||
            this.props.tender?.details.iddCost;
        if (showOutOfUKUsage) {
            ukData.push({
                title: "Non-UK Data",
                arrow: true,
                countryBreakdown: getRoamingCountryBreakdown("Data", "Non-UK Data"),
                ...stats.Roaming?.Data?.total,
            });
        }
        const iddData = Object.keys(stats.IDD).map((title) => {
            const countryStats = stats.IDD[title].destination || {};
            const countryBreakdown = Object.keys(countryStats).map((country) => {
                return {
                    title: displayCountry(country, this.props.tender?.isSmallBusiness),
                    topRow: title,
                    ...countryStats[country],
                };
            });
            return {
                title,
                ...stats.IDD[title].total,
                arrow: true,
                countryBreakdown,
            };
        });
        const roamingData = Object.keys(stats.Roaming).map((title) => {
            if (title === "Days") {
                const total = {};
                Object.keys(stats.Roaming[title]).map((country) => {
                    Object.keys(stats.Roaming[title][country]).map((month) => {
                        const current = total[month] || 0;
                        total[month] =
                            current +
                                stats.Roaming[title][country][month];
                        return total;
                    });
                });
                const countryBreakdown = Object.keys(stats.Roaming[title]).map((country) => {
                    return {
                        title: displayCountry(country, this.props.tender?.isSmallBusiness),
                        topRow: title,
                        ...stats.Roaming[title][country],
                    };
                });
                return {
                    title,
                    ...total,
                    arrow: true,
                    countryBreakdown,
                };
            }
            return {
                title,
                ...stats.Roaming[title].total,
                arrow: true,
                countryBreakdown: getRoamingCountryBreakdown(title, title),
            };
        });
        const ukTable = createTable(ukData, "UK");
        const iddTable = showOutOfUKUsage ? createTable(iddData, "IDD") : React.createElement(React.Fragment, null);
        const roamingTable = showOutOfUKUsage ? (createTable(roamingData, "Roaming")) : (React.createElement(React.Fragment, null));
        return (React.createElement(React.Fragment, null,
            ukTable,
            iddTable,
            roamingTable));
    };
    sortRows = (data) => {
        const typeScore = (title) => {
            if (/data/i.test(title)) {
                return 4;
            }
            if (/call/i.test(title)) {
                return 3;
            }
            if (/sms/i.test(title)) {
                return 2;
            }
            if (/mms/i.test(title)) {
                return 1;
            }
            return 0;
        };
        return data.sort((a, b) => {
            const t = typeScore(b.title) - typeScore(a.title);
            return t === 0 ? a.title.length - b.title.length : t;
        });
    };
    displayTextBox = () => {
        if (this.props.tender?.isSmallBusiness) {
            return (React.createElement("div", { className: styles.explanationBox },
                React.createElement("div", { className: styles.text },
                    "The customer has not provided their Current Contract's Bill Reports. This table shows an ",
                    React.createElement("b", null, "average"),
                    " of the Usage that other similar Customers on Predict have\u00A0had.")));
        }
        const superUsers = this.props.breakdown.superUsers.length;
        return (React.createElement("div", { className: styles.explanationBox },
            React.createElement("div", { className: styles.text },
                "Click on any of the ",
                getBrokerName(),
                " logos to view a more thorough analysis of the customer's usage data",
                superUsers > 0 && (React.createElement("div", null,
                    React.createElement("span", { className: styles.superUsers },
                        "There are ",
                        superUsers,
                        " super users"),
                    this.showPopoutIcon({
                        table: "Super Users",
                    })))),
            React.createElement(Logo, { className: styles.explanationLogo })));
    };
    render() {
        const tables = this.getTables();
        return (React.createElement(React.Fragment, null,
            this.displayTextBox(),
            tables));
    }
}
