// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleText--kkImQ{margin:0 .3em;color:var(--alt-highlighted-text-color);font-weight:600;font-style:normal;line-height:20px}.process--FiFVw{position:relative;display:flex}.process--FiFVw .dots--PlQ2a{position:absolute;top:35%;left:20%;width:65%;border-bottom:1px dashed #b0bcbc}.process--FiFVw .step--XqNAV{z-index:1;flex:1;text-align:center;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.footer--yPKsC{display:flex;align-items:center;justify-content:space-between}.footer--yPKsC .footerText--mZpl4{display:flex;flex:1;align-items:center}.footer--yPKsC .footerText--mZpl4 .icon--jOqgP{min-width:2.5em;max-width:2.5em;padding:.2em}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Introduction/index.module.scss"],"names":[],"mappings":"AAAA,kBAAW,aAAa,CAAC,uCAAuC,CAAC,eAAe,CAAC,iBAAiB,CAAC,gBAAgB,CAAC,gBAAS,iBAAiB,CAAC,YAAY,CAAC,6BAAe,iBAAiB,CAAC,OAAO,CAAC,QAAQ,CAAC,SAAS,CAAC,gCAAgC,CAAC,6BAAe,SAAS,CAAC,MAAM,CAAC,iBAAiB,CAAC,2EAA2E,CAAC,eAAQ,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,kCAAoB,YAAY,CAAC,MAAM,CAAC,kBAAkB,CAAC,+CAA0B,eAAe,CAAC,eAAe,CAAC,YAAY","sourcesContent":[".titleText{margin:0 .3em;color:var(--alt-highlighted-text-color);font-weight:600;font-style:normal;line-height:20px}.process{position:relative;display:flex}.process .dots{position:absolute;top:35%;left:20%;width:65%;border-bottom:1px dashed #b0bcbc}.process .step{z-index:1;flex:1;text-align:center;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.footer{display:flex;align-items:center;justify-content:space-between}.footer .footerText{display:flex;flex:1;align-items:center}.footer .footerText .icon{min-width:2.5em;max-width:2.5em;padding:.2em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleText": "titleText--kkImQ",
	"process": "process--FiFVw",
	"dots": "dots--PlQ2a",
	"step": "step--XqNAV",
	"footer": "footer--yPKsC",
	"footerText": "footerText--mZpl4",
	"icon": "icon--jOqgP"
};
export default ___CSS_LOADER_EXPORT___;
