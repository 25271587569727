import { DataSize } from "@Shared/Entities/Customer/Usage/enums";
import { UnitType } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import { dataConvertFromTo } from "@Shared/Helpers/Data";
export default (qty, type, useNewDataConversion = false) => {
    switch (type) {
        case UnitType.Data:
            if (!useNewDataConversion) {
                return qty * 1000;
            }
            return dataConvertFromTo(qty, DataSize.GB, DataSize.MB);
        case UnitType.Mins:
            return qty * 60;
        case UnitType.SMS:
        case UnitType.MMS:
            return qty;
    }
};
