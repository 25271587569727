import SVG from "@Components/Icons/SVG";
export default class Delete extends SVG {
    static defaultProps = {
        size: `1.5vw`,
        className: "",
        rotate: 0,
        fillOpacity: 1,
        onClick: () => { },
        edit: false,
        primary: false,
        dark: false,
        light: false,
        create: false,
        delete: false,
        noFill: true,
    };
    strokeDetails = {
        stroke: this.props.color === undefined
            ? this.props.light
                ? `#FFFFFF`
                : `#0EABDB`
            : this.props.color,
        strokeWidth: "3",
        strokeLinecap: "round",
        strokeLinejoin: "round",
    };
    fill = "none";
    path = [
        "M 14.2857 14.2857 V 92.8571 H 85.7143 V 14.2857 M 0 14.2857 H 35.7143 M 100 14.2857 H 64.2857 M 60.7143 32.1429 V 75 M 39.2857 75 V 32.1429 M 35.7143 14.2857 V 0 H 64.2857 V 14.2857 M 35.7143 14.2857 H 64.2857",
    ];
}
