// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--oMSFE .header--CvdL9{display:flex;justify-content:flex-end}.container--oMSFE .total--zbl6y{position:fixed;right:30.2%;bottom:0;width:400px;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);color:#fff;font-weight:700;background:var(--alt-highlighted-text-color);border-radius:4px}.container--oMSFE .total--zbl6y .textLeft--CGBJm{float:left}.container--oMSFE .total--zbl6y .textRight--XwM5J{float:right}@media(min-width: 960px){.container--oMSFE{gap:var(--standard-horizontal-padding)}}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Oob/index.module.scss"],"names":[],"mappings":"AAAA,iCAAmB,YAAY,CAAC,wBAAwB,CAAC,gCAAkB,cAAc,CAAC,WAAW,CAAC,QAAQ,CAAC,WAAW,CAAC,2EAA2E,CAAC,UAAU,CAAC,eAAe,CAAC,4CAA4C,CAAC,iBAAiB,CAAC,iDAA4B,UAAU,CAAC,kDAA6B,WAAW,CAAC,yBAAyB,kBAAW,sCAAsC,CAAC","sourcesContent":[".container .header{display:flex;justify-content:flex-end}.container .total{position:fixed;right:30.2%;bottom:0;width:400px;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding);color:#fff;font-weight:700;background:var(--alt-highlighted-text-color);border-radius:4px}.container .total .textLeft{float:left}.container .total .textRight{float:right}@media(min-width: 960px){.container{gap:var(--standard-horizontal-padding)}}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--oMSFE",
	"header": "header--CvdL9",
	"total": "total--zbl6y",
	"textLeft": "textLeft--CGBJm",
	"textRight": "textRight--XwM5J"
};
export default ___CSS_LOADER_EXPORT___;
