// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--sdPOT{display:flex;align-items:center;justify-content:space-between;margin-bottom:.5em;font-weight:600;font-size:medium}.header--sdPOT .buttonGrouped--rePKX{display:flex;gap:1rem}.tableContainer--dVa8a{font-size:.85rem}.addButton--fMKX1{display:flex;justify-content:flex-end}.inputStyle--kK5p2{min-width:5rem;max-width:8rem}.filler--PHgM8{width:1.5em;margin-top:.3vw}.checkbox--IycEo{width:5%}.name--AyETC{width:17%}.tooltip--t3zNk{width:5%}.costCol--tY2pD{background-color:beige}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Oob/DisplayTable/index.module.scss"],"names":[],"mappings":"AAAA,eAAQ,YAAY,CAAC,kBAAkB,CAAC,6BAA6B,CAAC,kBAAkB,CAAC,eAAe,CAAC,gBAAgB,CAAC,qCAAuB,YAAY,CAAC,QAAQ,CAAC,uBAAgB,gBAAgB,CAAC,kBAAW,YAAY,CAAC,wBAAwB,CAAC,mBAAY,cAAc,CAAC,cAAc,CAAC,eAAQ,WAAW,CAAC,eAAe,CAAC,iBAAU,QAAQ,CAAC,aAAM,SAAS,CAAC,gBAAS,QAAQ,CAAC,gBAAS,sBAAsB","sourcesContent":[".header{display:flex;align-items:center;justify-content:space-between;margin-bottom:.5em;font-weight:600;font-size:medium}.header .buttonGrouped{display:flex;gap:1rem}.tableContainer{font-size:.85rem}.addButton{display:flex;justify-content:flex-end}.inputStyle{min-width:5rem;max-width:8rem}.filler{width:1.5em;margin-top:.3vw}.checkbox{width:5%}.name{width:17%}.tooltip{width:5%}.costCol{background-color:beige}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--sdPOT",
	"buttonGrouped": "buttonGrouped--rePKX",
	"tableContainer": "tableContainer--dVa8a",
	"addButton": "addButton--fMKX1",
	"inputStyle": "inputStyle--kK5p2",
	"filler": "filler--PHgM8",
	"checkbox": "checkbox--IycEo",
	"name": "name--AyETC",
	"tooltip": "tooltip--t3zNk",
	"costCol": "costCol--tY2pD"
};
export default ___CSS_LOADER_EXPORT___;
