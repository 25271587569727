import { roundToFormat } from "@Shared/Helpers/Numbers";
export const displayMonthData = (number, rowKey) => {
    const value = convertUnits(rowKey, number);
    if (/data/i.test(rowKey)) {
        return roundToFormat(value, 2);
    }
    return roundToFormat(value, 0);
};
const convertUnits = (row = "", value = 0) => {
    if (/data/i.test(row)) {
        return value / 1024;
    }
    if (/call/i.test(row)) {
        return value / 60;
    }
    return value;
};
export const displayRowKey = (key) => {
    const contains = (test) => {
        return new RegExp(test, "i").test(key);
    };
    let title = key;
    if (contains("data")) {
        title = `${title} (GB)`;
    }
    if (contains("call")) {
        title = `${title} (mins)`;
    }
    return title?.replaceAll("_", " ");
};
