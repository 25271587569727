import { assumeId, decodeToken, logout as logoutRequest, refreshToken, requestPasswordReset, } from "@Api/Auth";
import getSubdomain, { getDomain, getPath } from "@Helpers/GetSubdomain";
import sleep from "@Helpers/Sleep";
import { UserType } from "@Shared/Entities/AbstractEntities/User/enums";
import { ADD_CUSTOMER } from "@Store/Actions/Customers/companies";
import { ADD_PARTNER } from "@Store/Actions/Partners/companies";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import validate from "validate.js";
import { fetchCompany } from "../Suppliers/companies";
export const SET_USER = "setUser";
export const SET_TOKEN = "setToken";
export const SET_MFA = "setMFA";
export const REMOVE_MFA = "removeMFA";
export const LOGOUT = "logout";
const set = (dispatch) => ({ user, company, token, }) => {
    dispatch({
        type: SET_USER,
        data: user,
    });
    dispatch({
        type: SET_TOKEN,
        data: token,
    });
    switch (user.type) {
        case UserType.Customer:
            dispatch({
                type: ADD_CUSTOMER,
                data: company,
            });
            break;
        case UserType.Supplier:
            fetchCompany(dispatch, company.id);
            break;
        case UserType.Partner:
        case UserType.White_Label:
            dispatch({
                type: ADD_PARTNER,
                data: company,
            });
    }
    return true;
};
export const boot = async (dispatch) => {
    const domain = getDomain();
    const notLocal = domain !== "";
    let token;
    if (notLocal) {
        token = Cookies.get("token");
    }
    if (validate.isEmpty(token)) {
        token = localStorage.getItem("token");
    }
    if (validate.isEmpty(token)) {
        await logout(dispatch);
        return false;
    }
    const { user, company, subdomain, exp } = decodeToken(token);
    if (!exp || exp < dayjs.utc().unix()) {
        await logout(dispatch);
        return false;
    }
    const path = getPath();
    if (notLocal && getSubdomain() !== subdomain) {
        Cookies.set("token", token, { domain });
        await sleep(500);
        window.location.replace(`https://${subdomain}.${domain}${path}`);
        await sleep(500);
        return false;
    }
    const redirectIfNeeded = async (target) => {
        if (path.startsWith(target)) {
            return true;
        }
        await sleep(500);
        window.location.replace(target);
        await sleep(500);
        return false;
    };
    switch (user.type) {
        case UserType.Customer:
            if (!(await redirectIfNeeded("/customer"))) {
                return false;
            }
            break;
        case UserType.Supplier:
            if (!(await redirectIfNeeded("/supplier"))) {
                return false;
            }
            break;
        case UserType.Partner:
        case UserType.White_Label:
            if (!(await redirectIfNeeded("/broker"))) {
                return false;
            }
            break;
        case UserType.Admin:
            if (!(await redirectIfNeeded("/admin"))) {
                return false;
            }
            break;
    }
    if (user.mfaEnabled && !user.assumed) {
        const mfa = localStorage.getItem("mfa");
        if (!validate.isEmpty(mfa)) {
            let valid = true;
            try {
                const { id, exp } = decodeToken(mfa);
                if (!exp || exp < dayjs.utc().unix() || id !== user.id) {
                    valid = false;
                }
            }
            catch {
                valid = false;
            }
            if (!valid) {
                console.log("MFA Failed");
                await dispatch({
                    type: REMOVE_MFA,
                    data: mfa,
                });
                return false;
            }
            await dispatch({
                type: SET_MFA,
                data: mfa,
            });
        }
    }
    Cookies.remove("token", { domain });
    const reboot = () => {
        window.removeEventListener("focus", reboot);
        console.log("is active");
        boot(dispatch);
    };
    window.addEventListener("focus", reboot);
    set(dispatch)({ user, company, token });
};
export const setUser = (dispatch, data) => {
    return dispatch({
        type: SET_USER,
        data,
    });
};
export const refresh = (dispatch, force = false) => {
    return refreshToken(force).then(set(dispatch));
};
export const assume = async (dispatch, id, companyId) => {
    if (!confirm(`Do you wish the assume the role of user with id ${id}`)) {
        return false;
    }
    const token = await assumeId(id, companyId);
    dispatch({
        type: SET_TOKEN,
        data: token,
    });
    await sleep(500);
    await boot(dispatch);
    await sleep(500);
    window.location.reload();
    return true;
};
export const logout = async (dispatch) => {
    try {
        await logoutRequest();
    }
    catch {
        //already logged out
    }
    dispatch({
        type: LOGOUT,
    });
};
export const requestReset = (username) => {
    return requestPasswordReset(username);
};
