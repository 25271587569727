import ChildEntity from "@Shared/Entities/AbstractEntities/ChildEntity";
import constraints from "./constraints";
import migrate from "./migrate";
export default class UsageBreakdown extends ChildEntity {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get data() {
        const { version } = this._data;
        const { stats, analysis, superUsers, rackRate } = this;
        return {
            version,
            stats,
            analysis,
            superUsers,
            rackRate,
        };
    }
    get stats() {
        return this._data.stats;
    }
    get analysis() {
        return this._data.analysis || {};
    }
    get superUsers() {
        return this._data.superUsers || [];
    }
    get rackRate() {
        return this._data.rackRate || {};
    }
}
