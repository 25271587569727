import { ComponentTypes, Effected, } from "@Shared/Entities/Supplier/Response/Proposal/enums";
export default (data = []) => {
    return data.reduce((total, { [Effected.TariffComponentTotalCost]: cost, [Effected.TariffComponentType]: type, }) => {
        if (Number.isNaN(cost)) {
            return total;
        }
        if (type === ComponentTypes.Network_ATC) {
            return total - cost;
        }
        return total + cost;
    }, 0);
};
