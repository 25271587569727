import React from "react";
import { Effected } from "@Shared/Entities/Supplier/Response/Proposal/enums";
import GetTotalOobCost from "@Shared/Helpers/CalculateUsageCovered/GetTotalOobCost";
import { format } from "@Shared/Helpers/Money";
import Bundles from "./Bundles";
import DisplayTable from "./DisplayTable";
import styles from "./index.module.scss";
import RoamLikeHome from "./RoamLikeHome";
export default class Oob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bundles: undefined,
            roamLikeHome: undefined,
            usedColors: [],
        };
    }
    openBundles = (group) => {
        this.setState({ bundles: group });
    };
    closeBundles = () => {
        this.setState({ bundles: undefined });
        this.setBundleErrors([]);
    };
    openRoamLikeHome = (group) => {
        this.setState({ roamLikeHome: group });
    };
    closeRoamLikeHome = () => {
        this.setState({ roamLikeHome: undefined });
        this.setBundleErrors([]);
    };
    updateRackRate = ({ value, name }) => {
        this.props.inputChange({
            name: `${Effected.Rack_Rate}.${name}`,
            value,
        });
    };
    autofill = (rackRate) => {
        this.props.inputChange({
            name: Effected.Rack_Rate,
            value: rackRate,
        });
    };
    setBundleErrors = (errors) => {
        this.props.setErrors({ [Effected.Bundles]: errors });
    };
    updateBlobColors = (bbColors) => {
        this.setState({ usedColors: bbColors });
    };
    getColor(ii = 0) {
        const bundles = this.props.response?.proposal?.bundles || [];
        const colorArray = [
            "rgba(0,175,229,0.4)",
            "rgba(60,241,14,0.4)",
            "rgba(218,241,14,0.4)",
            "rgba(232,61,0,0.6)",
            "rgba(171,2,255,0.5)",
            "rgba(198,210,248,0.4)",
            "rgba(252,194,4,0.4)",
            "rgba(202, 232, 155, 0.4)",
            "rgba(174,115,248,0.5)",
            "rgba(0,255,247,0.5)",
            "rgba(9,122,86,0.4)",
            "rgba(255,0,106,0.4)",
            "rgba(169,133,32,0.4)",
            "rgba(255,136,0,0.5)",
            "rgba(255,118,118,0.4)",
            "rgba(255,0,106,0.4)",
            "rgba(143,6,101,0.5)",
            "rgba(171,2,255,0.5)",
            "rgba(174,115,248,0.5)",
            "rgba(232,61,0,0.6)",
            "rgba(47,0,255,0.4)",
            "rgba(0,109,255,0.5)",
            "rgba(198,210,248,0.4)",
            "rgba(0,255,247,0.5)",
            "rgba(224, 189, 242, 0.4)",
            "rgba(61,35,66, 0.4)",
            "rgba(130, 72, 121, 0.4)",
            "rgba(114, 192, 75, 0.4)",
            "rgba(202, 232, 155, 0.4)",
            "rgba(220, 97, 52, 0.4)",
            "rgba(220, 52, 161, 0.4)",
            "rgba(234,97,152,0.4)",
            "rgba(250, 217, 212, 0.4)",
            "rgba(220, 181, 52, 0.4)",
            "rgba(177, 235, 52, 0.4)",
            "rgba(238, 245, 184, 0.4)",
            "rgba(252,194,4,0.4)",
            "rgba(4,246,60,0.4)",
        ];
        const used = new Set(bundles.map((b) => b.color));
        let filtered = colorArray.filter((color) => {
            return !used.has(color);
        });
        if (filtered.length === 0) {
            filtered = colorArray;
        }
        return filtered[ii % filtered.length];
    }
    render() {
        const totalCost = format(GetTotalOobCost(this.props.tender, this.props.response.proposal));
        return (React.createElement("div", { className: styles.container },
            React.createElement(Bundles, { responseId: this.props.response.id, open: this.state.bundles, close: this.closeBundles, data: this.props.data, notify: this.props.notify, setErrors: this.setBundleErrors, errors: this.props.errors, getColor: this.getColor.bind(this), updateBlobColors: this.updateBlobColors, bundleBlobColors: this.state.usedColors }),
            React.createElement(RoamLikeHome, { responseId: this.props.response.id, open: this.state.roamLikeHome, close: this.closeRoamLikeHome, data: this.props.data, notify: this.props.notify, setErrors: this.setBundleErrors, errors: this.props.errors, getColor: this.getColor.bind(this), updateBlobColors: this.updateBlobColors, bundleBlobColors: this.state.usedColors }),
            React.createElement(DisplayTable, { openBundles: this.openBundles, openRoamLikeHome: this.openRoamLikeHome, rackRate: this.props.data[Effected.Rack_Rate], update: this.updateRackRate, response: this.props.response, errors: this.props.errors[Effected.Rack_Rate], tender: this.props.tender, setErrors: this.props.setErrors, supplier: this.props.supplier, autofill: this.autofill }),
            React.createElement("div", { className: styles.total },
                React.createElement("div", { className: styles.textLeft }, "Overall total:"),
                React.createElement("div", { className: styles.textRight }, totalCost))));
    }
}
