import ConvertUnitsForDisplay from "@Shared/Helpers/CalculateUsageCovered/ConvertUnitsForDisplay";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered/index";
export default (tender, proposal) => {
    const covered = CalculateUsageCovered(tender, proposal);
    let totalCost = 0;
    for (const allowanceType in covered) {
        totalCost += covered[allowanceType].costOfBundles;
        totalCost +=
            ConvertUnitsForDisplay(covered[allowanceType].remaining, allowanceType) * (proposal?.rackRate?.[allowanceType] || 0);
    }
    return totalCost;
};
