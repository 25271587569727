import React from "react";
import Dropdown from "@Components/Form/Dropdown";
import FormInput from "@Components/Form/Input";
import { BundleType, Duration, Effected, UnitType, } from "@Shared/Entities/Supplier/Response/Proposal/Bundle/enums";
import GetUnits from "@Shared/Helpers/CalculateUsageCovered/GetUnits";
import styles from "./index.module.scss";
export default (possibleAllowanceTypes, getColor) => (update, data = [], errors = [], index, defaultObject = {}) => {
    const values = data[index] || defaultObject;
    const error = errors[index] || {};
    const dropdownChange = ({ option: { value }, field, }) => {
        update({ value, name: field });
    };
    const dropdownMultiChange = ({ option, field, }) => {
        const value = option.map(({ value }) => value);
        update({ value, name: field });
    };
    const inputChange = (name, value) => {
        update({ name, value });
    };
    const setToUnlimited = (name) => () => {
        const current = values[name];
        const value = current === "Unlimited" ? undefined : "Unlimited";
        inputChange(name, value);
    };
    const rowsOptions = possibleAllowanceTypes.map((key) => ({
        value: key,
        label: key,
    }));
    const blobColor = values[Effected.Color] || getColor(index);
    const placeholder = "Leave blank if N/A";
    const showUnitInput = (unitType) => {
        return (values[Effected.ApplyRows] || []).some((allowanceType) => GetUnits(allowanceType) === unitType);
    };
    return [
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.header },
                React.createElement("span", { className: styles.title }, "Bundle Name"),
                React.createElement("div", { className: styles.colorBlob, style: { backgroundColor: blobColor } })),
            React.createElement(FormInput, { name: Effected.Name, value: values[Effected.Name], valueChanged: inputChange, error: error[Effected.Name] })),
        React.createElement("div", { className: styles.row },
            React.createElement("div", { className: styles.column },
                React.createElement("span", { className: styles.title }, "Type"),
                React.createElement(Dropdown, { name: Effected.Type, value: values[Effected.Type], error: error[Effected.Type], onChange: dropdownChange, enum: BundleType })),
            React.createElement("div", { className: styles.column },
                React.createElement("span", { className: styles.title }, "Duration"),
                React.createElement(Dropdown, { name: Effected.Duration, value: values[Effected.Duration], error: error[Effected.Duration], onChange: dropdownChange, enum: Duration }))),
        React.createElement("div", { className: styles.row },
            React.createElement("div", { className: styles.column },
                React.createElement("span", { className: styles.title }, getNumberBundlesName(values[Effected.Duration], values[Effected.Type])),
                React.createElement(FormInput, { name: Effected.NumberOfBundles, type: "number", noDecimal: true, value: values[Effected.NumberOfBundles], error: error[Effected.NumberOfBundles], valueChanged: inputChange, noIcon: true })),
            React.createElement("div", { className: styles.column },
                React.createElement("span", { className: styles.title }, "Cost of bundle"),
                React.createElement(FormInput, { name: Effected.Cost, type: "currency", value: values[Effected.Cost], error: error[Effected.Cost], valueChanged: inputChange, noIcon: true }))),
        React.createElement(React.Fragment, null,
            React.createElement("span", { className: styles.title }, "Apply to which rows?"),
            React.createElement(Dropdown, { name: Effected.ApplyRows, onChange: dropdownMultiChange, value: values[Effected.ApplyRows], error: error[Effected.ApplyRows], isMulti: true, options: rowsOptions })),
        React.createElement("div", { className: styles.unitsRow },
            showUnitInput(UnitType.Data) && (React.createElement("div", { className: styles.column },
                React.createElement("div", { className: styles.qtyHeader },
                    React.createElement("span", { className: styles.title }, "Data"),
                    React.createElement("span", { className: styles.unlimited },
                        "Unlimited",
                        React.createElement("div", { className: `${styles.circle} ${values[Effected.DataMB] === "Unlimited"
                                ? styles.active
                                : ""}`, onClick: setToUnlimited(Effected.DataMB) }))),
                values[Effected.DataMB] === "Unlimited" ? (React.createElement(FormInput, { type: "text", value: "Unlimited", disabled: true })) : (React.createElement(FormInput, { name: Effected.DataMB, type: "data", value: values[Effected.DataMB], error: error[Effected.DataMB], valueChanged: inputChange, placeholder: placeholder })))),
            showUnitInput(UnitType.Mins) && (React.createElement("div", { className: styles.column },
                React.createElement("div", { className: styles.qtyHeader },
                    React.createElement("span", { className: styles.title }, "Mins"),
                    React.createElement("span", { className: styles.unlimited },
                        "Unlimited",
                        React.createElement("div", { className: `${styles.circle} ${values[Effected.Mins] === "Unlimited"
                                ? styles.active
                                : ""}`, onClick: setToUnlimited(Effected.Mins) }))),
                values[Effected.Mins] === "Unlimited" ? (React.createElement(FormInput, { type: "text", value: "Unlimited", disabled: true })) : (React.createElement(FormInput, { name: Effected.Mins, type: "number", value: values[Effected.Mins], error: error[Effected.Mins], valueChanged: inputChange, placeholder: placeholder })))),
            showUnitInput(UnitType.SMS) && (React.createElement("div", { className: styles.column },
                React.createElement("div", { className: styles.qtyHeader },
                    React.createElement("span", { className: styles.title }, "SMS"),
                    React.createElement("span", { className: styles.unlimited },
                        "Unlimited",
                        React.createElement("div", { className: `${styles.circle} ${values[Effected.SMS] === "Unlimited"
                                ? styles.active
                                : ""}`, onClick: setToUnlimited(Effected.SMS) }))),
                values[Effected.SMS] === "Unlimited" ? (React.createElement(FormInput, { type: "text", value: "Unlimited", disabled: true })) : (React.createElement(FormInput, { name: Effected.SMS, type: "number", value: values[Effected.SMS], error: error[Effected.SMS], valueChanged: inputChange, placeholder: placeholder })))),
            showUnitInput(UnitType.MMS) && (React.createElement("div", { className: styles.column },
                React.createElement("div", { className: styles.qtyHeader },
                    React.createElement("span", { className: styles.title }, "MMS"),
                    React.createElement("span", { className: styles.unlimited },
                        "Unlimited",
                        React.createElement("div", { className: `${styles.circle} ${values[Effected.MMS] === "Unlimited"
                                ? styles.active
                                : ""}`, onClick: setToUnlimited(Effected.MMS) }))),
                values[Effected.MMS] === "Unlimited" ? (React.createElement(FormInput, { type: "text", value: "Unlimited", disabled: true })) : (React.createElement(FormInput, { name: Effected.MMS, type: "number", value: values[Effected.MMS], error: error[Effected.MMS], valueChanged: inputChange, placeholder: placeholder }))))),
    ];
};
const getNumberBundlesName = (duration, type) => {
    if (duration === Duration.Per_Day) {
        return "How many days?";
    }
    if (type === BundleType.Individual) {
        return "How many users?";
    }
    return "How many bundles?";
};
