import Company from "@Shared/Entities/AbstractEntities/Company";
import constraints from "./constraints";
import migrate from "./migrate";
export default class Supplier extends Company {
    constructor(data = {}) {
        super(migrate(data), constraints);
    }
    get baseUrl() {
        return `${super.baseUrl}/suppliers/${this.id}`;
    }
    get data() {
        const parent = super.data;
        const { version } = this._data;
        const { OOBLibrary, MDMLibrary, DMLibrary, incumbentCanView, subDomain, socialEnterprise, limitedGuarantee, assetLock, offeredPlatforms, canViewSensabill, networkLibrary, customerServiceLibrary, } = this;
        return {
            ...parent,
            version,
            networkLibrary,
            OOBLibrary,
            MDMLibrary,
            DMLibrary,
            incumbentCanView,
            subDomain,
            socialEnterprise,
            limitedGuarantee,
            assetLock,
            offeredPlatforms,
            canViewSensabill,
            customerServiceLibrary,
        };
    }
    get offeredPlatforms() {
        return this._data.offeredPlatforms || [];
    }
    get OOBLibrary() {
        return this._data.OOBLibrary;
    }
    get MDMLibrary() {
        return this._data.MDMLibrary;
    }
    get DMLibrary() {
        return this._data.DMLibrary;
    }
    get incumbentCanView() {
        return this._data.incumbentCanView;
    }
    get subDomain() {
        return this._data.subDomain;
    }
    get socialEnterprise() {
        return this._data.socialEnterprise;
    }
    get limitedGuarantee() {
        return this._data.limitedGuarantee;
    }
    get assetLock() {
        return this._data.assetLock;
    }
    get canViewSensabill() {
        return this._data.canViewSensabill === true;
    }
    get links() {
        return {
            self: `${this.baseUrl}`,
            users: `${this.baseUrl}/users`,
        };
    }
    get networkLibrary() {
        return this._data.networkLibrary;
    }
    get customerServiceLibrary() {
        return this._data.customerServiceLibrary;
    }
}
