import React from "react";
import styles from "./index.module.scss";
export default class LargeFormInput extends React.Component {
    static defaultProps = {
        value: "",
        type: "text",
        error: false,
        name: "",
        valueChanged: () => { },
        characterLimit: 1000,
    };
    constructor(props) {
        super(props);
        const value = this.props.value;
        this.state = {
            value,
        };
    }
    componentDidUpdate(previousProps) {
        if (previousProps.value !== this.props.value) {
            this.setState({
                value: this.props.value,
            });
        }
    }
    changeInput = (event) => {
        this.setState({
            value: event.target.value,
        });
    };
    update = () => {
        if (this.props.valueChanged) {
            this.props.valueChanged(this.props.name, this.state.value);
        }
    };
    render() {
        const error = this.props.error ? styles.error : "";
        return (React.createElement(React.Fragment, null,
            React.createElement("textarea", { className: `${styles.input} ${error}`, name: this.props.name, onChange: this.changeInput, value: this.state.value, maxLength: this.props.characterLimit, disabled: this.props.disabled, onBlur: this.update }),
            React.createElement("span", { className: styles.text },
                this.props.characterLimit -
                    (this.props.value?.length ?? 0),
                " ",
                "characters")));
    }
}
