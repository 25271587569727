import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";
import { Redirect } from "react-router-dom";
import Button from "@Components/Button";
import Card from "@Components/Card";
import UiError from "@Components/Icons/Error";
import VArrowLeft from "@Components/Icons/VArrowLeft";
import VArrowRight from "@Components/Icons/VArrowRight";
import InfoPopup from "@Components/InfoPopup";
import Progress from "@Components/ProgressBar";
import InProgress from "@Components/Status/InProgress";
import Submitted from "@Components/Status/Submitted";
import { ToastStatus } from "@Components/Toast";
import { CustomerType } from "@Shared/Entities/Customer/Tender/enums";
import { Pages, Status } from "@Shared/Entities/Supplier/Response/enums";
import Factory from "@Shared/Factory";
import { displayEnum } from "@Shared/Helpers/DisplayEnum";
import { checkValidAdditionalData, checkValidAdditionalLines, checkValidCustomerService, checkValidDM, checkValidHardwareFund, checkValidMDM, checkValidNetwork, checkValidOOB, checkValidTariff, } from "@SmartComponents/Proposal/validation";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import styles from "./index.module.scss";
export default class TopBar extends React.Component {
    divElement;
    constructor(props) {
        super(props);
        this.divElement = React.createRef();
        this.state = {
            popup: false,
            redirect: false,
            progress: 0,
        };
    }
    componentDidMount() {
        const height = this.divElement?.current?.clientHeight || 0;
        if (this.props.reportHeight) {
            this.props.reportHeight(height);
        }
        this.calcProgress();
    }
    componentDidUpdate(previousProps) {
        if (this.props.response.seqNo > previousProps.response.seqNo) {
            this.calcProgress();
        }
    }
    getValidationFunctions = (tender, response, hasUsage, covered, devices) => {
        const { proposal } = response;
        const validationFunctions = [
            () => checkValidTariff(proposal),
            () => checkValidOOB(proposal, hasUsage, covered),
            () => checkValidNetwork(proposal),
            () => checkValidAdditionalLines(proposal, response.proposal.tariffComponents, tender?.isSmallBusiness),
            () => checkValidAdditionalData(proposal, response.proposal.tariffComponents),
            () => checkValidCustomerService(proposal),
        ];
        if (!tender.isSmallBusiness) {
            validationFunctions.push(() => checkValidHardwareFund(proposal, devices, tender));
        }
        if (!tender.noMDM && !tender.isSmallBusiness) {
            validationFunctions.push(() => checkValidMDM(proposal, tender.noMDM));
        }
        if (!tender.noDM && !tender.isSmallBusiness) {
            validationFunctions.push(() => checkValidDM(proposal));
        }
        return validationFunctions;
    };
    calcProgress = () => {
        const { tender, devices, response } = this.props;
        const covered = CalculateUsageCovered(tender, response.proposal);
        const hasUsage = tender.usageId !== undefined;
        const validationFunctions = this.getValidationFunctions(tender, response, hasUsage, covered, devices);
        let passed = 0;
        for (const validationFunction of validationFunctions) {
            try {
                validationFunction();
                passed++;
            }
            catch (error) {
                console.log(error);
            }
        }
        this.setState({
            progress: Math.ceil((passed / validationFunctions.length) * 100),
        });
    };
    validateProposal = () => {
        const { tender, devices, response } = this.props;
        const covered = CalculateUsageCovered(tender, response.proposal);
        const hasUsage = tender.usageId !== undefined;
        const validationFunctions = this.getValidationFunctions(tender, response, hasUsage, covered, devices);
        let errors = {};
        for (const validationFunction of validationFunctions) {
            try {
                validationFunction();
            }
            catch (error) {
                errors = { ...errors, ...error };
            }
        }
        if (Object.keys(errors).length === 0) {
            return true;
        }
        this.props.setErrors(errors);
        return false;
    };
    checkUser = (user) => {
        return !!user;
    };
    publishResponse = () => {
        if (!this.checkUser(this.props.user)) {
            return this.props.notify({
                title: "Denied",
                description: "You do not have the authority to publish this proposal",
                status: ToastStatus.Error,
            });
        }
        if (!this.validateProposal()) {
            return this.props.notify({
                title: "Oops",
                description: "There were errors in the proposal you tried to publish",
                status: ToastStatus.Error,
            });
        }
        const response = Factory.Response({
            ...this.props.response.data,
        });
        return this.props.publish(response).then(() => (this.setState({ redirect: true }),
            this.props.notify({
                title: "Tender Submitted",
                description: "Thank you, your Tender has been published",
                status: ToastStatus.Success,
            })));
    };
    popup = (close) => {
        const publish = () => {
            return new Promise((resolve) => {
                resolve(this.publishResponse());
            }).finally(() => close());
        };
        if (!this.props.tender.isOpen) {
            return (React.createElement(Card, { className: styles.popup },
                "Oops! Looks like you have missed the deadline",
                React.createElement(Button, { click: close, dark: true }, "Close")));
        }
        const match = this.props.tender.details.totalLines ===
            this.props.response.meta.totalLines;
        return (React.createElement(Card, { className: styles.popup },
            React.createElement("h2", null, "Are you sure you're ready to publish?"),
            !match && (React.createElement("div", { className: styles.error },
                React.createElement(UiError, { error: true }),
                " The lines in your tariff do not seem to match the customer's request")),
            React.createElement("div", { className: styles.buttons },
                React.createElement(Button, { click: close, dark: true }, "CONTINUE EDITING"),
                React.createElement(Button, { click: publish }, "PUBLISH"))));
    };
    openPopup = () => {
        if (!this.validateProposal()) {
            return this.props.notify({
                title: "Oops",
                description: "There were errors in the proposal you tried to publish",
                status: ToastStatus.Error,
            });
        }
        this.setState({ popup: true });
    };
    closePopup = () => {
        this.setState({ popup: false });
    };
    render() {
        const { page, tender, response } = this.props;
        if (this.state.redirect) {
            return React.createElement(Redirect, { push: true, to: this.props.redirectPath });
        }
        const nextButtonText = page === Pages.Introduction ? "GET STARTED" : "NEXT";
        const disabled = this.props.popoutOpen;
        const editingUsers = (response.editing || [])
            .filter(({ id }) => id !== this.props.user.id)
            .map(({ name }) => name);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.wrapper, ref: this.divElement },
                React.createElement(Grid2, { container: true },
                    React.createElement(Grid2, { xs: 12, md: true, container: true, direction: "column" },
                        React.createElement(Grid2, { className: styles.text },
                            "Tender ID: ",
                            tender.id.toUpperCase(),
                            React.createElement("div", { className: styles.status }, this.props.response.status ===
                                Status.Draft ? (React.createElement(InProgress, null)) : (React.createElement(Submitted, null))),
                            "Created by:",
                            " ",
                            displayEnum(CustomerType, tender.customerType)),
                        React.createElement(Grid2, { className: styles.progress, container: true, maxWidth: 800 },
                            React.createElement(Grid2, { xs: "auto" }, "Progress"),
                            React.createElement(Grid2, { xs: true, className: styles.progressBar },
                                React.createElement(Progress, { completeValue: 100, index: this.state.progress })),
                            React.createElement(Grid2, { xs: "auto", className: styles.percentage },
                                this.state.progress,
                                "%"))),
                    editingUsers.length > 0 && (React.createElement(Grid2, { xs: 12, md: true, container: true, className: styles.editing },
                        "EDITING: ",
                        editingUsers.join(", "))),
                    React.createElement(Grid2, { xs: 12, md: "auto" },
                        React.createElement("div", { className: styles.buttons },
                            page == Pages.Introduction ? (React.createElement(Button, { dark: true, disabled: true },
                                React.createElement(VArrowLeft, { light: true, size: "1rem" }),
                                " ",
                                "PREVIOUS")) : (React.createElement(Button, { dark: true, click: this.props.previous, disabled: disabled },
                                React.createElement(VArrowLeft, { light: true, size: "1rem" }),
                                " ",
                                "PREVIOUS")),
                            page == Pages.ReviewAndSubmit ? (React.createElement(Button, { click: this.openPopup, cta: true, disabled: disabled },
                                "SUBMIT ",
                                React.createElement(VArrowRight, { light: true, size: "1rem" }))) : (React.createElement(Button, { click: this.props.next, cta: true, disabled: disabled },
                                nextButtonText,
                                " ",
                                React.createElement(VArrowRight, { light: true, size: "1rem" }))))))),
            React.createElement(InfoPopup, { content: this.popup, trigger: this.state.popup, onClose: this.closePopup })));
    }
}
