import React from "react";
import { connect } from "react-redux";
import StateNavigationItem from "@Components/StateNavigationItem";
import { Status } from "@Components/StateNavigationItem/enums";
import { LikeHardwareFunded } from "@Shared/Entities/Customer/Tender/Details/enums";
import { Pages } from "@Shared/Entities/Supplier/Response/enums";
import { validateAdditionalData, validateAdditionalLines, validateCustomerService, validatedDM, validateHardwareFund, validateMDM, validateNetwork, validateOOB, validateTariff, } from "@SmartComponents/Proposal/validation";
import { proposalTab } from "@Store/Actions/Suppliers/responses";
import CalculateUsageCovered from "@Shared/Helpers/CalculateUsageCovered";
import { hideForSmallBusiness } from "..";
import styles from "./index.module.scss";
const getTitle = (tender = {}) => {
    const { details = {} } = tender;
    const likeHardwareFunded = details.likeHardwareFunded;
    const hwFundPageTitle = likeHardwareFunded === LikeHardwareFunded.Leasing
        ? "Leasing"
        : "Hardware";
    const titles = {
        [Pages.Introduction]: "Introduction",
        [Pages.Tariff]: "Tariff",
        [Pages.OOB]: "OOB",
        [Pages.HardwareFund]: hwFundPageTitle,
        [Pages.Network]: "Network",
        [Pages.AdditionalLines]: "Additional Lines",
        [Pages.AdditionalData]: "Additional Data",
        [Pages.MDM]: "MDM",
        [Pages.DM]: "Data Management",
        [Pages.CustomerService]: "Customer Service",
        [Pages.ReviewAndSubmit]: "Review & Submit",
    };
    return titles;
};
class ProposalNavigation extends React.Component {
    click = (tab) => {
        const validatePages = () => {
            //check if all pages before this are valid
            for (let index = 0; index < tab; index++) {
                try {
                    this.validatePage(index, true);
                }
                catch {
                    return false;
                }
            }
            return true;
        };
        let progressIndex = this.props.response.progressIndex;
        if (validatePages()) {
            progressIndex = Math.round(Math.max(this.props.response.progressIndex, tab * 10));
        }
        this.props.changeTab({
            id: this.props.responseId,
            tab,
            progressIndex,
        });
    };
    validatePage = (tab, ignoreTab = false) => {
        const { tender, response } = this.props;
        const { data } = response.proposal;
        if (hideForSmallBusiness(tender?.isSmallBusiness, tab)) {
            return true;
        }
        switch (Number(tab)) {
            case Pages.Introduction:
                return true;
            case Pages.Tariff:
                return validateTariff(data);
            case Pages.OOB:
                const covered = CalculateUsageCovered(tender, response.proposal);
                const hasUsage = tender.usageId !== undefined;
                return validateOOB(data, hasUsage, covered);
            case Pages.HardwareFund:
                if (response.tab >= Pages.HardwareFund || ignoreTab) {
                    return validateHardwareFund(data, this.props.devices, tender);
                }
                return false;
            case Pages.Network:
                return validateNetwork(data);
            case Pages.AdditionalLines:
                return validateAdditionalLines(data, tender?.isSmallBusiness);
            case Pages.AdditionalData:
                return validateAdditionalData(data);
            case Pages.MDM:
                if (response.tab >= Pages.MDM || ignoreTab) {
                    return validateMDM(data, response.noMDM);
                }
                return false;
            case Pages.DM:
                if (response.tab >= Pages.DM || ignoreTab) {
                    return validatedDM(data);
                }
                return false;
            case Pages.CustomerService:
                return validateCustomerService(data);
            case Pages.ReviewAndSubmit:
                return false;
        }
    };
    getTabStatus = (tab) => {
        try {
            return this.validatePage(tab) ? Status.Complete : Status.Incomplete;
        }
        catch {
            return Status.Issue;
        }
    };
    render() {
        if (this.props.response === undefined) {
            return React.createElement(React.Fragment, null);
        }
        const isSmallBusiness = this.props.tender?.isSmallBusiness;
        const { tab = Pages.Introduction } = this.props;
        const titles = getTitle(this.props.tender);
        const tabs = Object.keys(titles)
            // .filter(
            //     (page: any) =>
            //         Number(page) !== Pages.MDM || !this.props.response.noMDM
            // )
            .map((page) => {
            if (hideForSmallBusiness(isSmallBusiness, page)) {
                return null;
            }
            const title = titles[page];
            const active = page == tab;
            const status = active
                ? Status.Incomplete
                : this.getTabStatus(page);
            return (React.createElement(StateNavigationItem, { key: page, name: title, status: status, active: active, click: () => this.click(Number(page)) }));
        })
            .filter((element) => element !== null);
        return React.createElement("div", { className: styles.nav }, tabs);
    }
}
const mapStateToProps = ({ suppliers: { responses, responseTabs }, hardware: { devices }, customers: { tenders }, }, props) => {
    const response = responses.find(({ id }) => id === props.responseId);
    const tab = responseTabs.find(({ id }) => id === props.responseId);
    const tender = tenders.find(({ id }) => id === response.tenderId);
    return {
        response,
        tab: tab?.tab,
        devices,
        tender,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        // dispatching plain actions
        changeTab: (data) => proposalTab(dispatch, data),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProposalNavigation);
