// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--Y29N2{display:flex;max-width:100%;font-size:.85rem}.wrapper--Y29N2 .form--bUnIw{flex:1}.save--sVJdd{width:25%;margin:auto;border-radius:.5em}.row--oshDn{display:flex;gap:.5em;font-size:.85rem}.row--oshDn .column--Mrhsp{flex:1}.row--oshDn .column--Mrhsp .subRow--PIwmh{display:flex}.row--oshDn .column--Mrhsp .subRow--PIwmh .subColumn--Q9sf1{flex:1;margin:auto}.row--oshDn .column--Mrhsp .subRow--PIwmh .subColumn--Q9sf1 .upperInputs--V8gmW{display:flex}.row--oshDn .column--Mrhsp .subRow--PIwmh .subColumn--Q9sf1 .upperInputs--V8gmW .item--gOe9v{flex:1}.title--D3GfV{font-weight:bold}.centred--Yxs9Z{display:flex;justify-content:center}.centred--Yxs9Z .radios--o86_7{flex-direction:row;gap:1em}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/Tariff/TariffConstructForm/index.module.scss"],"names":[],"mappings":"AAAA,gBAAS,YAAY,CAAC,cAAc,CAAC,gBAAgB,CAAC,6BAAe,MAAM,CAAC,aAAM,SAAS,CAAC,WAAW,CAAC,kBAAkB,CAAC,YAAK,YAAY,CAAC,QAAQ,CAAC,gBAAgB,CAAC,2BAAa,MAAM,CAAC,0CAAqB,YAAY,CAAC,4DAAgC,MAAM,CAAC,WAAW,CAAC,gFAA6C,YAAY,CAAC,6FAAmD,MAAM,CAAC,cAAO,gBAAgB,CAAC,gBAAS,YAAY,CAAC,sBAAsB,CAAC,+BAAiB,kBAAkB,CAAC,OAAO","sourcesContent":[".wrapper{display:flex;max-width:100%;font-size:.85rem}.wrapper .form{flex:1}.save{width:25%;margin:auto;border-radius:.5em}.row{display:flex;gap:.5em;font-size:.85rem}.row .column{flex:1}.row .column .subRow{display:flex}.row .column .subRow .subColumn{flex:1;margin:auto}.row .column .subRow .subColumn .upperInputs{display:flex}.row .column .subRow .subColumn .upperInputs .item{flex:1}.title{font-weight:bold}.centred{display:flex;justify-content:center}.centred .radios{flex-direction:row;gap:1em}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--Y29N2",
	"form": "form--bUnIw",
	"save": "save--sVJdd",
	"row": "row--oshDn",
	"column": "column--Mrhsp",
	"subRow": "subRow--PIwmh",
	"subColumn": "subColumn--Q9sf1",
	"upperInputs": "upperInputs--V8gmW",
	"item": "item--gOe9v",
	"title": "title--D3GfV",
	"centred": "centred--Yxs9Z",
	"radios": "radios--o86_7"
};
export default ___CSS_LOADER_EXPORT___;
