import React from "react";
import { DeviceRange } from "@Shared/Entities/Customer/Tender/Details/enums";
export default (pickedHandsets, number_, oSSpread, devices) => {
    let range = pickedHandsets;
    if (typeof range === "string") {
        range = Number(range);
        if (DeviceRange[range] === undefined) {
            console.log({ pickedHandsets });
            const handset = (devices.find(({ id }) => id === pickedHandsets) || {}).name;
            return `${number_}x ${handset}`;
        }
    }
    if (range === DeviceRange.Low) {
        return `${number_}x Budget Phones`;
    }
    if (oSSpread !== undefined) {
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
            React.createElement("div", null, `${number_}x ${range === DeviceRange.High ? "Top Range" : "Mid Range"} Phones`),
            React.createElement("div", null, `Android ${oSSpread}%`),
            React.createElement("div", null, `iOS ${100 - Number(oSSpread)}%`)));
    }
};
