import React from "react";
import Table from "@Components/Table";
import { format } from "@Shared/Helpers/Money";
import { LikeHardwareFunded } from "@Shared/Entities/Customer/Tender/Details/enums";
import styles from "./index.module.scss";
export default class DevicePricesTable extends React.Component {
    render() {
        if (this.props.data.length === 0 ||
            this.props.tender?.details?.likeHardwareFunded ===
                LikeHardwareFunded.Leasing) {
            return React.createElement(React.Fragment, null);
        }
        const data = this.props.data.map(({ deviceId, price }) => {
            const name = (this.props.devices.find(({ id }) => id === deviceId) || {}).name;
            return { name, price };
        });
        const fields = [
            {
                header: "Device Name",
                field: "name",
                leftAlign: true,
            },
            {
                header: "Price",
                field: "price",
                display: ({ price }) => (React.createElement("div", { className: styles.number }, format(price))),
                rightAlign: true,
            },
        ];
        return (React.createElement(React.Fragment, null,
            React.createElement("h1", null, this.props.title),
            React.createElement(Table, { data: data, fields: fields, noNav: true, className: styles.table })));
    }
}
