// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageContent--wc4Jz{height:100%;overflow-y:hidden}.pageContent--wc4Jz .proposal--VeKNg{display:flex;flex-direction:column;height:93%}.pageContent--wc4Jz .proposal--VeKNg .page--xd8Rb{display:flex;flex:1;height:100%}.pageContent--wc4Jz .proposal--VeKNg .page--xd8Rb .content--MB8bD{flex:1;overflow-y:scroll;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.noScroll--Enph_{-ms-overflow-style:none;scrollbar-width:none}.noScroll--Enph_::-webkit-scrollbar{display:none}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/Proposal/index.module.scss"],"names":[],"mappings":"AAAA,oBAAa,WAAW,CAAC,iBAAiB,CAAC,qCAAuB,YAAY,CAAC,qBAAqB,CAAC,UAAU,CAAC,kDAA6B,YAAY,CAAC,MAAM,CAAC,WAAW,CAAC,kEAAsC,MAAM,CAAC,iBAAiB,CAAC,2EAA2E,CAAC,iBAAU,uBAAuB,CAAC,oBAAoB,CAAC,oCAA6B,YAAY","sourcesContent":[".pageContent{height:100%;overflow-y:hidden}.pageContent .proposal{display:flex;flex-direction:column;height:93%}.pageContent .proposal .page{display:flex;flex:1;height:100%}.pageContent .proposal .page .content{flex:1;overflow-y:scroll;padding:var(--standard-vertical-padding) var(--standard-horizontal-padding)}.noScroll{-ms-overflow-style:none;scrollbar-width:none}.noScroll::-webkit-scrollbar{display:none}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "pageContent--wc4Jz",
	"proposal": "proposal--VeKNg",
	"page": "page--xd8Rb",
	"content": "content--MB8bD",
	"noScroll": "noScroll--Enph_"
};
export default ___CSS_LOADER_EXPORT___;
