import { Network } from "@Shared/Entities/Customer/Usage/enums";
export var Effected;
(function (Effected) {
    Effected["MinimumSpend"] = "minimumSpend";
    Effected["HasMinimumSpend"] = "hasMinimumSpend";
    Effected["IsManagementFee"] = "isManagementFee";
    Effected["ManagementFeeChargeMethod"] = "managementFeeChargeMethod";
    Effected["ManagementFee"] = "managementFee";
    Effected["ReducedHWFund"] = "reducedHWFund";
    Effected["ReducedEffect"] = "reducedEffect";
    Effected["TotalFund"] = "totalFund";
    Effected["CashHW"] = "cashHW";
    Effected["HWFundTaken"] = "hwFundTaken";
    Effected["DrawDown"] = "drawDown";
    Effected["DeductSims"] = "deductSims";
    Effected["DeductedPerSim"] = "deductedPerSim";
    Effected["SimCost"] = "simCost";
    Effected["ChargePerSim"] = "chargePerSim";
    Effected["HandsetProfitMargin"] = "handsetProfitMargin";
    Effected["HandsetLocked"] = "handsetLocked";
    Effected["AppleDEP"] = "appleDEP";
    Effected["DeviceInsurance"] = "deviceInsurance";
    Effected["NetworkVariant"] = "networkVariant";
    Effected["ChargeForDelivery"] = "chargeForDelivery";
    Effected["DeliveryChargeMethod"] = "deliveryChargeMethod";
    Effected["DeliveryCharge"] = "deliveryCharge";
    Effected["ExtraDeviceDetails"] = "extraDeviceDetails";
    Effected["OS"] = "OS";
    Effected["AdditionalLinesCoTerminus"] = "additionalLinesCoTerminus";
    Effected["TimeLeftTerm"] = "timeLeftTerm";
    Effected["HWFundPerLine"] = "hwFundPerLine";
    Effected["HWFundPerDataSim"] = "hwFundPerDataSim";
    Effected["HWProRata"] = "HWProRata";
    Effected["AdditionalLines"] = "additionalLines";
    Effected["AdditionalLineCost"] = "additionalLineCost";
    Effected["AdditionalDataLineCost"] = "additionalDataLineCost";
    Effected["AdditionalLinesInfo"] = "additionalLinesInfo";
    Effected["AccountManaged"] = "accountManaged";
    Effected["AccountManagerVisit"] = "accountManagerVisit";
    Effected["AccountManagerVisitFrequency"] = "accountManagerVisitFrequency";
    Effected["ComplaintProcess"] = "complaintProcess";
    Effected["TicketSystem"] = "ticketSystem";
    Effected["VIPService"] = "VIPService";
    Effected["VIPServiceResponseTime"] = "VIPServiceResponseTime";
    Effected["ResponseTime"] = "responseTime";
    Effected["ResolutionTime"] = "resolutionTime";
    Effected["FreeNextDay"] = "freeNextDay";
    Effected["OngoingAnalysis"] = "ongoingAnalysis";
    Effected["OfferingPlanCom"] = "offeringPlanCom";
    Effected["OngoingAnalysisMethod"] = "ongoingAnalysisMethod";
    Effected["AnalysisFrequency"] = "analysisFrequency";
    Effected["DisposeHandsets"] = "disposeHandsets";
    Effected["WEEECertificates"] = "WEEECertificates";
    Effected["FullRecycleValue"] = "fullRecycleValue";
    Effected["CustomTerms"] = "customTerms";
    Effected["PACCodeCharge"] = "PACCodeCharge";
    Effected["ChargeMethod"] = "chargeMethod";
    Effected["PACCost"] = "PACCost";
    Effected["TerminationPenalty"] = "terminationPenalty";
    Effected["PenaltyPerLine"] = "penaltyPerLine";
    Effected["PaperStatements"] = "paperStatements";
    Effected["CostForStatements"] = "costForStatements";
    Effected["NonDirectDebitCharges"] = "nonDirectDebitCharges";
    Effected["NonDirectDebitCost"] = "nonDirectDebitCost";
    Effected["ExtraChargesInTenders"] = "extraChargesInTenders";
    Effected["TotalMonthlyCost"] = "totalMonthlyCost";
    Effected["AdditionalChargeDetails"] = "additionalChargeDetails";
    Effected["IsCloudFree"] = "isCloudFree";
    Effected["MDMLineCost"] = "MDMLineCost";
    Effected["OfferMDM"] = "offerMDM";
    Effected["MDMName"] = "MDMName";
    Effected["MDMDetails"] = "MDMDetails";
    Effected["OtherName"] = "otherName";
    Effected["MDMOS"] = "MDMOS";
    Effected["MinCommitmentPeriod"] = "minCommitmentPeriod";
    Effected["MinCommitmentPeriodOther"] = "minCommitmentPeriodOther";
    Effected["MDMRange"] = "MDMRange";
    Effected["EnterpriseVersions"] = "enterpriseVersions";
    Effected["AlternativeSolution"] = "alternativeSolution";
    Effected["FurtherMDM"] = "furtherMDM";
    Effected["MultipleNetworks"] = "multipleNetworks";
    Effected["SelectNetworks"] = "selectNetworks";
    Effected["OtherNetwork"] = "otherNetwork";
    Effected["SecondaryNetworks"] = "secondaryNetworks";
    Effected["BillingRelationship"] = "billingRelationship";
    Effected["BillingRelationshipChoice"] = "billingRelationshipChoice";
    Effected["NetworkRelationship"] = "networkRelationship";
    Effected["KeepSims"] = "keepSims";
    Effected["Increases"] = "increases";
    Effected["IncreaseMeasurement"] = "increaseMeasurement";
    Effected["AdditionalIncrease"] = "additionalIncrease";
    Effected["AdditionalIncreasePercentage"] = "additionalIncreasePercentage";
    Effected["MandatoryRise"] = "mandatoryRise";
    Effected["MandatoryRisePercentage"] = "mandatoryRisePercentage";
    Effected["PercentageIncrease"] = "percentageIncrease";
    Effected["BreakClause"] = "breakClause";
    Effected["DevicePrices"] = "devicePrices";
    Effected["OfferLeasing"] = "offerLeasing";
    Effected["LeasingData"] = "leasingData";
    Effected["SetupFee"] = "setupFee";
    Effected["MinimumLeaseTerm"] = "minimumLeaseTerm";
    Effected["MonthlyLeasing"] = "monthlyLeasing";
    Effected["LeasingRequiresInsurance"] = "leasingRequiresInsurance";
    Effected["DirectDebit"] = "directDebit";
    Effected["DirectDebitCost"] = "directDebitCost";
    Effected["LeasingLumpSum"] = "leasingLumpSum";
    Effected["LeasingNotes"] = "leasingNotes";
    //tariff
    Effected["ProposalDependant"] = "proposalDependant";
    Effected["ESims"] = "eSims";
    Effected["NetworkSupport"] = "networkSupport";
    Effected["SpecificChangesOrRequirements"] = "changesOrRequirements";
    Effected["PlanName"] = "planName";
    Effected["Discount"] = "discount";
    Effected["TariffComponents"] = "tariffComponents";
    Effected["TariffComponentNetwork"] = "networkComponent";
    Effected["TariffComponentType"] = "type";
    Effected["TariffComponentTitle"] = "title";
    Effected["TariffComponentNumberLines"] = "numLines";
    Effected["TariffComponentStandardCost"] = "costPerLine";
    Effected["TariffComponentDiscount"] = "componentDiscount";
    Effected["TariffComponentNetCost"] = "netCost";
    Effected["TariffComponentTotalCost"] = "totalCost";
    Effected["TariffComponentItems"] = "items";
    Effected["TariffComponentAllowanceType"] = "allowanceType";
    Effected["TariffComponentAllowanceQtyType"] = "allowanceQTYType";
    Effected["TariffComponentAllowanceQty"] = "allowanceQTY";
    Effected["TariffComponentAllowanceHide"] = "allowanceHidden";
    //oob
    Effected["Rack_Rate"] = "rackRate";
    Effected["Bundles"] = "bundles";
    Effected["AdditionalNetworkInfo"] = "additionalNetworkInfo";
    Effected["OfferDM"] = "offerDM";
    Effected["DMTool"] = "dmTool";
    Effected["DMOS"] = "DMOS";
    Effected["DMFeatures"] = "dmFeatures";
    Effected["DMFree"] = "dmFree";
    Effected["DMLineCost"] = "dmLineCost";
    Effected["DMMinCommitmentPeriod"] = "dmMinCommitmentPeriod";
    Effected["DMMinCommitmentPeriodOther"] = "dmMinCommitmentPeriodOther";
    Effected["DMRange"] = "dmRange";
    Effected["DMEnterprise"] = "dmEnterprise";
    Effected["DMAlternative"] = "dmAlternative";
    Effected["AdditionalData"] = "additionalData";
    Effected["AdditionalDataNoNewLine"] = "additionalDataNoNewLine";
    Effected["AdditionalDataMinQty"] = "additionalDataMinQty";
    Effected["AdditionalDataCost"] = "additionalDataCost";
    Effected["AdditionalDataType"] = "additionalDataType";
    Effected["AdditionalDataAuto"] = "additionalDataAuto";
})(Effected || (Effected = {}));
export var BillingRelationship;
(function (BillingRelationship) {
    BillingRelationship[BillingRelationship["Network_Billed"] = 0] = "Network_Billed";
    BillingRelationship[BillingRelationship["Own_Billing_Engine"] = 1] = "Own_Billing_Engine";
    BillingRelationship[BillingRelationship["Both"] = 2] = "Both";
})(BillingRelationship || (BillingRelationship = {}));
export var HandsetLock;
(function (HandsetLock) {
    HandsetLock[HandsetLock["Network_Locked"] = 0] = "Network_Locked";
    HandsetLock[HandsetLock["Sim_Free"] = 1] = "Sim_Free";
    HandsetLock[HandsetLock["Either"] = 2] = "Either";
})(HandsetLock || (HandsetLock = {}));
export var DeviceLock;
(function (DeviceLock) {
    DeviceLock[DeviceLock["Sim_Free"] = 0] = "Sim_Free";
    DeviceLock[DeviceLock["Either"] = 1] = "Either";
})(DeviceLock || (DeviceLock = {}));
export var NetworkRelationship;
(function (NetworkRelationship) {
    NetworkRelationship[NetworkRelationship["Tier_1_Network_Direct_Team"] = 0] = "Tier_1_Network_Direct_Team";
    NetworkRelationship[NetworkRelationship["Direct_Network_Partner"] = 1] = "Direct_Network_Partner";
    NetworkRelationship[NetworkRelationship["Indirect_Partner_via_Distribution"] = 2] = "Indirect_Partner_via_Distribution";
    NetworkRelationship[NetworkRelationship["Wholesale"] = 3] = "Wholesale";
    NetworkRelationship[NetworkRelationship["MVNO"] = 4] = "MVNO";
})(NetworkRelationship || (NetworkRelationship = {}));
export var Measurement;
(function (Measurement) {
    Measurement[Measurement["RPI"] = 0] = "RPI";
    Measurement[Measurement["CPI"] = 1] = "CPI";
    Measurement[Measurement["Both"] = 2] = "Both";
})(Measurement || (Measurement = {}));
export var TariffType;
(function (TariffType) {
    TariffType[TariffType["Shared"] = 0] = "Shared";
    TariffType[TariffType["Individual"] = 1] = "Individual";
})(TariffType || (TariffType = {}));
export var TermLength;
(function (TermLength) {
    TermLength[TermLength["Additional_lines_wrapped_into_next_upgrade_period"] = 0] = "Additional_lines_wrapped_into_next_upgrade_period";
    TermLength[TermLength["Fully_for_remaining_months_aligned_to_main_account"] = 1] = "Fully_for_remaining_months_aligned_to_main_account";
})(TermLength || (TermLength = {}));
export var TimeFrame;
(function (TimeFrame) {
    TimeFrame[TimeFrame["Immediately"] = 0] = "Immediately";
    TimeFrame[TimeFrame["In_Year_One"] = 1] = "In_Year_One";
    TimeFrame[TimeFrame["In_Year_Two"] = 2] = "In_Year_Two";
    TimeFrame[TimeFrame["Over_24_Months"] = 3] = "Over_24_Months";
    TimeFrame[TimeFrame["Dependant_On_Spend"] = 4] = "Dependant_On_Spend";
})(TimeFrame || (TimeFrame = {}));
export var Months;
(function (Months) {
    Months[Months["30 days"] = 1] = "30 days";
    Months[Months["6 months"] = 6] = "6 months";
    Months[Months["12 months"] = 12] = "12 months";
    Months[Months["24 months"] = 24] = "24 months";
    Months[Months["36 months"] = 36] = "36 months";
    Months[Months["48 months"] = 48] = "48 months";
})(Months || (Months = {}));
export var TimePeriods;
(function (TimePeriods) {
    TimePeriods[TimePeriods["1 Month"] = 0] = "1 Month";
    TimePeriods[TimePeriods["3 Months"] = 1] = "3 Months";
    TimePeriods[TimePeriods["12 Months"] = 2] = "12 Months";
    TimePeriods[TimePeriods["24 Months"] = 3] = "24 Months";
    TimePeriods[TimePeriods["Other"] = 4] = "Other";
})(TimePeriods || (TimePeriods = {}));
export var MDMNames;
(function (MDMNames) {
    MDMNames[MDMNames["Cisco_Meraki"] = 0] = "Cisco_Meraki";
    MDMNames[MDMNames["Citrix_Endpoint_Mangement"] = 1] = "Citrix_Endpoint_Mangement";
    MDMNames[MDMNames["Hexnode"] = 2] = "Hexnode";
    MDMNames[MDMNames["HMD_Enable_Pro"] = 3] = "HMD_Enable_Pro";
    MDMNames[MDMNames["IBM_MaaS360"] = 4] = "IBM_MaaS360";
    MDMNames[MDMNames["ManageEngine"] = 5] = "ManageEngine";
    MDMNames[MDMNames["Miradore"] = 6] = "Miradore";
    MDMNames[MDMNames["MobileIron_EUM"] = 7] = "MobileIron_EUM";
    MDMNames[MDMNames["SOTI"] = 8] = "SOTI";
    MDMNames[MDMNames["VMware_Workspace_ONE"] = 9] = "VMware_Workspace_ONE";
    MDMNames[MDMNames["Workspace_ONE"] = 10] = "Workspace_ONE";
    MDMNames[MDMNames["Other"] = 11] = "Other";
})(MDMNames || (MDMNames = {}));
export var MDMOS;
(function (MDMOS) {
    MDMOS[MDMOS["Android_Only"] = 0] = "Android_Only";
    MDMOS[MDMOS["iOS_Only"] = 1] = "iOS_Only";
    MDMOS[MDMOS["Android & iOS"] = 2] = "Android & iOS";
})(MDMOS || (MDMOS = {}));
export var SmallTimeFrame;
(function (SmallTimeFrame) {
    SmallTimeFrame[SmallTimeFrame["Same_Working_Day"] = 0] = "Same_Working_Day";
    SmallTimeFrame[SmallTimeFrame["Next_Working_Day"] = 1] = "Next_Working_Day";
    SmallTimeFrame[SmallTimeFrame["48 Hours"] = 2] = "48 Hours";
    SmallTimeFrame[SmallTimeFrame["72 Hours"] = 3] = "72 Hours";
})(SmallTimeFrame || (SmallTimeFrame = {}));
export var ComponentTypes;
(function (ComponentTypes) {
    ComponentTypes[ComponentTypes["Leader"] = 0] = "Leader";
    ComponentTypes[ComponentTypes["Leader inc. Shared Data"] = 1] = "Leader inc. Shared Data";
    ComponentTypes[ComponentTypes["Shared_Data"] = 2] = "Shared_Data";
    ComponentTypes[ComponentTypes["Data_Sharers"] = 3] = "Data_Sharers";
    ComponentTypes[ComponentTypes["Voice & Text"] = 4] = "Voice & Text";
    ComponentTypes[ComponentTypes["Sim Only Voice & Data"] = 5] = "Sim Only Voice & Data";
    ComponentTypes[ComponentTypes["MBB"] = 6] = "MBB";
    // Bolt_On,
    ComponentTypes[ComponentTypes["Aggregated_Data"] = 7] = "Aggregated_Data";
    ComponentTypes[ComponentTypes["Network_ATC"] = 8] = "Network_ATC";
})(ComponentTypes || (ComponentTypes = {}));
export var AllowanceTypes;
(function (AllowanceTypes) {
    //UK Table
    AllowanceTypes["UK Data (GB)"] = "UK Data (GB)";
    AllowanceTypes["Std Calls (mins)"] = "Std Calls (mins)";
    AllowanceTypes["Std SMS"] = "Std SMS";
    AllowanceTypes["Biz rate Calls (mins)"] = "Biz rate Calls (mins)";
    AllowanceTypes["Prem Calls"] = "Prem Calls";
    AllowanceTypes["Prem SMS"] = "Prem SMS";
    AllowanceTypes["Std MMS"] = "Std MMS";
    AllowanceTypes["Dir Enq"] = "Dir Enq";
    AllowanceTypes["Free Calls (mins)"] = "Free Calls (mins)";
    //IDD Table
    AllowanceTypes["UK IDD to EU (mins)"] = "UK IDD to EU (mins)";
    AllowanceTypes["UK IDD to USA & Canada (mins)"] = "UK IDD to USA & Canada (mins)";
    AllowanceTypes["UK IDD to APAC (mins)"] = "UK IDD to APAC (mins)";
    AllowanceTypes["UK IDD to ROW (mins)"] = "UK IDD to ROW (mins)";
    AllowanceTypes["Int SMS"] = "Int SMS";
    AllowanceTypes["Int MMS"] = "Int MMS";
    //EU Table
    AllowanceTypes["EU Data (GB)"] = "EU Data (GB)";
    AllowanceTypes["EU Calls (mins)"] = "EU Calls (mins)";
    AllowanceTypes["EU SMS"] = "EU SMS";
    AllowanceTypes["EU MMS"] = "EU MMS";
    //Non-EU Table
    AllowanceTypes["Non-EU Data (GB)"] = "Non-EU Data (GB)";
    AllowanceTypes["Non-EU Calls (mins)"] = "Non-EU Calls (mins)";
    AllowanceTypes["Non-EU SMS"] = "Non-EU SMS";
    AllowanceTypes["Non-EU MMS"] = "Non-EU MMS";
})(AllowanceTypes || (AllowanceTypes = {}));
export var QTYType;
(function (QTYType) {
    QTYType[QTYType["Unlimited"] = 0] = "Unlimited";
    QTYType[QTYType["Shared"] = 1] = "Shared";
    QTYType[QTYType["Pooled"] = 2] = "Pooled";
    QTYType[QTYType["Individual"] = 3] = "Individual";
    QTYType[QTYType["Aggregated"] = 4] = "Aggregated";
})(QTYType || (QTYType = {}));
export var FreeNextDay;
(function (FreeNextDay) {
    FreeNextDay[FreeNextDay["Yes"] = 0] = "Yes";
    FreeNextDay[FreeNextDay["Yes, excluding iPhone"] = 1] = "Yes, excluding iPhone";
    FreeNextDay[FreeNextDay["No"] = 2] = "No";
})(FreeNextDay || (FreeNextDay = {}));
export var AnalysisMethod;
(function (AnalysisMethod) {
    AnalysisMethod[AnalysisMethod["Manageabill"] = 0] = "Manageabill";
    AnalysisMethod[AnalysisMethod["Wandera"] = 1] = "Wandera";
    AnalysisMethod[AnalysisMethod["Plan.com"] = 2] = "Plan.com";
    AnalysisMethod[AnalysisMethod["In-house"] = 3] = "In-house";
    AnalysisMethod[AnalysisMethod["Other"] = 4] = "Other";
    AnalysisMethod[AnalysisMethod["Sensabill"] = 5] = "Sensabill";
})(AnalysisMethod || (AnalysisMethod = {}));
export var Frequency;
(function (Frequency) {
    Frequency[Frequency["Monthly"] = 0] = "Monthly";
    Frequency[Frequency["Quarterly"] = 1] = "Quarterly";
    Frequency[Frequency["Half_a_year"] = 2] = "Half_a_year";
    Frequency[Frequency["Annually"] = 3] = "Annually";
    Frequency[Frequency["Ad_hoc_on_request"] = 4] = "Ad_hoc_on_request";
})(Frequency || (Frequency = {}));
export var ChargeMethod;
(function (ChargeMethod) {
    ChargeMethod[ChargeMethod["Per_Account"] = 0] = "Per_Account";
    ChargeMethod[ChargeMethod["Per_Device"] = 1] = "Per_Device";
})(ChargeMethod || (ChargeMethod = {}));
export var DMFeatures;
(function (DMFeatures) {
    DMFeatures[DMFeatures["Management_Portal"] = 0] = "Management_Portal";
    DMFeatures[DMFeatures["Mobile_App"] = 1] = "Mobile_App";
    DMFeatures[DMFeatures["MI:RIAM Intelligence Engine"] = 2] = "MI:RIAM Intelligence Engine";
    DMFeatures[DMFeatures["Cloud_Gateway"] = 3] = "Cloud_Gateway";
    DMFeatures[DMFeatures["Data_Compression"] = 4] = "Data_Compression";
    DMFeatures[DMFeatures["Data_Caps_and_Alerts"] = 5] = "Data_Caps_and_Alerts";
    DMFeatures[DMFeatures["Tethering_Control"] = 6] = "Tethering_Control";
    DMFeatures[DMFeatures["Web_Content_Filtering"] = 7] = "Web_Content_Filtering";
    DMFeatures[DMFeatures["Data Used by App (E.g. YT, FB, Insta)"] = 8] = "Data Used by App (E.g. YT, FB, Insta)";
    DMFeatures[DMFeatures["GDPR & Compliance Reports"] = 9] = "GDPR & Compliance Reports";
    DMFeatures[DMFeatures["UEM_Connect"] = 10] = "UEM_Connect";
})(DMFeatures || (DMFeatures = {}));
export var AdditionalDataType;
(function (AdditionalDataType) {
    AdditionalDataType[AdditionalDataType["Per_Line"] = 0] = "Per_Line";
    AdditionalDataType[AdditionalDataType["To_the_Pool"] = 1] = "To_the_Pool";
})(AdditionalDataType || (AdditionalDataType = {}));
export var Platform;
(function (Platform) {
    Platform[Platform["Other"] = 0] = "Other";
    Platform[Platform["EE_Small_Business"] = 1] = "EE_Small_Business";
    Platform[Platform["EE_Corporate"] = 2] = "EE_Corporate";
    Platform[Platform["Vodafone_VCO"] = 3] = "Vodafone_VCO";
    Platform[Platform["Vodafone"] = 4] = "Vodafone";
    Platform[Platform["O2"] = 5] = "O2";
    Platform[Platform["DAISY"] = 6] = "DAISY";
    Platform[Platform["O2 - Plan.com"] = 7] = "O2 - Plan.com";
    Platform[Platform["EE - Plan.com"] = 8] = "EE - Plan.com";
    Platform[Platform["MVNO_Three"] = 9] = "MVNO_Three";
    Platform[Platform["EE_Teams"] = 10] = "EE_Teams";
})(Platform || (Platform = {}));
export var ManagementFeeChargeMethod;
(function (ManagementFeeChargeMethod) {
    ManagementFeeChargeMethod[ManagementFeeChargeMethod["Account_Level"] = 0] = "Account_Level";
    ManagementFeeChargeMethod[ManagementFeeChargeMethod["Per_Line"] = 1] = "Per_Line";
})(ManagementFeeChargeMethod || (ManagementFeeChargeMethod = {}));
export const platformToNetwork = (platform) => {
    switch (platform) {
        case Platform.EE_Small_Business:
        case Platform.EE_Corporate:
        case Platform["EE - Plan.com"]:
        case Platform.EE_Teams:
            return Network.EE;
        case Platform.O2:
        case Platform["O2 - Plan.com"]:
            return Network.O2;
        case Platform.Vodafone:
        case Platform.Vodafone_VCO:
            return Network.Vodafone;
        case Platform.MVNO_Three:
            return Network.Three;
        case Platform.Other:
            return Network.Other;
    }
    return undefined;
};
