// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table--CJVur{font-size:.85rem}.table--CJVur .name--eRcN4{max-width:10em;overflow-wrap:break-word}.table--CJVur .row--OOdwv{min-width:10em;text-align:left}.table--CJVur .number--TyKly{color:var(--alt-highlighted-text-color)}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProposalSummary/BundleTable/index.module.scss"],"names":[],"mappings":"AAAA,cAAO,gBAAgB,CAAC,2BAAa,cAAc,CAAC,wBAAwB,CAAC,0BAAY,cAAc,CAAC,eAAe,CAAC,6BAAe,uCAAuC","sourcesContent":[".table{font-size:.85rem}.table .name{max-width:10em;overflow-wrap:break-word}.table .row{min-width:10em;text-align:left}.table .number{color:var(--alt-highlighted-text-color)}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table--CJVur",
	"name": "name--eRcN4",
	"row": "row--OOdwv",
	"number": "number--TyKly"
};
export default ___CSS_LOADER_EXPORT___;
