import { Network } from "@Shared/Entities/Customer/Usage/enums";
export var CustomerType;
(function (CustomerType) {
    CustomerType[CustomerType["Customer"] = 0] = "Customer";
    CustomerType[CustomerType["Broker"] = 1] = "Broker";
    CustomerType[CustomerType["Super_Broker"] = 2] = "Super_Broker";
    CustomerType[CustomerType["White_Label"] = 3] = "White_Label";
})(CustomerType || (CustomerType = {}));
export var Sections;
(function (Sections) {
    Sections[Sections["LineRequirements"] = 0] = "LineRequirements";
    Sections[Sections["ContractEndDates"] = 1] = "ContractEndDates";
    Sections[Sections["CurrentCostsOrDiscountsAndBenefits"] = 2] = "CurrentCostsOrDiscountsAndBenefits";
    Sections[Sections["DataInternationalCallsAndRoaming"] = 3] = "DataInternationalCallsAndRoaming";
    Sections[Sections["NetworkProviderOptions"] = 4] = "NetworkProviderOptions";
    Sections[Sections["HandsetsAndDevicesOptions"] = 5] = "HandsetsAndDevicesOptions";
    Sections[Sections["MDM"] = 6] = "MDM";
    Sections[Sections["AdditionalInfo"] = 7] = "AdditionalInfo";
    Sections[Sections["SubmitTender"] = 8] = "SubmitTender";
})(Sections || (Sections = {}));
export var Quality;
(function (Quality) {
    //ordered from worst to best signal
    Quality[Quality["Clear"] = 0] = "Clear";
    Quality[Quality["Red"] = 1] = "Red";
    Quality[Quality["Amber"] = 2] = "Amber";
    Quality[Quality["Green"] = 3] = "Green";
    Quality[Quality["Enhanced"] = 4] = "Enhanced";
})(Quality || (Quality = {}));
export var TenderType;
(function (TenderType) {
    TenderType["SIMPLIFIED"] = "simplified";
    TenderType["ENTERPRISE"] = "enterprise";
})(TenderType || (TenderType = {}));
